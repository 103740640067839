import { Alert, Button, Card, Col, Collapse, Divider, Dropdown, Form, Input, Menu, message, Modal, Popconfirm, Popover, Row, Space, Switch, Tabs, Tag, Timeline, Tooltip, Typography } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, Prompt, useParams } from "react-router-dom";
import appConfig from "../../appConfig";
import Icon from "../../assets/icons/icon";
import ActiveUsers from "../../components/activeUsers";
import ActivityDrawer from "../../components/activityDrawer";
import AnimateIn from "../../components/animateIn";
import CardBox from "../../components/cardBox";
import CollapseCard from "../../components/collapseCard";
import ConflictAlert from "../../components/conflictAlert";
import CopyableContent from "../../components/copyableContent";
import CustomCollapse from "../../components/customCollapse";
import CustomCollapseHeader from "../../components/customCollapseHeader";
import Details from "../../components/detailsList";
import DocumentsUpload from "../../components/documentsUpload";
import DateSelect from "../../components/form/dateSelect";
import EnumRadio from "../../components/form/EnumRadio";
import InputNum from "../../components/form/inputNum";
import InputPrice from "../../components/form/inputPrice";
import InputVin from "../../components/form/inputVin";
import SelectUser from "../../components/form/selectUser";
import StatusSelect from "../../components/form/statusSelect";
import DividerLine from "../../components/lineSplit";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import ServerAutoFill from "../../components/serverAutoFill";
import ServerSelectBox from "../../components/serverSelectBox";
import StatusTag from "../../components/statusTag";
import UnmatchedValueWarning from "../../components/unmatchedValueWarning";
import { TaxLegalCaseStoreProvider, useTaxLegalActions, useTaxLegalCase, useTaxLegalEvaluation, useTaxLegalFiles, useTaxLegalId, useTaxLegalLastSaved, useTaxLegalVehicle } from "../../contexts/taxLegalCaseStore";
import TaxLegalPriceDifferenceStore, { getInitialPriceDifference } from "../../contexts/taxLegalStagesPriceDifferenceStore";
import TaxLegalStagesStore, { Names, useTaxLegalStages } from "../../contexts/TaxLegalStagesStore";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import PageTitle from "../../layout/pageTitle";
import { getPath } from "../../routes/appRoutes";
import { getSavedEnums } from "../../services/authService";
import { brandsAutoComplete, modelsAutoComplete } from "../../services/autocompleteService";
import http from "../../services/httpService";
import { downloadAllFiles, getEquipmentList, taxLegalCaseUrl, taxLegalFileUrl, updateTaxLegalCase } from "../../services/taxLegalService";
import { getTaxCase, taxCasesURL } from "../../services/taxService";
import { statusKey, vinKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import { PaginationedData } from "../../types/apiTypes";
import { IdType, ParamTypes, RemoteVehicleData, ScreenProps } from "../../types/appTypes";
import { ActiveStageValues, EditVehicleAndEvaluation, initTaxLegalCase, OriginalEvaluation, TaxLegalCaseRevision, TaxLegalCase as TaxLegalCaseType, TaxLegalStage, TaxLegalStageData, UpdateTaxLegalCase } from "../../types/taxLegalTypes";
import { TaxListItem } from "../../types/taxTypes";
import consts from "../../utilities/consts";
import { date } from "../../utilities/date";
import { compare } from "../../utilities/deepCompare";
import format from "../../utilities/formatNumbers";
import { findTagTitleFromId } from "../../utilities/mapPriceSourceTags";
import { isValidVin } from "../../utilities/stringExtraFunctions";
import { isValidNum } from "../../utilities/typeGuard";
import { PenaltyType, PriceSourceTags, SelectNewPriceSource } from "./shared";
import Complaint, { ComplaintConclusionForm } from "./stages/complaint";
import PriceCheck, { ConclusionForm, HearingOfPartiesForm } from "./stages/priceCheck";
import Resumption from "./stages/resumption";
import { getEnumTitleById } from "../../services/enumService";
import moment from "moment";

const { Panel } = Collapse;

const TaxLegalCase: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<TaxLegalCaseType>(taxLegalCaseUrl(id), initTaxLegalCase, true);
  const caseReceived = data.id > 0;
  return (
    <AnimateIn start={!isLoading && caseReceived} loadingBeforeStart >
      <TaxLegalCaseStoreProvider initialState={data} >
        <TaxLegalStagesStore.Provider initialState={data.stages}>
          <PromptWrapper />
          <PageTitle
            title={`${title} / ${id}`}
            fluid
            headerMargin={false}
            backBtn
            extra={<ActiveUsers context="taxLegal" />}
            footer={caseReceived && <TaxLegalCaseHead />}
          />
          <PageBody>
            <TaxLegalCaseLayout />
          </PageBody>
        </TaxLegalStagesStore.Provider>
      </TaxLegalCaseStoreProvider>

    </AnimateIn>

  );
};
export default TaxLegalCase;
function PromptWrapper() {
  const taxLegalCase = useTaxLegalCase();
  const lastSaved = useTaxLegalLastSaved()
  const { getStagesForms, stages, resetForms } = useTaxLegalStages()
  const taxLegalCaseRef = useRef(taxLegalCase)
  const stagesRef = useRef(stages)
  const lastSavedRef = useRef(lastSaved)

  const compareCase = useCallback(() => {
    return compare(taxLegalCase, taxLegalCaseRef.current)
  }, [taxLegalCase, taxLegalCaseRef.current])

  useEffect(() => {
    if (lastSavedRef.current !== lastSaved) {
      lastSavedRef.current = lastSaved;
      stagesRef.current = stages;
      taxLegalCaseRef.current = taxLegalCase;
      resetForms()
    }
  }, [lastSaved])


  const compareStages = useCallback(() => {
    return compare(stages, stagesRef.current)
  }, [stages])

  const areFormsClean = () => {
    const stagesForms = getStagesForms()
    for (const [fingerprint, formStage] of stagesForms.entries()) {
      for (const [key, formInstance] of Object.entries(formStage.data)) {
        if (formInstance.isFieldsTouched()) {
          return false
        }
      }
    }
    return true
  }


  const handleMessage = useCallback(() => {
    const caseUpToDate = compareCase()
    const stagesUpToDate = compareStages()
    const formsAreClean = areFormsClean()
    let isUpToDate = !!(caseUpToDate && stagesUpToDate && formsAreClean);
    if (isUpToDate) return true;
    return _t("msg.confirm_leave");
  }, [compareCase, compareStages])


  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    const message = handleMessage();
    if (typeof message === 'string') {
      event.preventDefault();
      event.returnValue = message; // Legacy
      return message // Legacy
    }
  }, [handleMessage]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return <Prompt message={handleMessage} />
}

function calculateStatuteOfLimitationDate(reviewDate: string | null | undefined) {
  if (!reviewDate) return null;
  // 15. of next month, plus 3 years, minus 1 month for buffer-room.
  return moment(reviewDate).set("date", 15).add(3, 'years')
}

function constructKeyValues(stages: TaxLegalStage[], originalEvaluation: OriginalEvaluation | null): ActiveStageValues {
  const activeStageValues: Partial<ActiveStageValues> = {
    activeStage: stages[stages.length - 1].type,
  }


  const priceKeys: { formKey: string, stageValueKey: keyof ActiveStageValues }[] = [
    { formKey: "newPrice", stageValueKey: "mstNewPrice" },
    { formKey: "equipmentPrice", stageValueKey: "mstEquipmentPrice" },
    { formKey: "tradePrice", stageValueKey: "mstTradePrice" },
    { formKey: "registrationFee", stageValueKey: "mstRegistrationFee" },
    { formKey: "regulationAmount", stageValueKey: "regulationAmount" },
  ];


  const statuteOfLimitationDate = calculateStatuteOfLimitationDate(originalEvaluation?.reviewDate)
  if (statuteOfLimitationDate) {
    activeStageValues.statuteOfLimitationsDate = statuteOfLimitationDate.toISOString()
  }

  for (const stage of stages) {
    for (const key of priceKeys) {
      for (const formName in stage.data) {
        const formObj = stage.data[formName as Names] as any;
        if (!formObj) { continue; }
        if (key.formKey in formObj && isValidNum(formObj[key.formKey])) {
          (activeStageValues[key.stageValueKey] as number) = Number(formObj[key.formKey]);
        }
      }
    }


    if (stage.type === 'price_check') {
      if (stage.data.materials) {
        if (stage.data.materials.governmentCaseNumber) {
          activeStageValues.governmentCaseNumber = String(stage.data.materials.governmentCaseNumber)
        }
        if (Array.isArray(stage.data.materials.analysisTags)) {
          activeStageValues.analysisTags = stage.data.materials.analysisTags as string[]
        }
      }
      if (stage.data.hearingOfParties) {
        if (Array.isArray(stage.data.hearingOfParties.lackOfComplianceTags)) {
          activeStageValues.complianceTags = stage.data.hearingOfParties.lackOfComplianceTags as string[]
        }
        if (stage.data.hearingOfParties.governmentNewPriceAssessment) {
          activeStageValues.governmentNewPriceAssessment = stage.data.hearingOfParties.governmentNewPriceAssessment
        }
        if (stage.data.hearingOfParties.governmentTradePriceAssessment) {
          activeStageValues.governmentTradePriceAssessment = stage.data.hearingOfParties.governmentTradePriceAssessment
        }
      }
      if (stage.data.conclusion) {
        if (stage.data.conclusion.lackOfComplianceTags) {
          activeStageValues.complianceTags = stage.data.conclusion.lackOfComplianceTags as string[]
        }
        if (stage.data.conclusion.conclusionDate) {
          activeStageValues.conclusionDate = stage.data.conclusion.conclusionDate
        }
        if (stage.data.conclusion.governmentNewPriceAssessment) {
          activeStageValues.governmentNewPriceAssessment = stage.data.conclusion.governmentNewPriceAssessment
        }
        if (stage.data.conclusion.governmentTradePriceAssessment) {
          activeStageValues.governmentTradePriceAssessment = stage.data.conclusion.governmentTradePriceAssessment
        }
      }
    } else if (stage.type === 'complaint') {
      if (stage.data.casePresentation && stage.data.casePresentation.caseHandlerIdSANST) {
        activeStageValues.governmentCaseHandlerId = parseInt(stage.data.casePresentation.caseHandlerIdSANST)
      }
      if (stage.data.complaintConclusion) {
        if (stage.data.complaintConclusion.statuteOfLimitationsDate) {
          activeStageValues.statuteOfLimitationsDate = stage.data.complaintConclusion.statuteOfLimitationsDate
        }
      }
    } else if (stage.type === 'resumption') {
      if (stage.data.request) {
        if (stage.data.request.governmentCaseHandlerId) {
          activeStageValues.governmentCaseHandlerId = stage.data.request.governmentCaseHandlerId
        }
      }
      if (stage.data.complaintConclusion) {
        if (stage.data.complaintConclusion.conclusionDate) {
          activeStageValues.conclusionDate = stage.data.complaintConclusion.conclusionDate
        }
      }
      if (stage.data.hearingOfParties) {
        if (Array.isArray(stage.data.hearingOfParties.lackOfComplianceTags)) {
          activeStageValues.complianceTags = stage.data.hearingOfParties.lackOfComplianceTags as string[]
        }
        if (stage.data.hearingOfParties.governmentNewPriceAssessment) {
          activeStageValues.governmentNewPriceAssessment = stage.data.hearingOfParties.governmentNewPriceAssessment
        }
        if (stage.data.hearingOfParties.governmentTradePriceAssessment) {
          activeStageValues.governmentTradePriceAssessment = stage.data.hearingOfParties.governmentTradePriceAssessment
        }
      }
      if (stage.data.conclusion) {
        if (stage.data.conclusion.conclusionDate) {
          activeStageValues.conclusionDate = stage.data.conclusion.conclusionDate
        }
        if (stage.data.conclusion.governmentCaseHandlerId) {
          activeStageValues.governmentCaseHandlerId = stage.data.conclusion.governmentCaseHandlerId
        }
        if (stage.data.conclusion.governmentNewPriceAssessment) {
          activeStageValues.governmentNewPriceAssessment = stage.data.conclusion.governmentNewPriceAssessment
        }
        if (stage.data.conclusion.governmentTradePriceAssessment) {
          activeStageValues.governmentTradePriceAssessment = stage.data.conclusion.governmentTradePriceAssessment
        }
      }
    }
  }
  if (activeStageValues.regulationAmount && stages[0].data.hearingOfParties?.regulationAmount) {
    activeStageValues.regulationReduction = activeStageValues.regulationAmount - Number(stages[0].data.hearingOfParties.regulationAmount);
    // TODO: Ensure backend renames regulationDifference to regulationReduction
  }
  return activeStageValues as ActiveStageValues
}

const DRAFT_ENUM_ID = 40;
const PENALTY_CASE_ENUM_ID = 4;

function TaxLegalCaseHead() {
  const taxLegalCase = useTaxLegalCase();
  const { user } = UserStore.useContainer()
  const { getStagesForms, stages, setStages } = useTaxLegalStages()
  const { setTaxLegalCase, setLastSaved } = useTaxLegalActions();
  const { vehicle, dealerName } = taxLegalCase;
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm<TaxLegalCaseRevision>();
  const initialValues: Partial<TaxLegalCaseRevision> = {
    statusId: taxLegalCase.status?.id,
    nextDeadline: taxLegalCase.nextDeadline || undefined,
    note: taxLegalCase.importantNote || undefined,
    penaltyCaseId: taxLegalCase.penaltyCaseId || undefined,
    penaltyTypeId: taxLegalCase.penaltyType?.id,
    allDMRDocumentsRequestedAt: taxLegalCase.allDMRDocumentsRequestedAt,
    caseHandlerId: taxLegalCase.caseHandler?.id,
    repairRequested: !!taxLegalCase.repairRequestedAt,
    governmentBatchId: taxLegalCase.governmentBatchId
  }

  const governmentCaseIds = stages.map(stage => {
    if (stage.type === "price_check" && stage.data.materials?.governmentCaseNumber) {
      return stage.data.materials.governmentCaseNumber
    } else if (stage.type === "complaint" && stage.data.casePresentation?.caseIdSANST) {
      return stage.data.casePresentation.caseIdSANST
    } else if (stage.type === "resumption" && stage.data.request?.newGovernmentCaseNumber) {
      return stage.data.request.newGovernmentCaseNumber
    }
  }).filter(Boolean)

  async function handleSaveCase(revisionValues?: TaxLegalCaseRevision) {
    try {
      let newTaxLegalCase = { ...taxLegalCase } as UpdateTaxLegalCase;
      if (revisionValues) {
        newTaxLegalCase.revision = revisionValues
      }
      setSaving(true)
      const stagesForms = getStagesForms()

      if (stagesForms) {
        const newStages = [...stages]
        stagesForms.forEach((formStage, fingerprint) => {
          const stage = newStages.find(stage => stage.fingerprint === fingerprint)
          if (stage) {
            const data = formStage.data
            for (const [key, formInstance] of Object.entries(data) as [Names, any][]) {
              stage.data[key] = formInstance.getFieldsValue()
            }
          }
        })
        newTaxLegalCase.stages = newStages;
        newTaxLegalCase.activeStageValues = constructKeyValues(stages, taxLegalCase.originalEvaluation)
      }
      const { data } = await updateTaxLegalCase(taxLegalCase.id, newTaxLegalCase)
      setTaxLegalCase(data)
      setLastSaved(new Date().toISOString())
      setStages(data.stages)
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setSaving(false)
      if (saveModalOpen) {
        setSaveModalOpen(false)
      }
    }
  }

  return (
    <Row align="middle" gutter={[8, 16]}>
      <Col flex="auto">
        <Typography.Title level={3} ellipsis className="mb-0">
          {vehicle?.brand ? (
            <>
              {vehicle?.brand} {vehicle?.model}
            </>
          ) : "-"}
        </Typography.Title>
        <Space wrap style={{ rowGap: 0, marginTop: 4 }} split={<Divider type="vertical" className="ml-05 mr-05" />}>
          {governmentCaseIds.map(id => (
            <CopyableContent key={id} className="text-medium" contentToCopy={id ?? ""}>
              {id ?? "-"}
            </CopyableContent>

          ))}
          <CopyableContent className="text-medium" contentToCopy={vehicle?.vin ?? ""}>
            {vehicle?.vin ?? "-"}
          </CopyableContent>
          <Typography.Text className="text-medium">
            {dealerName ?? "-"}
          </Typography.Text>
        </Space>
      </Col>
      <Col>
        <Space wrap>
          <Button loading={saving} onClick={() => handleSaveCase()}>{_t("saveWithoutStatus")}</Button>
          <Button loading={saving} type="primary" onClick={() => setSaveModalOpen(true)}>{_t("save")}</Button>
          <Modal title={_t("save", "case")}
            visible={saveModalOpen} onCancel={() => setSaveModalOpen(false)}
            className="dmf-modal-footer-space-between"
            okText={_t("save")}
            onOk={form.submit}
            confirmLoading={saving}
            cancelButtonProps={{ disabled: saving }}
            width={600}
          >
            <Form form={form}
              {...consts.formItemProps}
              // TODO --> Get next deadline from forms
              requiredMark={false}
              onFinish={handleSaveCase}
              initialValues={initialValues}
            >
              <Form.Item rules={[{ required: true }]} label={_t("status")} name="statusId">
                <StatusSelect disabled={saving} contexts="taxLegal" />
              </Form.Item>
              <Form.Item label={_t("next", "deadline")} name="nextDeadline">
                <DateSelect disabled={saving} />
              </Form.Item>
              <Form.Item label={_t("accessToDocuments")} name="allDMRDocumentsRequestedAt">
                <DateSelect />
              </Form.Item>
              {user?.dealer.id && (
                <Form.Item label={_t("caseHandler")} shouldUpdate={(prev, next) => prev.statusId !== next.statusId}>
                  {({ getFieldValue }) => {
                    const status = getFieldValue(["statusId"]) as number;
                    const required = !!(status && status !== DRAFT_ENUM_ID)
                    return (
                      <SelectUser rules={[{ required: required }]} dealerId={user.dealer.id} typeId={1} name="caseHandlerId" noStyle />
                    )
                  }}
                </Form.Item>
              )}
              <Form.Item label={_t("responsibilityEvaluation")} name="penaltyTypeId">
                <PenaltyType />
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prev, next) => prev.penaltyTypeId !== next.penaltyTypeId}>
                {({ getFieldValue }) => {
                  const penaltyId = getFieldValue(["penaltyTypeId"])
                  const penaltyCaseEnum = getSavedEnums()?.taxLegalCase?.penaltyType?.find(item => item.id === PENALTY_CASE_ENUM_ID)
                  if (penaltyCaseEnum && penaltyId !== penaltyCaseEnum.id) return null;
                  return (
                    <Form.Item rules={[{ required: true }]} label={_t("penaltyCaseId")} name="penaltyCaseId">
                      <Input disabled={saving} />
                    </Form.Item>
                  )
                }}
              </Form.Item>
              <Form.Item label={_t("notice")} name="note">
                <Input disabled={saving} />
              </Form.Item>
              <Form.Item label={_t("repairRequired")} valuePropName="checked" name="repairRequested">
                <Switch/>
              </Form.Item>
              <Form.Item label={_t("group", "id")} name="governmentBatchId">
                <Input disabled={saving} />
              </Form.Item>
            </Form>
          </Modal>
        </Space>
      </Col>
    </Row>
  )
}

function TaxLegalCaseLayout() {
  const { importantNote, note, relatedCases, id } = useTaxLegalCase()
  return (
    <Row align="top" gutter={24}>
      <Col
        xxl={{ span: 7 }}
        xl={{ span: 12 }}
        xs={{ span: 24 }}
      >
        <CaseStatusCard />
        <VehicleCard />
        {note && (
          <Alert message={_t("noteAtCreation")} description={note} closable />
        )}
      </Col>
      <Col
        xxl={{ span: 10 }}
        xl={{ span: 12 }}
        xs={{ span: 24 }}
      >
        <CaseStages />
        <ActivityDrawer
          positionNormal
          visible={true}
          setVisibility={() => {}}
          // TODO: When adding a new activity note, the page needs to be refreshed before it will show up as a note
          isAdmin
          type="taxLegal"
          id={id}
        />
      </Col>
      <Col
        xxl={{ span: 7 }}
        xl={{ span: 12 }}
        xs={{ span: 24 }}
      >
        {importantNote && (
          <Alert style={{ marginBottom: 24 }} message={importantNote} />
        )}
        <PriceDifferenceTimeline />
        <TaxLegalCaseAttachments />
        {relatedCases && (
          <ConflictAlert conflictedCases={relatedCases} />
        )}
      </Col>
    </Row>
  );
};


function CaseStatusCard() {
  const { status, governmentBatchId, penaltyType, penaltyCaseId, caseHandler, nextDeadline, activeStageValues, allDMRDocumentsRequestedAt, repairRequestedAt } = useTaxLegalCase()
  const governmentNewPriceAssessment = getEnumTitleById(activeStageValues?.governmentNewPriceAssessment ?? 0, ["taxLegalCase", "governmentAssessment"])
  const governmentTradePriceAssessment = getEnumTitleById(activeStageValues?.governmentTradePriceAssessment ?? 0, ["taxLegalCase", "governmentAssessment"])
  return (
    <Card title={_t("status")} extra={ !!repairRequestedAt && 
      <Typography.Text type={"warning"}>
        <Tooltip title={_t("repairRequired")}>
          <Icon name="build-outline" size="large" style={{ display: 'block' }} />
        </Tooltip>
      </Typography.Text>
    }>
      <Details borderLess>
        <Details.Item style={{ alignItems: "center" }} label={_t("status")} value={<StatusTag status={status} />} />
        <Details.Item label={_t("dmf", "caseHandler")} value={caseHandler?.fullName || "-"} />
        <Details.Divider/>
        <Details.Item label={_t("actual", "regulation")} value={format.price(Number(activeStageValues?.regulationAmount || ""))} />
        <Details.Item label={_t("eventually", "reduction")} value={format.price(Number(activeStageValues?.regulationReduction || ""))} />
        <Details.Item style={{ alignItems: "center" }} label={_t("actual_penalty")} value={<StatusTag status={penaltyType} />} />

        {!!penaltyCaseId && (
          <Details.Item label={_t("penaltyCaseId")} value={penaltyCaseId ?? "-"} />
        )}
        {!!governmentBatchId && (
          <Details.Item label={_t("ms.groupCaseNumber")} value={governmentBatchId} />
        )}
        <Details.Item style={{ alignItems: "center" }} label={_t("MSAssessment")} 
          value={`${governmentNewPriceAssessment ? governmentNewPriceAssessment + " NP" : ""} - ${governmentTradePriceAssessment ? governmentTradePriceAssessment + " HP" : ""}`} 
        />
        <Details.Divider/>
        <Details.Item label={_t("next", "deadline")} value={nextDeadline ? date(nextDeadline) : "-"} />
        <Details.Item label={_t("conclusionDate")} value={activeStageValues?.conclusionDate ? date(activeStageValues.conclusionDate) : "-"} /> 
        <Details.Item label={_t("limitationDate")} value={activeStageValues?.statuteOfLimitationsDate ? date(activeStageValues.statuteOfLimitationsDate) : "-"} />
        <Details.Item label={_t("accessToDocuments")} value={format.date(allDMRDocumentsRequestedAt)} />
      </Details>
    </Card>
  )
}

function VehicleCard() {
  const [editVehicle, setEditVehicla] = useState(false)
  const vehicle = useTaxLegalVehicle()
  const evaluation = useTaxLegalEvaluation()
  const id = useTaxLegalId()
  const [loadingEquipment, setLoadingEquipment] = useState(false)


  async function getEquipment() {
    try {
      setLoadingEquipment(true)
      const { data } = await getEquipmentList(id)
      if (data.url) window.open(data.url, "_blank");
    } catch (error) {
      renderErrorMessage(error)
    } finally { setLoadingEquipment(false) }
  }

  return (
    <>
      <CollapseCard title={_t("vehicle")}>
        <Details borderLess>
          <Details.Item label={_t("type")} value={vehicle?.type ?? "-"} />
          <Details.Item label={_t("brand")} value={vehicle?.brand ?? "-"} />
          <Details.Item label={_t("model")} value={vehicle?.model ?? "-"} />
          <Details.Item label={_t("vin")} value={vehicle?.vin ?? "-"} />
          <Details.Item label={_t("firstRegDate")} value={format.date(vehicle?.firstRegDate)} />
          <Details.Item label={_t("mileage")} value={format.number(vehicle?.mileage) + " km."} />
          <Details.Item label={_t("equipment")} value={<Button loading={loadingEquipment} onClick={getEquipment} style={{ marginRight: -7 }} size="small" type="link">Hent udstyr</Button>} />
        </Details>
        <DMRCollapse dmr={vehicle?.remoteData?.dmr} />
        <DATCollapse dat={vehicle?.remoteData?.dat} />
        <CardBox title={<Typography.Title level={5}>{_t("original", "evaluation")}</Typography.Title>}>
          <Details borderLess>
            <Details.Item label={_t("reviewDate")} value={format.date(evaluation?.reviewDate)} />
            <Details.Item label={_t("reporter")} value={evaluation?.reporterCompany ?? "-"} />
            <Details.Item label={_t("evaluator")} value={evaluation?.caseHandler ?? "-"} />
            <Details.Item label={_t("reporter", "referenceNumber_short")} value={<Link to={getPath("tax", evaluation?.dmfCaseId)}>{evaluation?.dmfCaseId ?? "-"}</Link>} />
            <Details.Divider />
            <Details.Item label={_t("new_price")} value={format.price(evaluation?.newPrice)} />
            <Details.Item label={_t("extra_equipment_price")} value={format.price(evaluation?.equipmentsPrice)} />
            <Details.Item label={_t("trade_price")} value={format.price(evaluation?.tradePrice)} />
            <Details.Item label={_t("registration_fee")} value={format.price(evaluation?.registrationFee)} />
            <Details.Item label={_t("validatedMileage")} value={format.yesOrNo(evaluation?.mileageDocumentation)} />

            <Details.Item label={_t("new_price", "source")} value={
              findTagTitleFromId(evaluation?.newPriceSourceTag || -1) && (
                <Tag color="gray" style={{ fontSize: 12, lineHeight: "16px" }}>{findTagTitleFromId(evaluation?.newPriceSourceTag || -1)}</Tag>
              )
            } />
            <Details.Item label={_t("equipment_price", "source")} value={
              evaluation?.equipmentPriceSourceTags?.map((id) => <Tag color="gray" style={{ fontSize: 12, lineHeight: "16px" }} key={id}>{findTagTitleFromId(id)}</Tag>)}
            />

          </Details>
        </CardBox>
        <div className="text-right mt-1" style={{ marginRight: -16 }}>
          <Button onClick={() => setEditVehicla(true)} type="link">{_t("edit")}</Button>
        </div>
      </CollapseCard>
      <EditVehicleAndAssessment
        isOpen={editVehicle}
        close={() => setEditVehicla(false)}
      />
    </>
  )
}

type EditVehicleAndEvaluationProps = {
  isOpen: boolean;
  close: () => void;
}
function EditVehicleAndAssessment({ isOpen, close }: EditVehicleAndEvaluationProps) {
  const vehicle = useTaxLegalVehicle()
  const evaluation = useTaxLegalEvaluation()
  const { setVehicle, setEvaluation } = useTaxLegalActions()
  const [form] = Form.useForm<EditVehicleAndEvaluation>()
  const [dat, setDat] = useState<RemoteVehicleData['dat']>()
  const [dmr, setDmr] = useState<RemoteVehicleData['dmr']>()
  const [vinCompletedInCase, setVinCompletedInCase] = useState<IdType[]>([])
  const [loading, setLoading] = useState(false)

  function applyChanges(values: EditVehicleAndEvaluation) {
    setVehicle({
      ...values.vehicle,
      remoteData: {
        dat: dat ?? null,
        dmr: dmr ?? null
      }
    })
    setEvaluation(values.originalEvaluation)
    close()
  }

  function handleVinFetch(data: RemoteVehicleData) {
    const dmr = data.dmr
    const dat = data.dat;
    if (dat) setDat(dat);
    if (dmr) setDmr(dmr);
    form.setFieldsValue({
      vehicle: {
        vin: dmr?.mapped?.vin,
        brand: dmr?.mapped?.brand,
        model: dmr?.mapped?.model,
        firstRegDate: dmr?.mapped?.firstRegDate,
        mileage: dmr?.mapped?.mileage,
      }
    })
  }

  async function handleFetchTaxCase() {
    try {
      setLoading(true)
      const taxCaseId = form.getFieldValue(["originalEvaluation", "dmfCaseId"])
      if (!taxCaseId) return;
      const { data: taxCase } = await getTaxCase(taxCaseId)
      const reporterCompany = form.getFieldValue(['originalEvaluation', 'reporterCompany'])
      form.setFieldsValue({
        originalEvaluation: {
          caseHandler: taxCase.log?.receipt?.person.fullName,
          reporterCompany: reporterCompany ?? appConfig('companyName'),
          reviewDate: taxCase.log?.receipt?.date || null,
          registrationFee: taxCase.internalCalculation?.registrationFee?.registrationFee,
          equipmentsPrice: taxCase.internalCalculation?.newPrice?.equipmentsPrice,
          newPrice: taxCase.internalCalculation?.newPrice?.newPrice,
          tradePrice: taxCase.internalCalculation?.tradePrice?.tradePrice,
        },
        vehicle: {
          ...taxCase.vehicle
        }
      });
      setDat(taxCase.vehicle?.remoteData?.dat);
      setDmr(taxCase.vehicle?.remoteData?.dmr);
      if (taxCase.vehicle?.vin) {
        searchCompletedTaxCasesMatchingVin(taxCase.vehicle.vin)
      }
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }
  const initialValues = { vehicle: { ...vehicle }, originalEvaluation: { ...evaluation } };
  if (!initialValues.vehicle.typeId) {
    initialValues.vehicle.typeId = 1
  }

  function handleBrandIdChange(_value: any, option: { label?: string } | any) {
    if (option.label) {
      form.setFieldsValue({ vehicle: { brand: option.label } })
    }
  }

  async function searchCompletedTaxCasesMatchingVin(vin: string) {
    try {
      const query = new URLSearchParams();
      const completedStatusId = getSavedEnums()?.status.find(status => status.title === "Gennemført")?.id
      query.set(vinKey, vin)
      if (completedStatusId) query.set(statusKey, String(completedStatusId))
      const url = taxCasesURL(query.toString());
      const { data } = await http.get<PaginationedData<TaxListItem>>(url);
      setVinCompletedInCase(data.data.map(taxCase => taxCase.id))
    } catch (error) {
      renderErrorMessage(error)
    }
  }
  function handleValuesChange(changedValues: Partial<EditVehicleAndEvaluation>, values: EditVehicleAndEvaluation) {
    if (changedValues.vehicle?.vin && isValidVin(changedValues.vehicle.vin)) {
      searchCompletedTaxCasesMatchingVin(changedValues.vehicle.vin)
    }
  }

  useEffect(() => {
    if (vehicle?.vin) {
      searchCompletedTaxCasesMatchingVin(vehicle.vin)
    }
  }, [])

  return (
    <Modal title={_t("editVehicleAndOriginalValuation")}
      visible={isOpen} onCancel={close}
      width={900}
      footer={null}
    >
      <Form<EditVehicleAndEvaluation> form={form}
        {...consts.formItemProps}
        className="m-0 form-items-mb-05"
        requiredMark={false}
        onFinish={applyChanges}
        onValuesChange={handleValuesChange}
        initialValues={initialValues}
      >
        <Row gutter={[32, 24]}>
          <Col span={24}>
            <Form.Item label={_t("tax", "id").toUpperCase()} name={["originalEvaluation", "dmfCaseId"]} rules={[{ pattern: /\d+/, message: "TAX ID er ikke et nummer" }]}>
              <Input disabled={loading} placeholder="82545" className="input-group-btn" addonAfter={<Button loading={loading} onClick={handleFetchTaxCase}>Hent sag</Button>} />
            </Form.Item>
            <DividerLine />
          </Col>
          <Col lg={{ span: 12 }} span={24} >
            <Form.Item
              name={["vehicle", "typeId"]}
              label={_t("vehicle_type")}

            >
              <EnumRadio size="small" nameKey={["vehicle", "type"]} />
            </Form.Item>

            <Form.Item label={_t("vin")} name={["vehicle", "vin"]}>
              <InputVin useRemoteVehicleData onFetched={handleVinFetch} />
            </Form.Item>

            <Form.Item hidden name={["vehicle", "brand"]}>
              <Input />
            </Form.Item>

            <Form.Item shouldUpdate={(curr, next) => {
              const currData = curr.vehicle.typeId;
              const nextData = next.vehicle.typeId;
              return currData !== nextData
            }
            } noStyle>
              {({ getFieldValue, setFieldsValue }) => {
                const typeId = getFieldValue(['vehicle', 'typeId']);
                const url = brandsAutoComplete('', '', typeId);
                return (
                  <Form.Item rules={[{ required: true }]} name={["vehicle", "brandId"]}
                    label={_t("brand")}>
                    <ServerSelectBox
                      onChange={handleBrandIdChange}
                      apiUrl={url}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item shouldUpdate={(curr, next) => {
              const currDataBrand = curr.vehicle.brandId;
              const currDataType = curr.vehicle.typeId;
              const currAlias = curr.vehicle.addModelAlias;
              const nextDataBrand = next.vehicle.brandId;
              const nextDataType = next.vehicle.typeId;
              const nextAlias = next.vehicle.addModelAlias;
              return (currDataBrand !== nextDataBrand ||
                currDataType !== nextDataType ||
                currAlias !== nextAlias)
            }} noStyle>
              {({ getFieldsValue }) => {
                const data = getFieldsValue();
                const { brandId, typeId, addModelAlias } = data?.vehicle || {};

                const url = modelsAutoComplete('', brandId, '', typeId);
                return (
                  <Form.Item
                    help={addModelAlias && <UnmatchedValueWarning unMatchedValue={addModelAlias} />}
                    rules={[{ required: true }]}
                    name={["vehicle", "model"]} label={_t("model")}>
                    <ServerAutoFill
                      apiUrl={url}
                      disabled={!brandId || !typeId}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item label={_t("firstRegDate")} name={["vehicle", "firstRegDate"]}>
              <DateSelect />
            </Form.Item>
            <Form.Item className="mb-2" label={_t("mileage")} name={["vehicle", "mileage"]}>
              <InputNum />
            </Form.Item>

            <Form.Item valuePropName="checked" className="switch-form-item-flex-right" label={_t("mileage_documentation")} name={["originalEvaluation", "mileageDocumentation"]}>
              <Switch />
            </Form.Item>
            <SelectNewPriceSource path={["originalEvaluation"]} />
            <PriceSourceTags path={["originalEvaluation"]} />

            {/* Display-Only components */}
            <DMRCollapse dmr={dmr} />
            <DATCollapse dat={dat} />
          </Col>
          <Col lg={{ span: 12 }} span={24}>
            {!!vinCompletedInCase.length && (
              <Alert className="mb-1" showIcon type="warning" closable message={
                <Space size={"small"}>
                  Stelnummeret er værdifastsat i TAX-sagsnummer {' '}
                  {vinCompletedInCase.map(id =>
                    <Link key={id} target={"_blank"} to={getPath("tax", id)}>
                      #{id}
                    </Link>
                  )}
                </Space>
              }
              />
            )}
            <CardBox className="pt-1" title={<Typography.Title className="mb-1" level={5}>{_t("original", "evaluation")}</Typography.Title>}>
              <Form.Item label={_t("reviewDate")} name={["originalEvaluation", "reviewDate"]}>
                <DateSelect />
              </Form.Item>
              <Form.Item label={_t("selfReporter")} name={["originalEvaluation", "reporterCompany"]}>
                <Input />
              </Form.Item>
              <Form.Item label={_t("caseHandler")} name={["originalEvaluation", "caseHandler"]}>
                <Input />
              </Form.Item>

              <DividerLine style={{ marginBlock: 12 }} />
              <Form.Item label={_t("new_price")} name={["originalEvaluation", "newPrice"]}>
                <InputPrice />
              </Form.Item>
              <Form.Item label={_t("equipment_new_price")} name={["originalEvaluation", "equipmentsPrice"]}>
                <InputPrice />
              </Form.Item>
              <Form.Item label={_t("trade_price")} name={["originalEvaluation", "tradePrice"]}>
                <InputPrice />
              </Form.Item>
              <Form.Item label={_t("registration_fee")} name={["originalEvaluation", "registrationFee"]}>
                <InputPrice />
              </Form.Item>
              <DividerLine style={{ marginBlock: 12 }} />
              <Form.Item label={_t("selfReporter", "referenceNumber_short")} name={["originalEvaluation", "externalReferenceCode"]}>
                <Input />
              </Form.Item>

            </CardBox>
          </Col>
        </Row>


        {/* FOOTER */}
        <Space style={{ width: "100%", justifyContent: "space-between", paddingTop: 24, marginTop: 24 }}>
          <Button onClick={close}>{_t("close")}</Button>
          <Button type="primary" htmlType="submit">{_t("apply")}</Button>
        </Space>
      </Form>

    </Modal>
  )
}


type DMRCollapseProps = {
  dmr?: RemoteVehicleData['dmr']
}
function DMRCollapse({ dmr }: DMRCollapseProps) {

  return (
    <CustomCollapse className="mt-03 mr-0 ml-0">
      <Panel
        header={
          <CustomCollapseHeader>
            {_t("DMR", "data")}
          </CustomCollapseHeader>
        }
        key="1"
      >
        <Details borderLess className="mb-0-important">
          <Details.Item label={_t("mileage")} value={format.number(dmr?.mapped?.mileage) + " km."} />
          <Details.Item label={"Synsvurdering"} value={dmr?.raw?.dmr?.condition ?? "-"} />
          <Details.Item label={"CO2"} value={format.number(dmr?.mapped?.emissionAmount) + "g"} />
          <Details.Item label={_t("battery_capacity")} value={format.batteryCapacity(dmr?.mapped?.batteryCapacity) + "g"} />
          <Details.Item label={_t("totalWeight")} value={format.number(dmr?.raw?.data?.totalWeight) + " kg."} />
          {/* TODO: Which data point maps to towing hook? */}
          <Details.Item label={_t("tow")} value={format.yesOrNo(dmr?.raw?.data?.coupling)} />
        </Details>
      </Panel>
    </CustomCollapse>
  )
}

type DATCollapseProps = {
  dat?: RemoteVehicleData['dat']
}
function DATCollapse({ dat }: DATCollapseProps) {
  return (
    <CustomCollapse className="mt-03 mr-0 ml-0">
      <Panel
        header={
          <CustomCollapseHeader>
            {_t("DAT", "data")}
          </CustomCollapseHeader>
        }
        key="1"
      >
        <Details borderLess className="mb-0-important">
          <Details.Item label={_t("dat_net_price")} value={format.price(dat?.raw?.originalNetPrice, 0, "EUR")} />
          <Details.Item label={_t("number_of_doors")} value={dat?.raw?.doorCount ?? "-"} />
          <Details.Item label={_t("number_of_seats")} value={dat?.raw?.seatsCount ?? "-"} />
          <Details.Item label={_t("number_of_gears")} value={dat?.raw?.gearCount ?? "-"} />
          <Details.Item label={_t("drive_wheel")} value={dat?.raw?.driveWheel ?? "-"} />
          {/* TODO: DAT Tow hook data */}
          <Details.Item label={_t("tow")} value={format.yesOrNo(false)} />
        </Details>
      </Panel>
    </CustomCollapse>
  )
}



function TaxLegalCaseAttachments() {
  const [loading, setLoading] = useState<boolean>(false);
  const id = useTaxLegalId()
  const { setFiles } = useTaxLegalActions()
  const files = useTaxLegalFiles();

  const handleDownloadAll = async () => {
    try {
      setLoading(true);
      const { data } = await downloadAllFiles(id);
      if (data?.url) window.open(data.url, "_blank");
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapseCard title={_t("attachments")} loading={loading}>
      <DocumentsUpload
        onDownloadAll={handleDownloadAll}
        fileList={files || []}
        canLockFiles
        onChange={({ fileList }) => { setFiles(fileList) }}
        action={taxLegalFileUrl(id)}
      />
    </CollapseCard>
  );
};

function PriceDifferenceTimeline() {
  const { stages } = useTaxLegalStages()
  const evaluation = useTaxLegalEvaluation()

  return (

    <CollapseCard title={_t("regulation", "timeline")}>
      <Timeline className="" mode="left">
        <Timeline.Item color="blue" label={<>{_t("reviewDate")} {" "} <small>{format.date(evaluation?.reviewDate)}</small></>}>
          <Popover
            title={_t("original", "evaluation")}
            content={
              <Details borderLess>
                <Details.Item label={_t("new_price")} value={format.number(evaluation?.newPrice)} />
                <Details.Item label={_t("equipment")} value={format.number(evaluation?.equipmentsPrice)} />
                <Details.Item label={_t("trade_price")} value={format.number(evaluation?.tradePrice)} />
                <Details.Item label={_t("registration_fee_short")} value={format.number(evaluation?.registrationFee)} />

              </Details>
            }
          >
            {format.number(evaluation?.registrationFee)}
          </Popover>
        </Timeline.Item>
        {stages.map((stage, i) => {
          const color = i < stages.length - 1 ? "green" : "gray";
          return (
            <Timeline.Item color={color} key={stage.fingerprint} label={
              <>
                {_t(stage.type)} {" "}
                <small>{format.date(stage.timestamp)}</small>
              </>
            }>
              <Space direction="vertical" size="small">
                {Object.keys(stage.data).map((key) => {
                  const data = stage.data[key as keyof TaxLegalStageData]
                  const shouldRender = (data: TaxLegalStageData[keyof TaxLegalStageData]): data is HearingOfPartiesForm | ConclusionForm | ComplaintConclusionForm => {
                    if (data && "regulationAmount" in data) {
                      return isValidNum(data.newPrice) 
                        || isValidNum(data.equipmentsPrice)
                        || isValidNum(data.tradePrice) 
                        || isValidNum(data.registrationFee) 
                        || isValidNum(data.regulationAmount)
                    }
                    return false
                  }
                  if (shouldRender(data)) {
                    const regulation = data.regulationAmount
                    const newPrice = data.newPrice;
                    const equipmentsPrice = data.equipmentsPrice;
                    const tradePrice = data.tradePrice;
                    const registrationFee = data.registrationFee;
                    return (
                      <Popover
                        key={key}
                        title={`${_t(key as keyof TaxLegalStageData)}: ${_t("ms_value")} (${_t("diff")})`}
                        content={
                          <Details borderLess>
                            <Details.Item label={_t("new_price")} value={
                              `${format.number(newPrice)} (${format.number(newPrice - (evaluation?.newPrice || 0))})`
                            } />
                            <Details.Item label={_t("equipment")} value={
                              `${format.number(equipmentsPrice)} (${format.number(equipmentsPrice - (evaluation?.equipmentsPrice || 0))})`
                            } />
                            <Details.Item label={_t("trade_price")} value={
                              `${format.number(tradePrice)} (${format.number(tradePrice - (evaluation?.tradePrice || 0))})`
                            } />
                            <Details.Item label={_t("registration_fee_short")} value={
                              `${format.number(registrationFee)} (${format.number(registrationFee - (evaluation?.registrationFee || 0))})`
                            } />
                          </Details>
                        }
                      >
                        <span>
                          {format.number(registrationFee)} ({format.number(regulation)})
                        </span>

                      </Popover>
                    )
                  }
                  return null;
                })}

              </Space>
            </Timeline.Item>
          )
        })}
      </Timeline>
      <small>{_t("msg.valuesGivenInKr")}</small>
    </CollapseCard>
  );
};


function CaseStages() {
  const originalEvaluation = useTaxLegalEvaluation()
  const { stages, deleteStage, addStage, reorderStages, activeTab, setActiveTab } = useTaxLegalStages()
  const refs = useRef<(HTMLDivElement | null)[]>([])
  const currentRef = useRef<HTMLDivElement | null>(null)

  function handleMouseDown(index: number) {
    if (refs.current) {
      currentRef.current = refs.current[index]
    }

    let item = currentRef.current;
    if (!item) return;
    const bounds = item.parentElement?.parentElement?.parentElement?.parentElement;
    if (bounds) {
      item.dataset.minX = String(bounds.getBoundingClientRect().left)
      item.dataset.maxX = String(bounds.getBoundingClientRect().right)
    }
    item.dataset.initX = String(item.getBoundingClientRect().x);
    window.addEventListener('mousemove', handleDragElement);
    window.addEventListener('mouseup', handleMouseUp);

  }
  function handleMouseUp(ev: MouseEvent) {
    let item = currentRef.current;
    if (!item) return;
    const x = ev.clientX
    if (refs.current) {
      const insertionIndex = refs.current.findIndex((tab) => {
        const rect = tab?.getBoundingClientRect();
        if (tab === item) return false;
        if (rect && rect.left <= x && rect.right >= x) {
          return true
        }
        return false;
      })
      const itemIndex = refs.current.indexOf(item);
      if (insertionIndex > -1 && itemIndex > -1) {
        reorderStages(insertionIndex, itemIndex);
      }
    }
    item.style.transform = "translate(0px)"
    delete item.dataset.initMouseX;
    window.removeEventListener('mouseup', handleMouseUp)
    window.removeEventListener('mousemove', handleDragElement);
    currentRef.current = null;
  }

  function handleDragElement(ev: MouseEvent) {
    let item = currentRef.current
    if (item) {
      const offset = parseInt(item.dataset.initX || "0");
      const min = parseInt(item.dataset.minX || "0");
      const max = parseInt(item.dataset.maxX || "0");

      if (!item.dataset.initMouseX) {
        item.dataset.initMouseX = String(ev.clientX - offset)
      }

      const initMouseX = parseInt(item.dataset.initMouseX || "0");
      const minX = (min - initMouseX) - offset;
      const maxX = (max - initMouseX) - offset;
      let nextX = (ev.clientX - initMouseX) - offset
      if (nextX < minX) nextX = minX;
      else if (nextX > maxX) nextX = maxX
      item.style.transform = `translateX(${nextX}px)`
    }
  }

  function confirmDelete(stage: TaxLegalStage) {
    let shouldDelete = false
    const label = stage.type === 'complaint' ? _t("complaint") : _t("resumption")
    if (window.prompt("Indtast 'SLET' for at slette " + label) === "SLET") {
      if (window.confirm(_t("msg.confirm_delete"))) {
        shouldDelete = true
      }
    }
    if (shouldDelete) {
      deleteStage(stage.fingerprint)
      message.warning(label + " " + _t("deleted").toLowerCase())
    } else {
      message.info(label + " " + _t("msg.not_deleted").toLowerCase())
    }

  }


  return (
    <TaxLegalPriceDifferenceStore.Provider initialState={getInitialPriceDifference(stages, originalEvaluation)}>

      <Tabs
        className="taxlegal-case-management"
        tabBarExtraContent={
          <Dropdown placement="bottomRight" overlay={
            <Menu>
              <Menu.Item key={"addComplaint"} onClick={() => addStage("complaint")}>{_t("add", "complaint")}</Menu.Item>
              <Menu.Item key={"addResumption"} onClick={() => addStage("resumption")}>{_t("add", "resumption")}</Menu.Item>
            </Menu>
          }>
            <Button size="small">+</Button>
          </Dropdown>
        }
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        {stages.map((stage, i) => {
          if (stage.type === 'price_check') {
            return (
              <Tabs.TabPane animated forceRender tab={<>Pristjek <small><sub>{stage.timestamp ? date(stage.timestamp) : ""}</sub></small></>} key={stage.fingerprint}>
                <PriceCheck fingerprint={stage.fingerprint} position={"0"} />
              </Tabs.TabPane>
            )
          }
          if (stage.type === 'complaint') {
            return (
              <Tabs.TabPane forceRender tab={
                <div className="custom-tab"
                  ref={el => refs.current[i] = el}
                  onMouseDown={() => handleMouseDown(i)}
                >
                  <span className="mr-05">Klagesag <small><sub>{stage.timestamp ? date(stage.timestamp) : ""}</sub></small></span>
                  <Popconfirm title={_t("confirm_action")} okText={_t("delete")} onConfirm={() => confirmDelete(stage)}>
                    <Button onClick={(e) => { e.stopPropagation(); }} type="text" size="small" danger icon={<Icon name="trash-bin-outline" />} />
                  </Popconfirm>
                </div>
              }
                key={stage.fingerprint}>
                <Complaint fingerprint={stage.fingerprint} position={stage.position} />
              </Tabs.TabPane>
            )
          }
          if (stage.type === 'resumption') {
            return (
              <Tabs.TabPane forceRender
                tab={
                  <div className="custom-tab"
                    ref={el => refs.current[i] = el}
                    onMouseDown={() => handleMouseDown(i)}
                  >
                    <span className="mr-05">{_t("resumption")} <small><sub>{stage.timestamp ? date(stage.timestamp) : ""}</sub></small></span>
                    <Popconfirm title={_t("confirm_action")} okText={_t("delete")} onConfirm={() => confirmDelete(stage)}>
                      <Button onClick={(e) => { e.stopPropagation(); }} type="text" size="small" danger icon={<Icon name="trash-bin-outline" />} />
                    </Popconfirm>
                  </div>
                } key={stage.fingerprint}
              >
                <Resumption fingerprint={stage.fingerprint} position={stage.position} />
              </Tabs.TabPane>
            )
          }
          return null;
        })}
      </Tabs>
    </TaxLegalPriceDifferenceStore.Provider>
  )
}
