import { Card, Divider, Form, Input } from "antd";
import React, { FC, useState } from "react";
import { useEnums } from "../../../../components/useEnum";
import _t from "../../../../lang/translate";
import { customersAutoComplete, } from "../../../../services/autocompleteService";
import EnumRadio from "../../../../components/form/EnumRadio";
import consts from "../../../../utilities/consts";
import LoadingContainer from "../../../../components/loadingContainer";
import ServerSelectBox from "../../../../components/serverSelectBox";
import FancySubmitButton from "../../../../components/fancySubmitButton";
import CustomerInfoFormItems from "../../../../components/customerInfoFormItems";
import { SwitchChangeEventHandler } from "antd/es/switch";
import SwitchInput from '../../../../components/form/switchInput';
import AdminSelectDealerUser from "../../../../components/form/adminUserSelect";

const { TextArea } = Input;

interface TaxNewCaseAdminPanelProps {
  loading: boolean;
  caseType?: "MOTOR_KOMPAGNIET" | "TAX"
}

const TaxNewCaseAdminPanel: FC<TaxNewCaseAdminPanelProps> = ({ loading, caseType }) => {
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
  const handleIsNewCustomer: SwitchChangeEventHandler = (checked, _e) => {
    setIsNewCustomer(checked);
  }

  return (
    <Card bordered={false} title={_t("case_created_for")}>
      <LoadingContainer loading={loading}>

        {caseType === "MOTOR_KOMPAGNIET" ? (
          <>
            <Form.Item>
              <SwitchInput
                label={_t('new_customer')}
                checked={isNewCustomer}
                onChange={handleIsNewCustomer}
              />
            </Form.Item>

            {isNewCustomer ? (
              <ManualCustomerCreationFormItems />
            ) : (
              <AdminUserSelect />
            )}
          </>
        ) : (
          <AdminUserSelect />
        )}
        <Divider />
        <Form.Item {...consts.formItemLabellessProps} name="paymentMethodId" rules={[{ required: true }]}>
          <EnumRadio nameKey={["paymentMethod"]} />
        </Form.Item>
        <Form.Item {...consts.formItemLabellessProps} name="reason">
          <TextArea autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Første sag rabat.." />
        </Form.Item>
      </LoadingContainer>
      <div className="fancy-btn-wp text-center">
        <FancySubmitButton loading={loading} />
      </div>
    </Card>
  );
};

export default TaxNewCaseAdminPanel;

const AdminUserSelect = () => {
  const [clientType] = useEnums(["client", "type"]);

  return (
    <>
      <Form.Item noStyle shouldUpdate={(curr, next) => curr.client.typeId !== next.client.typeId}>
        {({ setFieldsValue }) => {
          return (
            <Form.Item {...consts.formItemLabellessProps} name={["client", "typeId"]}>
              <EnumRadio
                nameKey={["client", "type"]}
                onChange={(e) => setFieldsValue({ calculationTypeId: e.target.value })}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <AdminSelectDealerUser/>

      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client.typeId !== curr.client.typeId}>
        {({ getFieldValue }) => {
          const typeId = getFieldValue(["client", "typeId"]);
          return (
            clientType &&
            typeId === clientType[1]?.id && (
              <Form.Item
                {...consts.formItemLabellessProps}
                name={["client", "customerId"]}
                rules={[{ required: true }]}
              >
                <ServerSelectBox apiUrl={customersAutoComplete()} placeholder={_t("choose", "customer")} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item {...consts.formItemLabellessProps} name="calculationTypeId">
        <EnumRadio nameKey={["taxCase", "calculationType"]} />
      </Form.Item>
    </>
  );
};


function ManualCustomerCreationFormItems() {
  return (
    <Form.Item wrapperCol={{ span: 24 }} className={"ant-form-vertical"}>
      <CustomerInfoFormItems namePrefix={"customerData"} />
    </Form.Item>
  )
}
