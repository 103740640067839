import { Button, Form, Input, Select, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";
import { TagStoreQuick, taxLegalCategories } from "../../types/taxLegalTypes";
import { addTaxLegalTag } from "../../services/taxLegalService";
import consts from "../../utilities/consts";
import { useState } from "react";


type Payload = {
  categoryId?: string;
  initialValues?: string;

}
export default function AddTag<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [loading, setLoading] = useState(false)
  const [tmpForm] = Form.useForm<TagStoreQuick>();
  payload = payload as Payload;

  const handleSubmit = async (values: TagStoreQuick) => {
    try {
      setLoading(true)
      const { data } = await addTaxLegalTag(values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false)
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="horizontal" initialValues={{
      title: payload.initialValues,
      categoryId: payload.categoryId
    }} onFinish={handleSubmit}
      {...consts.formItemProps}
    >
      <h2 className="modal-title">{_t("add", "tag")}</h2>
        <Form.Item label={_t("title")} name="title" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item label={_t("category")} name="categoryId" rules={[{ required: true }]} >
          <Select disabled={!!payload.categoryId}>
            {taxLegalCategories.map((cat) => (
              <Select.Option key={cat.categoryId} value={cat.categoryId}>{cat.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" loading={loading} onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("add")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(AddTag);
