import { Card, Col, Form, Row } from "antd";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingContainer from "../../../components/loadingContainer";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import PageBody from "../../../layout/pageBody";
import PageTitle from "../../../layout/pageTitle";
import { getPath } from "../../../routes/appRoutes";
import { createResellerTaxCase } from "../../../services/resellerTaxService";
import { ScreenProps } from "../../../types/appTypes";
import {
  isTaxDataSource,
  ResellerTaxCaseCreation,
  ResellerTaxCustomerType,
  ResellerTaxDataSourceType
} from "../../../types/resellerTaxTypes";
import consts from "../../../utilities/consts";
import { convertToApiFileList } from "../../../utilities/convertTypes";
import { isAxiosError } from "../../../utilities/typeGuard";
import TaxNewCaseAdminPanel from "../taxCommon/admin/taxNewCaseAdminPanel";
import ResellerTaxFetchCarFC from "./resellerTaxFetchCarFC";

type ResellerTaxCaseCreationForm = ResellerTaxCaseCreation & {
  customerInfo?: ResellerTaxCustomerType | null;
}
const ResellerTaxNewCase: FC<ScreenProps> = ({ title }) => {
  const { user, hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("resellerTax.isAdmin");
  const [activeTab, setActiveTab] = useState<ResellerTaxDataSourceType>("vin");
  const [newResellerTaxCaseForm] = Form.useForm<ResellerTaxCaseCreationForm>();
  const handleTabChange = (key: string) => isTaxDataSource(key) && setActiveTab(key);
  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory();

  const initCustomerData: ResellerTaxCustomerType = {
    typeId: 1,
    address: null,
    city: null,
    cvr: null,
    email: null,
    name: null,
    phone: null,
    zip: null,
    nameFirst: null,
    nameLast: null,
  }

  const initCase = {
    id: 0,
    evaluationTypeId: 1,
    vehicle: {
      typeId: 1,
      link: "",
      vin: "",
    },
    note: null,
    files: null,
    paymentMethodId: 1,
    client: {
      userId: user?.id,
      dealerId: user?.dealer?.id,
      typeId: 1,
    },
    customerData: initCustomerData,
    calculationTypeId: 1,
  };

  const createNewResellerTaxCase = async (values: ResellerTaxCaseCreationForm) => {
    try {
      setLoading(true);
      let dataSourceId = 0;
      if (activeTab === "vin") {
        dataSourceId = 2;
      } else if (activeTab === "link") {
        dataSourceId = 1;
      } else if (activeTab === "manual") {
        dataSourceId = 3;
      }
      const newResellerTaxCase: ResellerTaxCaseCreationForm = {
        ...values,
        dataSourceId,
        files: convertToApiFileList(values?.files || []),
      };
      const { data } = await createResellerTaxCase(newResellerTaxCase);
      data.id && history.replace(getPath("resellerTax", data.id));
    } catch (error) {
      renderErrorMessage(error)
      if(isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        errors && newResellerTaxCaseForm.setFields(errors);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle fluid backBtn title={title} />
      <PageBody>
        <Form
          {...consts.formItemProps}
          form={newResellerTaxCaseForm}
          initialValues={initCase}
          requiredMark={false}
          onFinish={createNewResellerTaxCase}
        >
          <Row align="top" gutter={24}>
            {isAdmin && (
              <Col {...consts.newCaseAdminColumnProps}>
                <TaxNewCaseAdminPanel
                  caseType={"MOTOR_KOMPAGNIET"}
                  loading={loading}
                />
              </Col>
            )}
            <Col {...consts.newCaseContentColumnProps(isAdmin)} flex="auto" order={2}>
              <Card bordered={false} className="card-tabs" title={_t("new", "reseller-tax-case")}>
                <LoadingContainer loading={loading}>
                  <ResellerTaxFetchCarFC
                    isAdmin={isAdmin}
                    onTabChange={handleTabChange}
                    activeTab={activeTab}
                  />
                </LoadingContainer>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default ResellerTaxNewCase;
