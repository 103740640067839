export function hasNumber(myString: string) {
  return /\d/.test(myString);
}

export function capitalize(s: string) {
  const firstLetter = s.charAt(0);
  return hasNumber(firstLetter) ? s : firstLetter.toUpperCase() + s.slice(1);
}

export const getRandomString = (length: number = 5) => (Math.random() + 1).toString(36).substring(length);

export const isValidVin = (vin: unknown): boolean => {
  if (typeof vin === 'string' && vin.length === 17) {
    const vinPattern = /^[a-zA-Z0-9]+$/
    return vinPattern.test(vin)
  }
  return false;
}

export const formatVin = (vin: unknown) => {
  return String(vin).toUpperCase()
}

export const slugify = (text: string): string => {
  let str = text;
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return str.length > 3 ? str : getRandomString(8);
}
