import React, {FC} from "react";
import {Space} from "antd";
import ImportCollectionAuthorizationBtn from "./importCollectionAuthorizationBtn";
import ImportExpectedDeliveryDate from "./importExpectedDeliveryDate";
import ImportPickupAgreementBtn from "./importPickupAgreementBtn";
import DividerLine from "../../../../components/lineSplit";
import ImportPickupOrderBtn from "./importPickupOrderBtn";
import {updatePickupSelf, updateTransportOrdered} from "../../../../services/importService";
import _t from "../../../../lang/translate";
import StatusFlagToggle from "./statusFlagToggle";

const ImportTransportActions: FC = () => {
  return (
    <>
      <StatusFlagToggle
        color={"default"}
        flag={"pickupSelf"}
        callable={updatePickupSelf}
        label={_t("pickup_self")}
      />
      <DividerLine className="mt-05 mb-1"/>
      <ImportExpectedDeliveryDate/>
      <StatusFlagToggle
        flag={"transportOrdered"}
        callable={updateTransportOrdered}
        label={_t("transport", "ordered")}
      />
      <Space wrap>
        <ImportCollectionAuthorizationBtn/>
        <ImportPickupAgreementBtn/>
        <ImportPickupOrderBtn/>
      </Space>
    </>
  );
};

export default ImportTransportActions;
