import { Button, Form, Input, message, Space } from "antd";
import React, { useState } from "react";
import _t from "../../lang/translate";
import { createOffer } from "../../services/leasingService";
import { LeasingCreateOfferType } from "../../types/leasingTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { isAxiosError } from "../../utilities/typeGuard";

const { TextArea } = Input;

export default function NewOfferLeasing<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<LeasingCreateOfferType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { leasingCaseId, initialValues } = payload;

  const handleSave = async (values: LeasingCreateOfferType) => {
    try {
      setLoading(true);
      const { data } = await createOffer(leasingCaseId, values);
      onSuccess(data as T);
    } catch (error) {
      if (isAxiosError(error)){
        const { errors, message: msg } = error.response?.data;
        Array.isArray(errors) ? tmpForm.setFields(errors) : message.error(msg || _t("msg.unknown_error"));
      }
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleSave} initialValues={initialValues}>
      <h2 className="modal-title">{_t("generate_offer")}</h2>
      <Form.Item name="note" label={_t("note")}>
        <TextArea placeholder={_t("leasing_offer_note_placeholder")} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" disabled={loading} onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("generate_offer")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(NewOfferLeasing);
