import React, {FC, useState} from "react";
import {Button, Col, Dropdown, Menu, message, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import {LoadingOutlined, PlayCircleOutlined} from '@ant-design/icons';
import {useHistory} from "react-router";
import Icon from "../../../assets/icons/icon";
import ActivityDrawer from "../../../components/activityDrawer";
import Checklist from "../../../components/checklist/checklist";
import {renderErrorMessage} from "../../../components/messages/errorMessage";
import openModal from "../../../components/modal/openModal";
import StatusTag from "../../../components/statusTag";
import TaxCaseStore from "../../../contexts/taxCaseStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import {getPath} from "../../../routes/appRoutes";
import log from "../../../services/logService";
import {
  createEstimaticOfTaxCase,
  createImportOfTaxCase,
  createTaxDocofTaxCase,
  saveTaxCaseStatusToInProgress
} from "../../../services/taxService";
import {LocationStateType} from "../../../types/appTypes";
import {TaxCase} from "../../../types/taxTypes";
import TaxCaseDealer from "./admin/taxCaseDealer";
import {isAxiosError} from "../../../utilities/typeGuard";
import appConfig from "../../../appConfig";

const jumpToWidgetCaseUrlBase = process.env.REACT_APP_WIDGET_JUMP_URL || '';


const TaxCaseHeadExtra: FC = () => {
  const history = useHistory<LocationStateType>();
  const {taxCase, setTaxCase} = TaxCaseStore.useContainer();
  const {hasPermission} = UserStore.useContainer();
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const isAdmin = hasPermission("tax.isAdmin");
  const canCreateImport = hasPermission("import.create");
  const canCreateTaxDoc = hasPermission("taxDoc.create");
  const canCreateLeasing = hasPermission("leasing.create");
  const canCreateEstimatic = hasPermission("tax.isAdmin");
  const canCreatePartnerLeasing = hasPermission("partner_leasing.create");

  const handleCreateLeasing = async () => {
    try {
      const {data} = await openModal<{ data: any }>("taxToLeasingCase", {
        id: taxCase.id,
        from: "tax",
      });
      if (data)
        history.push({
          pathname: getPath("leasing", "new"),
          state: {from: {pathname: "tax"}, data},
        });
    } catch (e) {
    }
  };
  const handleCreatePartnerLeasing = async () => {
    try {
      const {data} = await openModal<{ data: any }>("taxToLeasingCase", {
        id: taxCase.id,
        from: "tax",
        partnerCase: true,
      });
      if (data)
        history.push({
          pathname: getPath("partnerLeasing", "new"),
          state: {
            from: {pathname: "tax"},
            data,
          },
        });
    } catch (e) {
    }
  };

  const handleCreateTaxDoc = async () => {
    try {
      const {data} = await createTaxDocofTaxCase(taxCase.id);
      if (data?.id) {
        history.push({
          pathname: getPath("taxDoc", `${data.id}`),
        });
      } else {
        log("Exporting tax to taxDoc case returned data without Id", {data});
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const handleCreateImport = async () => {
    try {
      const {data} = await createImportOfTaxCase(taxCase.id);
      if (data?.id) {
        history.push({
          pathname: getPath("import", `${data.id}`),
        });
      } else {
        log("Exporting tax to Import case returned data without Id", {data});
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const handleCreateOpenEstimatic = async () => {
    const link = taxCase.links?.estimatic;

    if (link) {
      window.open(link, "_blank");
      return true;
    }

    try {
      const {data} = await createEstimaticOfTaxCase(taxCase.id);
      setTaxCase(data);
      message.success(_t("msg.process_started"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const exportMenu = (
    <Menu>
      {canCreateLeasing && (
        <Menu.Item key="toLeasing" onClick={handleCreateLeasing}>
          {_t("leasing")}
        </Menu.Item>
      )}
      {canCreatePartnerLeasing && (
        <Menu.Item key="toPartnerLeasing" onClick={handleCreatePartnerLeasing}>
          {_t("partner_leasing")}
        </Menu.Item>
      )}
      {canCreateTaxDoc && (
        <Menu.Item key="toTaxDoc" onClick={handleCreateTaxDoc}>
          {_t("tax-doc")}
        </Menu.Item>
      )}
      {canCreateImport && (
        <Menu.Item key="toImport" onClick={handleCreateImport}>
          {_t("importCase")}
        </Menu.Item>
      )}
    </Menu>
  );

  const openMenu = (
    <Menu>
      {taxCase.links?.dealerView && (
        <Menu.Item key="dmfPro">
          <a href={taxCase.links.dealerView} target="_blank">
            DMF PRO
          </a>
        </Menu.Item>
      )}
      {taxCase.caseSourceId === appConfig("widgetCaseSourceId") && (
        <Menu.Item key="toWidget">
          <a href={`${jumpToWidgetCaseUrlBase}/${taxCase.id}`} target="_blank">
            WIDGET
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleStatusChange = async () => {
    try {
      const {id, status} = taxCase;
      const newCase = await openModal<TaxCase>("changeStatus", {
        id,
        context: "tax",
        initialValues: {
          id: status?.id,
          forcedStatus: taxCase.flags.forcedStatus,
        },
      });
      setTaxCase(newCase);
    } catch (error) {
    }
  };

  const handleCaseStatus = async () => {
    try {
      const data = await saveTaxCaseStatusToInProgress(taxCase.id);

      if (data) {
        setTaxCase(data.data);

        return true;
      }
    } catch (error) {
      renderErrorMessage(error)

      return false;
    }
  };

  return (
    <Row align="middle" gutter={[8, 16]}>
      <Col flex="auto">{isAdmin && <TaxCaseDealer/>}</Col>
      <Col>
        <Space wrap>
          {taxCase.flags.isPriority && (
            <Tooltip title={_t("high_priority")}>
              <Tag color="error" className="icon-tag">
                <Icon name="alert-outline"/>
              </Tag>
            </Tooltip>
          )}

          {
            isAdmin && (!taxCase.isInProgress) &&
            <Tooltip title={_t('changeToInProgress')}>
              <PlayCircleOutlined
                style={{cursor: 'pointer', fontSize: 30, marginLeft: 5}}
                onClick={handleCaseStatus}
              />
            </Tooltip>
          }

          {isAdmin ? (
            <StatusTag locked={taxCase.flags.forcedStatus} status={taxCase.status} onClick={handleStatusChange}/>
          ) : (
            <StatusTag status={taxCase.status}/>
          )}


          {(canCreateLeasing || canCreatePartnerLeasing) && (
            <Dropdown overlay={exportMenu}>
              <Button>{_t("move", "to")}</Button>
            </Dropdown>
          )}

          {canCreateEstimatic && (
            <Popconfirm
              placement="topLeft"
              onConfirm={handleCreateOpenEstimatic}
              title={_t('confirm_action')}
              disabled={taxCase.flags.generatingEstimaticCase}
            >
              <Button disabled={taxCase.flags.generatingEstimaticCase}>
                {taxCase.links?.estimatic ? _t("open", "estimatic") : _t("create", "estimatic")}
                {taxCase.flags.generatingEstimaticCase ? (<LoadingOutlined className="ml-1"/>) : null}
              </Button>
            </Popconfirm>
          )}

          {isAdmin && (
            <>
              <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
              <ActivityDrawer
                visible={drawerVisibility}
                isAdmin={isAdmin}
                setVisibility={setDrawerVisibility}
                type="tax"
                id={taxCase.id}
                sendingDisabledReason={!taxCase.flags.partnerCase ? null : _t('externalCaseContactCustomerManually')}
              />
            </>
          )}
          {isAdmin && taxCase.links?.dealerView && (
            <Dropdown overlay={openMenu}>
              <Button>{_t("open", "to")}</Button>
            </Dropdown>
          )}

          {hasPermission("tax.checklist") && (
            <Checklist
              {...{
                isAdmin,
                id: taxCase.id,
                context: "tax",
                btnText: _t("checklist"),
                asDrawer: true
              }}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default TaxCaseHeadExtra;
