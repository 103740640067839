import React from "react";
import { Form } from "antd";
import ServerSelectBox from "../../components/serverSelectBox";
import { useEnums } from "../../components/useEnum";
import _t from "../../lang/translate";
import { dealersAutoComplete, usersAutoComplete } from "../../services/autocompleteService";
import consts from "../../utilities/consts";
import SelectUser from "./selectUser";


export default function AdminSelectDealerUser() {
  const [clientType] = useEnums(["client", "type"]);

  return (
    <>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client.typeId !== curr.client.typeId}>
        {({ getFieldValue }) => {
          const typeId = getFieldValue(["client", "typeId"]);
          return (
            clientType &&
            typeId === clientType[0]?.id && (
              <Form.Item
                {...consts.formItemLabellessProps}
                name={["client", "dealerId"]}
                rules={[{ required: true }]}
              >
                <ServerSelectBox placeholder={_t("choose", "dealer")} apiUrl={dealersAutoComplete()} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) =>
          prev.client.dealerId !== curr.client.dealerId || prev.client.typeId !== curr.client.typeId
        }
      >
        {({ getFieldValue }) => {
          const dealerId = getFieldValue(["client", "dealerId"]);
          const typeId = getFieldValue(["client", "typeId"]);

          return (
            <SelectUser dealerId={dealerId} typeId={typeId} name={["client", "userId"]} rules={[{ required: true }]}/>
          )
        }}
      </Form.Item>
    </>
  );
};


