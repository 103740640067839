import { Form } from "antd";
import { FormItemProps } from "antd/es";
import _t from "../../lang/translate";
import { usersAutoComplete } from "../../services/autocompleteService";
import consts from "../../utilities/consts";
import ServerSelectBox from "../serverSelectBox";
import { useEnums } from "../useEnum";


type Props = {
  dealerId: any;
  typeId: any;
} & FormItemProps

/**
 * typeId - User type. Is it a customer or a user. "Bruger" vs "Kunde"
 */
export default function SelectUser({ dealerId, typeId, ...rest }:Props) {
  const [clientType] = useEnums(["client", "type"]);

  if (!(clientType && typeId === clientType[0]?.id)) return null;

  return  (
    <Form.Item {...consts.formItemLabellessProps} {...rest}>
      <ServerSelectBox
        apiUrl={usersAutoComplete()}
        disabled={!dealerId}
        placeholder={_t("choose", "user")}
        filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
      />
    </Form.Item>
  )
};
