import { Space, Spin } from "antd";
import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { renderErrorMessage } from "../components/messages/errorMessage";
import { getInternalLink } from "../components/serverLink";
import _t from "../lang/translate";
import { getPath } from "../routes/appRoutes";
import { getLink } from "../services/redirectServices";
import { ParamTypes } from "../types/appTypes";

const Redirect: FC = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  useEffect(() => {
    async function getLocation() {
      try {
        const { data } = await getLink(id);
        const link = data.data;
        const location = link && getInternalLink(link);
        history.replace(location || getPath("notFound"));
      } catch (error) {
        renderErrorMessage(error)
        history.replace(getPath("notFound"));
      }
    }

    getLocation();
  }, [id, history]);

  return (
    <Space className="w-100" align="center" direction="vertical">
      <Spin size="large" />
      {_t("redirecting")}
    </Space>
  );
};

export default Redirect;
