import { Button, message, Popconfirm, Result } from "antd";
import debounce from "lodash/debounce";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import DataTable from "../../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../../components/table/dataTableTypes";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import { deleteLeasingCase, leasingCasesUrl } from "../../services/leasingService";
import { pageSizeKey, searchKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import { PaginationedData } from "../../types/apiTypes";
import { IdType, LocationStateType } from "../../types/appTypes";
import { LeasingCaseListType } from "../../types/leasingTypes";

interface LeasingOpenProps {
  columns: CustomColumnsType<LeasingCaseListType>;
  filters?: ReactNode;
  statusId?: number;
  onExport?: () => void;
  partnerCase: boolean;
}

const LeasingCases: FC<LeasingOpenProps> = ({ columns, filters, onExport, statusId, partnerCase }) => {
  const { hasPermission } = UserStore.useContainer();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const location = useLocation<LocationStateType>();
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<LeasingCaseListType>>("", {
    data: [],
  });
  const isAdmin = hasPermission(partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin");
  const canDeleteCase = hasPermission(partnerCase ? "partner_leasing.delete" : "leasing.delete");

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (statusId) {
      query.set("status", statusId.toString());
    }
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set("filter[partnerCase]", partnerCase ? "1" : "0");
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = leasingCasesUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, refreshId, statusId, delayedSetUrl, partnerCase]);

  const tableActions: RenderFunc<LeasingCaseListType> = (_, { id, car, status }) => {
    const isOpenCase = statusId === 19;
    const canDelete = isAdmin || isOpenCase;
    return canDeleteCase && canDelete ? (
      <Popconfirm
        placement="topLeft"
        onConfirm={() => handleDelete(id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>
              {id} : {car}
            </strong>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    ) : null;
  };

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteLeasingCase(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      renderErrorMessage(ex, _t("msg.not_deleted"));

    }
  };

  return isError ? (
    <Result status="error" title={_t("msg.unknown_error")} />
  ) : (
    <DataTable<LeasingCaseListType>
      onExport={onExport}
      onRefresh={handleRefresh}
      filter={filters}
      loading={isLoading}
      renderActions={tableActions}
      columnStorageKey="LEASING"
      columns={columns}
      dataSource={data.data}
      meta={data.meta}
    />
  );
};

export default LeasingCases;
