import { Alert, Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadFile } from "antd/lib/upload/interface";
import debounce from "lodash/debounce";
import React, { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import DocumentsUpload from "../../components/documentsUpload";
import FancySubmitButton from "../../components/fancySubmitButton";
import AdminSelectDealerUser from "../../components/form/adminUserSelect";
import InputVin from "../../components/form/inputVin";
import DividerLine from "../../components/lineSplit";
import LoadingContainer from "../../components/loadingContainer";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import PageTitle from "../../layout/pageTitle";
import { getPath } from "../../routes/appRoutes";
import { mediaApiEndpoint } from "../../services/mediaService";
import { createTaxLegalCase } from "../../services/taxLegalService";
import { getRemoteData } from "../../services/vehicleService";
import { ScreenProps } from "../../types/appTypes";
import { StoreTaxLegalCase } from "../../types/taxLegalTypes";
import consts from "../../utilities/consts";
import { convertToApiFileList } from "../../utilities/convertTypes";
import { isValidVin } from "../../utilities/stringExtraFunctions";

const { Paragraph: P, Text } = Typography

const TaxLegalNew: FC<ScreenProps> = ({ title }) => {
  const { user } = UserStore.useContainer();
  const history = useHistory()
  const initialTaxNewCase: StoreTaxLegalCase = {
    vin: "",
    note: null,
    externalReferenceCode: "",
    materialFiles: [],
    documentationFiles: [],
    client: {
      dealerId: user?.dealer?.id,
      typeId: 1,
      userId: user?.id,
    },
  };
  const [newTaxCaseForm] = Form.useForm<StoreTaxLegalCase>();
  const [loading, setLoading] = useState<boolean>(false);


  async function createNewTaxLegalCase(values: StoreTaxLegalCase) {
    try {
      setLoading(true)

      values.documentationFiles = convertToApiFileList(values.documentationFiles as unknown as UploadFile[])
      values.materialFiles = convertToApiFileList(values.materialFiles as unknown as UploadFile[])
      values.client.typeId = 1;

      const {data: newTaxLegalCase} = await createTaxLegalCase(values)
      newTaxLegalCase.id && history.replace(getPath("taxLegal", newTaxLegalCase.id));
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle fluid backBtn title={title} />
      <PageBody>
        <Form
          {...consts.formItemProps}
          form={newTaxCaseForm}
          initialValues={initialTaxNewCase}
          requiredMark={false}
          onFinish={createNewTaxLegalCase}
        >
          <Row align="top" gutter={24}>
            <Col {...consts.newCaseAdminColumnProps}>
              <CreateCard loading={loading} />
            </Col>
            <Col {...consts.newCaseContentColumnProps(true)}>
              <MainFormBody loading={loading} />
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default TaxLegalNew;



type CreateCardProps = {
  loading: boolean;
}
function CreateCard({ loading }: CreateCardProps) {
  return (
    <Card bordered={false} title={_t("case_created_for")}>
      <LoadingContainer loading={loading}>
        <AdminSelectDealerUser />
      </LoadingContainer>
      <DividerLine fluid />
      <div className="fancy-btn-wp text-center">
        <FancySubmitButton loading={loading} />
      </div>
    </Card>
  )
}

type MainFormBodyProps = {
  loading: boolean;
}
function MainFormBody({ loading }: MainFormBodyProps) {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("tax.isAdmin");
  const [loadingVin, setLoadingVin] = useState(false)
  const [vinData, setVinData] = useState({
    makeModelvariant: "",
    vin: "",
    numberPlate: "",
    taxGroup: ""
  });

  const fetchData = async (vin: string) => {
    if (!isValidVin(vin)) {
      setVinData({
        makeModelvariant: "",
        vin: "",
        numberPlate: "",
        taxGroup: ""
      });
      return;
    }
    try {
      setLoadingVin(true)
      const data = await getRemoteData(vin, { items: ["dmr"] })
      const dmr = data.data.data.dmr
      if (dmr && dmr.raw && dmr.mapped) {
        const make = dmr.mapped?.brand;
        const model = dmr.mapped?.model;
        const variant = dmr.mapped?.equipmentVariant;
        setVinData({
          makeModelvariant: `${make} ${model} ${variant}`,
          numberPlate: dmr.raw.data?.registration ?? "",
          taxGroup: dmr.raw.data?.type ?? "",
          vin: dmr.mapped?.vin ?? "",
        })
      } else {
        throw new Error("Stelnummer kunne ikke findes i DMR")
      }
    } catch (err) {
      renderErrorMessage(err)
    } finally {
      setLoadingVin(false)
    }
  }

  const debouncedFetchData = useCallback(
    debounce((value: string) => {
      fetchData(value)
    }, 400),
    [fetchData]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Capture the value early
      const value = e.target.value;
      debouncedFetchData(value);
    },
    [debouncedFetchData]
  );



  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Card bordered={false} bodyStyle={{ padding: 24 }} title={_t("new", "priceCheck") + " " + _t("case").toLowerCase()}>
      <LoadingContainer loading={loading}>
        <Alert type="info" showIcon className="mb-1" closable message={
          <>
            <P>Ved oprettelse af sag, vil du få tilsendt en fuldmagt til at vi må håndterer denne sag for dig overfor Motorstyrelsen. Det betyder, at fremtidige svar fra Motorstyrelsen vil blive sendt til og administreret af Dansk Motor Finans.</P>
            <P className="mb-0">Du vil løbende modtage opdateringer gennem systemet og på mail.</P>
          </>
        } />
        <Spin style={{marginTop: -8}} spinning={loadingVin}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={["vin"]}
            className="mb-1"
            rules={[{ required: true }]}
          >
            <InputVin disabled={loadingVin} onChange={handleChange} size="large" placeholder={_t("insert_vin_here")} prefix={<Icon name="barcode-outline" />} />
          </Form.Item>
        </Spin>
        <dl className="mb-2">
          <Row justify="space-between">
            <Col>
              <dt><Text type="secondary">{_t("makeModelVariant")}</Text></dt>
              <dd className="mb-0">{vinData.makeModelvariant}</dd>
            </Col>
            <Col>
              <dt><Text type="secondary">{_t("vin")}</Text></dt>
              <dd className="mb-0">{vinData.vin}</dd>
            </Col>
            <Col>
              <dt><Text type="secondary">{_t("registration_number")}</Text></dt>
              <dd className="mb-0">{vinData.numberPlate}</dd>
            </Col>
            <Col>
              <dt><Text type="secondary">{_t("taxGroup")}</Text></dt>
              <dd className="mb-0">{vinData.taxGroup}</dd>
            </Col>
          </Row>
          <small className="text-right d-block mt-1"><em>Opslag fra Motorregister</em></small>
        </dl>
        <Card bodyStyle={{ padding: 16 }}>
          <Form.Item name="materialFiles" label={_t("upload", "materialsSummons")} valuePropName="fileList" getValueFromEvent={normFile}>
            <DocumentsUpload
              canLockFiles={isAdmin}
              action={mediaApiEndpoint}
              canDelete={isAdmin}
              multiple={true}
              maxCount={2}
            />
          </Form.Item>
        </Card>
        <Card bodyStyle={{ padding: 16 }}>
          <Form.Item name="documentationFiles" label={_t("upload", "documentation")} valuePropName="fileList" getValueFromEvent={normFile}>
            <DocumentsUpload
              canLockFiles={isAdmin}
              action={mediaApiEndpoint}
              canDelete={isAdmin}
              multiple={true}
            />
          </Form.Item>
        </Card>
        <Form.Item name="externalReferenceCode" label={_t("ownReferenceNumber")} rules={[{required:true}]}>
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item name="note" label={_t("note_to_case")}>
          <TextArea disabled={loading} autoSize={{ minRows: 3, maxRows: 6 }} />
        </Form.Item>
      </LoadingContainer>
    </Card>
  )
}
