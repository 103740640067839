import { FC } from "react";
import { Button, message, Modal } from "antd";
import _t from "../../../../lang/translate";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import { completeImportCase } from "../../../../services/importService";
import { isNum } from "../../../../utilities/typeGuard";
import openModal from "../../../../components/modal/openModal";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

const ImportAcceptCaseBtn: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const handleReview = async () => {
    const merchantId = importCase.merchant?.id;

    //Open review modal after sending offer:
    if (merchantId) {
      const { data: newRatingScore } = await openModal<{data: unknown}>("review", {
        initialValues: { sourceId: importCase.id, sourceContext: "import" },
        targetId: merchantId,
        targetContext: "merchant",
      });
      // Populate the new rating score:
      if (isNum(newRatingScore) && importCase.merchant) {
        const merchant = { ...importCase.merchant, rating: newRatingScore };
        setImportCase({ ...importCase, merchant });
      }
    }
  };

  const handleAccept = async () => {
    try {
      const { data } = await completeImportCase(importCase.id);
      setImportCase(data);
      handleReview();
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  const confirmAccept = () => {
    Modal.confirm({
      title: _t("btn.complete"),
      content: _t("msg.confirm_complete_case"),
      okText: _t("yes"),
      okButtonProps: { color: "green" },
      onOk: handleAccept,
    });
  };

  return (
    <Button type="ghost" color="green" onClick={confirmAccept}>
      {_t("btn.complete", "case")}
    </Button>
  );
};

export default ImportAcceptCaseBtn;
