import { Button, Form, message, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC, useState } from "react";
import _t from "../../lang/translate";
import { updateReview } from "../../services/reviewService";
import { ReviewType } from "../../types/reviewType";
import { isNum } from "../../utilities/typeGuard";
import RateInput from "../rateInput";
import { renderErrorMessage } from "../messages/errorMessage";

interface ReviewItemEditProps {
  review: ReviewType;
  stopEditing: () => void;
  onNewScore: (newReview: ReviewType, newScore: number) => void;
}

const ReviewItemEdit: FC<ReviewItemEditProps> = ({ stopEditing, onNewScore, review }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ReviewType) => {
    const newReview = { ...review, ...values };
    try {
      setLoading(true);
      const { data } = await updateReview(newReview.id, newReview);
      if (isNum(data.data)) onNewScore(newReview, data.data);
      setLoading(false);
      stopEditing();
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form<ReviewType> initialValues={review} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <Form.Item name="score" rules={[{ required: true }]}>
        <RateInput allowHalf />
      </Form.Item>
      <Form.Item name="note">
        <TextArea autoSize={{ maxRows: 6, minRows: 2 }} placeholder={_t("notes")} />
      </Form.Item>

      <div className="text-right">
        <Space>
          <Button onClick={stopEditing} disabled={loading}>
            {_t("send")}
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("save")}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default ReviewItemEdit;
