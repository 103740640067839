import { UploadFile } from "antd/lib/upload/interface";
import { ApiFile } from "../types/appTypes";

export const convertToApiFile = (file: UploadFile) => {
  return {
    ...file.response?.data,
    response: file,
    uid: file.uid,
  } as ApiFile;
};
export const convertToUploadFileList = (fileList?: ApiFile[]) => {
  return Array.isArray(fileList) ? fileList.map((f) => convertToUploadFile(f)) : [];
};

export const convertToApiFileList = (fileList?: UploadFile[]) => {
  return Array.isArray(fileList)
    ? fileList.map((f) => {
      return { ...f.response };
    })
    : [];
};

export const convertToUploadFile = (file: ApiFile) => {
  return {
    uid: file.id,
    name: file.name,
    status: file.status,
    size: file.size,
    type: file.mimeType,
    url: file.url,
    response: file,
    thumbUrl: file.previewUrl,
  } as UploadFile;
};

export function getFileId(file: UploadFile) {
  if (isApiFile(file)) return file.id
  else if (file?.response?.id) return file.response.id;
  else return file.uid
}

export function isApiFile(file: UploadFile | ApiFile): file is ApiFile {
  return (file as ApiFile).id !== undefined;
}

export function isUploadFile(file: UploadFile | UploadFile): file is UploadFile {
  return (file as UploadFile).uid !== undefined;
}

export function isApiFiles(files: UploadFile[] | ApiFile[]): files is ApiFile[] {
  return files.length === 0 || (files[0] as ApiFile).id !== undefined;
}

export function isUploadFiles(files: UploadFile[] | ApiFile[]): files is UploadFile[] {
  return files.length === 0 || (files[0] as UploadFile).uid !== undefined;
}
