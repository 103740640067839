import React, { FC } from "react";
import { Form, Input } from "antd";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import NewPriceEquipments from "./newPriceEquipments";
import TotalNewPrice from "./totalNewPrice";
import TechnicalNewPrice from "./technicalNewPrice";

interface NewPriceFormItemsProps {
}

const NewPriceFormItems: FC<NewPriceFormItemsProps> = () => {
  return (
    <>
      <h4 className="mb-1">{_t("new_price")}</h4>
      <Form.Item name={["newPrice", "newPriceWithoutEquipments"]} label={_t("new_price", "without_equipments")}>
        <InputNum min={0} suffix="kr." />
      </Form.Item>
      <Form.Item name={["newPrice", "newPriceSource"]} label={`${_t("source")} - ${_t("note")}`}>
        <Input />
      </Form.Item>
      <TechnicalNewPrice />
      <NewPriceEquipments />
      <TotalNewPrice />
    </>
  );
};

export default NewPriceFormItems;
