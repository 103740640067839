import React, {FC, useState} from "react";
import {Button, Form, Input, message, Select, Space} from "antd";
import TextArea from "antd/lib/input/TextArea";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";

import {FullDocumentationType, TaxCase} from "../../../../types/taxTypes";
import {updateFullDocumentation} from "../../../../services/taxService";
import Details from "../../../../components/detailsList";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

const noteCodes = {
  "tradePrice": {
    "onlyEquivalentVehicles": "Kun tilsvarende køretøjer",
    "mixOfComparableAndEquivalentVehicles": "Blanding af sammenlignelige og tilsvarende køretøjer",
    "onlyComparableVehicles": "Kun sammenlignelige køretøjer"
  },
  "newPrice": {
    "estimaticEntireNewPrice": "Estimatic – hele nyprisen",
    "bilinfoEstimatic": "Bilinfo + Estimatic",
    "dat": "DAT",
    "bilinfoDat": "Bilinfo + DAT",
    "bilinfoEstimate": "Bilinfo + skøn",
    "estimate": "Skøn",
    "actualDat": "Faktisk + DAT",
    "recalculatedDat": "Genberegnet + DAT",
    "recalculatedEstimate": "Genberegnet + skøn",
    "recalculatedEstimatic": "Genberegnet + Estimatic",
    "estimaticOnlyBasePrice": "Estimatic – kun basispris",
    "estimaticDat": "Estimatic + DAT",
    "priceListEstimateNoRegistrationCard": "Prisliste + skøn (ingen vognkort)",
    "priceListEstimatic": "Prisliste + Estimatic",
    "priceListEntireNewPrice": "Prisliste – hele nyprisen",
    "sydfynsMc": "Sydfyns MC",
    "sydfynsMcDat": "Sydfyns MC + DAT"
  }
}

function getNoteCodeOption(
  base: keyof typeof noteCodes,
  key?: keyof typeof noteCodes[typeof base]
) {
  const items = Object.keys(noteCodes[base]).map(k => ({
    value: k,
    label: noteCodes[base][k as keyof typeof noteCodes[typeof base]]
  }));

  if (!key) {
    return items;
  }

  return items.find(x => x.value === key)?.label || null;
}

type FullDocData = Pick<FullDocumentationType, 'data'>;

interface EditFormProps {
  doc: FullDocumentationType | null,
  stopEditing: any,
  setTaxCase: (taxCase: TaxCase) => void,
  taxCase: TaxCase
}

const EditForm: FC<EditFormProps> = ({doc, stopEditing, setTaxCase, taxCase}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdate = async (values: FullDocData) => {
    try {
      setLoading(true);
      const {data} = await updateFullDocumentation(taxCase.id, values);
      setTaxCase(data);
      stopEditing();
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<FullDocData> initialValues={doc?.data ?? undefined} onFinish={handleUpdate}>
      <Form.Item name="governmentCaseNumber" label={_t('governmentCaseNumber')}>
        <Input disabled={loading}/>
      </Form.Item>

      <Form.Item name="newPriceNoteCode" label={_t('new_price', 'note')}>
        <Select
          disabled={loading}
          style={{width: 160}}
          options={getNoteCodeOption('newPrice') || []}
        />
      </Form.Item>

      <Form.Item name="tradePriceNoteCode" label={_t('trade_price', 'note')}>
        <Select
          disabled={loading}
          style={{width: 160}}
          options={getNoteCodeOption('tradePrice') || []}
        />
      </Form.Item>

      <Form.Item name="supplementaryDocumentationNote" label={_t('notes')}>
        <TextArea autoSize={{minRows: 2, maxRows: 3}} disabled={loading}/>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({submit}) => (
          <div className="text-right">
            <Space>
              <Button disabled={loading} onClick={stopEditing}>
                {_t("cancel")}
              </Button>
              <Button type="primary" loading={loading} onClick={submit} disabled={loading}>
                {_t("save")}
              </Button>
            </Space>
          </div>
        )}
      </Form.Item>
    </Form>
  )
}

interface InfoCardProps {
  doc: FullDocumentationType | null,
  startEditing: any
}

const InfoCard: FC<InfoCardProps> = ({doc, startEditing}) => {
  return <>
    <Details borderLess>
      <Details.Item label={_t('governmentCaseNumber')} value={doc?.data?.governmentCaseNumber || '-'}/>
      <Details.Item label={_t('new_price', 'note')} value={doc?.data?.newPriceNoteCode ? (
        getNoteCodeOption('newPrice', (doc.data.newPriceNoteCode as any))
      ) : '-'}/>
      <Details.Item label={_t('trade_price', 'note')} value={doc?.data?.tradePriceNoteCode ? (
        getNoteCodeOption('tradePrice', (doc.data.tradePriceNoteCode as any))
      ) : '-'}/>
      <Details.Item label={_t('notes')} value={doc?.data?.supplementaryDocumentationNote || "-"}/>
    </Details>

    <div className="text-right">
      <Button
        type="link"
        className="pl-0 pr-0 mt-1"
        onClick={startEditing}
      >
        {_t("edit")}
      </Button>
    </div>
  </>
}

const TaxFullDocumentationSupplementaryData: FC = () => {
  const {taxCase, setTaxCase} = TaxCaseStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const {fullDocumentation} = taxCase;
  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <>
      {isEditing ? (
        <EditForm doc={fullDocumentation} stopEditing={stopEditing} setTaxCase={setTaxCase} taxCase={taxCase}/>
      ) : (
        <InfoCard doc={fullDocumentation} startEditing={startEditing}/>
      )}
    </>
  );
};

export default TaxFullDocumentationSupplementaryData;
