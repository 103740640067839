import { Card, Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC, useState } from "react";
import { useHistory } from "react-router";
import Icon from "../../../assets/icons/icon";
import FancySubmitButton from "../../../components/fancySubmitButton";
import LoadingContainer from "../../../components/loadingContainer";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import PageBody from "../../../layout/pageBody";
import PageTitle from "../../../layout/pageTitle";
import { getPath } from "../../../routes/appRoutes";
import { createImportCase } from "../../../services/importService";
import { ScreenProps } from "../../../types/appTypes";
import { ImportCaseCreationType } from "../../../types/importTypes";
import consts from "../../../utilities/consts";
import { convertToApiFileList } from "../../../utilities/convertTypes";
import ImportNewCaseAdmin from "./importNewCaseAdmin";

const ImportNewCase: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("import.isAdmin");

  const [loading, setLoading] = useState<boolean>(false);

  const handleNewImportCase = async (values: ImportCaseCreationType) => {
    try {
      setLoading(true);
      const { data } = await createImportCase({
        ...values,
        files: convertToApiFileList(values.files || []),
      });
      data.id && history.replace(getPath("import", data.id));
    } catch(error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle fluid backBtn title={title} />
      <PageBody>
        <Form<ImportCaseCreationType> layout="vertical" requiredMark={false} onFinish={handleNewImportCase}>
          <Row align="top" gutter={24}>
            {isAdmin && (
              <Col {...consts.newCaseAdminColumnProps}>
                <ImportNewCaseAdmin loading={loading} />
              </Col>
            )}
            <Col {...consts.newCaseContentColumnProps(isAdmin)}>
              <Card bordered={false} loading={loading} title={_t("new_import_case_title")}>
                <LoadingContainer loading={loading}>
                  <Form.Item name={["vehicle", "link"]}>
                    <Input size="large" placeholder={_t("insert_link")} prefix={<Icon name="link-outline" />} />
                  </Form.Item>

                  <Form.Item name="note" label={_t("note")}>
                    <TextArea placeholder={_t("note_placeholder")} autoSize={{ minRows: 1, maxRows: 3 }} />
                  </Form.Item>
                  {!isAdmin && (
                    <div className="fancy-btn-wp text-center pt-1">
                      <FancySubmitButton loading={loading} />
                    </div>
                  )}
                </LoadingContainer>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default ImportNewCase;
