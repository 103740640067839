import { Button, Col, Dropdown, Menu, Popover, Row, Space, Spin } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import CardBox, { CardBoxProps } from "../../../components/cardBox";
import Details from "../../../components/detailsList";
import DividerLine from "../../../components/lineSplit";
import LogText from "../../../components/logText";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import _t from "../../../lang/translate";
import { openFileLink } from "../../../services/mediaService";
import { LeasingOfferType } from "../../../types/leasingTypes";
import format from "../../../utilities/formatNumbers";

interface LeasingOfferViewProps extends CardBoxProps {
  offer: LeasingOfferType | null;
  isActiveOffer?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onDelete: (Id: number) => void;
  onSend?: (Id: number) => void;
  onActivate?: (Id: number) => void;
}

const LeasingOfferView: FC<LeasingOfferViewProps> = ({
  offer,
  isActiveOffer,
  loading = false,
  onDelete,
  onSend,
  disabled,
  onActivate,
  ...rest
}) => {
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  if (!offer) {
    return (
      <CardBox {...rest}>
        <small>{_t("msg.no_active_offer_found")}</small>
      </CardBox>
    );
  }

  const handleOfferDownload = async () => {
    try {
      setLoadingDownload(true);
      await openFileLink(offer.media.url);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoadingDownload(false);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="delete"
        danger
        disabled={disabled}
        onClick={() => onDelete(offer.id)}
        icon={<Icon name="trash-bin-outline" size="small" />}
      >
        {_t("delete")}
      </Menu.Item>
    </Menu>
  );

  const moreContent = (
    <Details borderLess>
      <Details.Item label={_t("leasing_period")} value={offer.leasingPeriod} />
      <Details.Item label={_t("monthly_payment")} value={format.price(offer.monthlyPayment)} />
      <Details.Item label={_t("first_time_payment")} value={format.price(offer.payout)} />
      <Details.Item label={_t("restvalue")} value={format.price(offer.restValue)} />
      <Details.Item label={_t("note")} value={offer.note} />
    </Details>
  );

  const downloadIcon = <Icon size="large" name="download-outline" />;

  return (
    <Spin spinning={loading}>
      <CardBox {...rest}>
        <Row justify="space-between">
          <Col>
            <LogText logData={{ person: offer.person, date: offer.date }} />
          </Col>
          <Col>
            {!isActiveOffer && !disabled && (
              <Dropdown placement="bottomRight" overlay={menu}>
                <Button shape="circle" type="text" disabled={disabled}>
                  <Icon name="ellipsis-vertical-sharp" />
                </Button>
              </Dropdown>
            )}
          </Col>
        </Row>
        <DividerLine className="mt-05 mb-1" />
        {!isActiveOffer && moreContent}
        <Row justify="space-between">
          <Col>
            {isActiveOffer && (
              <Popover content={moreContent}>
                <Button type="link" className="pl-0 pr-0">
                  {_t("read", "more")}
                </Button>
              </Popover>
            )}
          </Col>
          <Col>
            <Space>
              <Button type="primary" ghost icon={downloadIcon} onClick={handleOfferDownload} loading={loadingDownload}>
                {_t("offer")}
              </Button>
              {isActiveOffer && onSend && (
                <Button type="primary" disabled={disabled} onClick={() => onSend(offer.id)}>
                  {_t("send", "offer")}
                </Button>
              )}
              {!isActiveOffer && onActivate && (
                <Button type="primary" disabled={disabled} onClick={() => onActivate(offer.id)}>
                  {_t("activate", "offer")}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </CardBox>
    </Spin>
  );
};

export default LeasingOfferView;
