import React, {FC} from "react";
import {Space} from "antd";
import DownloadBtn from "../downloadBtn";
import {
  downloadEntryCertificate,
  downloadPaymentAdvice,
  downloadPurchaseContract,
  downloadTakeHomeAgreement,
  updateEntryCertificateSigned,
  updatePaid,
  updatePurchaseContractSigned,
  updateTakeHomeAgreementSigned
} from "../../../../services/importService";
import _t from "../../../../lang/translate";
import StatusFlagToggle from "./statusFlagToggle";

const ImportBuyerRelation: FC = () => {
  return (
    <>
      <StatusFlagToggle
        flag={"purchaseContractSigned"}
        callable={updatePurchaseContractSigned}
        label={_t("purchase-contract-GR", "signed")}
      />

      <StatusFlagToggle
        flag={"entryCertificateSigned"}
        callable={updateEntryCertificateSigned}
        label={_t("entry-certificate-GR", "signed")}
      />

      <StatusFlagToggle
        flag={"takeHomeAgreementSigned"}
        callable={updateTakeHomeAgreementSigned}
        label={_t("take-home-agreement", "signed")}
      />

      <StatusFlagToggle
        flag={"paid"}
        callable={updatePaid}
        label={_t("payment", "received")}
      />
      <Space wrap>
        <DownloadBtn label={_t("purchase-contract-GR")} callable={downloadPurchaseContract}/>
        <DownloadBtn label={_t("entry-certificate-GR")} callable={downloadEntryCertificate}/>
        <DownloadBtn label={_t("take-home-agreement")} callable={downloadTakeHomeAgreement}/>
        <DownloadBtn label={_t("payment_advice")} callable={downloadPaymentAdvice}/>
      </Space>
    </>
  );
};

export default ImportBuyerRelation;
