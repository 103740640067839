import { PlayCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, message, Popconfirm, Popover, Result, Space, Tag, Tooltip, Typography } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import { renderErrorMessage } from "../components/messages/errorMessage";
import StatusCountDown from "../components/statusCountDown";
import StatusTag from "../components/statusTag";
import LimitedAccessMessageBox from "../components/subscription/limitedAccessMsgBox";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { deleteTaxCase, saveTaxCaseStatusToInProgress, taxCasesURL } from "../services/taxService";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { IdType, LocationStateType, ScreenProps } from "../types/appTypes";
import { TaxListItem } from "../types/taxTypes";
import format from "../utilities/formatNumbers";
import TaxCreateLink from "./forms/tax/admin/taxCreateLink";
import TableStatistics from "./tableStatistics";
import TaxTableFilter from "./tax/taxTableFilter";

const Tax: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const { hasPermission } = UserStore.useContainer();
  const [doRefund, setDoRefund] = useState<boolean>(false);
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<TaxListItem>>("", { data: [] }, true);
  const isAdmin = hasPermission("tax.isAdmin");
  const hasArchive = hasPermission("tax.index");

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 350), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = taxCasesURL(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, delayedSetUrl]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteTaxCase(id, doRefund);
      message.success(_t("deleted"));
    } catch (error) {
      renderErrorMessage(error, _t("msg.not_deleted"))
    }
  };

  const handleCaseStatus = async (e: any, id: IdType) => {
      e.preventDefault();

      try {
        const data = await saveTaxCaseStatusToInProgress(id);

        if (data) {
          const pathUrl = getPath("tax", id);

          history.push(pathUrl);

          return true;
        }
      } catch(error) {
        renderErrorMessage(error)

        return false;
      }
  }

  const columns: CustomColumnsType<TaxListItem> = [
    {
      title: _t("case", "number_short"),
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => (
        <>
          {record.isPriority && <Icon name="alert-outline" color="red" size="large" className="priority-icon-table" />}
          {hasPermission("screen.tax") ? (
            <Link to={getPath("tax", record.id)}>
              <strong>{text}</strong>
            </Link>
          ) : (
            text
          )}
          { isAdmin && !record.isInProgress && <Tooltip title={_t('changeToInProgress')}>
            <PlayCircleTwoTone
              style={{ cursor: 'pointer', fontSize: 20, marginLeft: 5 }}
              onClick={(e: any) => handleCaseStatus(e, record.id)}
            />
          </Tooltip>}
        </>
      ),
      fixed: "left",
    },
    {
      title: _t("date"),
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      width: 190,
      render: (date, record) => {
        const d = moment(date);
        const now = moment();
        const youngerThanDay = d.isValid() && now.diff(d, "days") < 1;
        if (isAdmin && youngerThanDay) {
          return <span title={format.dateAndTime(date)}>{moment(date).fromNow()}</span>
        } else {
          return (<>
            {record.recalculationRequestedAt &&
                <Popover placement="top" arrowPointAtCenter
                  content={now.diff(record.recalculationRequestedAt, "days") < 1
                    ? `Anmodet om genberegning for ${moment(record.recalculationRequestedAt).fromNow()}`
                    : `Anmodet om genberegning d. ${format.dateAndTime(record.recalculationRequestedAt)}`}
                >
                  <span className="p-01">
                    <Icon name="alert-outline" color="#0299fb" size="large"/>
                  </span>
                </Popover>
            }
            {format.dateAndTime(date)}
          </>)
        }
      },
    },
    {
      title: _t("dealer"),
      key: "dealerName",
      dataIndex: "dealerName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {isAdmin && record.dealerIsPriority && (
              <Icon name="star" size="small" color="lightblue" className="mr-05" />
            )}
            {isAdmin && record.isFirstCase && (
              <Tag color="processing" className="table-tag">{_t("new").toUpperCase()}</Tag>
            )}
            {`${text ? text + ", " : ""}${record.clientName}`}
          </>
        );
      },
    },
    /* {
      title: _t("created_by"),
      key: "clientName",
      dataIndex: "clientName",
      hidden: true,
      ellipsis: true,
    }, */
    {
      title: _t("vehicle"),
      key: "car",
      dataIndex: "car",
      ellipsis: true,
    },
    {
      title: _t("year"),
      key: "firstRegDate",
      dataIndex: "firstRegDate",
      width: 80,
      render: (text) => text && moment(text).year(),
      hidden: true,
    },
    {
      title: _t("vin"),
      key: "vin",
      dataIndex: "vin",
      width: 195,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 280,
      dataIndex: "status",
      render: (status, record) => (
        <Space>
          {isAdmin && status?.id === 7 && record.createdAt ? (
            <StatusCountDown status={status} size="small" startDate={record.createdAt} durationMinutes={record.waitTime} />
          ) : (
            <StatusTag status={status} size="small" />
          )}
          <Typography.Text type={record.paid ? "success" : "secondary"}>
            <Icon name="card-outline" size="medium" />
          </Typography.Text>
          <Typography.Text type={record.uploaded ? "success" : "secondary"}>
            <Icon name="documents-outline" size="medium" />
          </Typography.Text>
          {isAdmin && typeof record.importantNote === "string" && (
            <Popover placement="top" arrowPointAtCenter content={record.importantNote} overlayClassName="tax-table-popover-note">
              <Typography.Text type="warning">
                <Icon name="information-outline" size="large" />
              </Typography.Text>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: _t("mileage_unit"),
      key: "mileage",
      dataIndex: "mileage",
      hidden: true,
      sorter: true,
      //width: 80,
      render: format.milage,
    },
    {
      title: _t("engine_power_unit"),
      key: "horsePower",
      sorter: true,
      dataIndex: "horsePower",
      width: 80,
      hidden: true,
    },
    {
      title: _t("estimated_time"),
      key: "expectedUpdateTime",
      dataIndex: "expectedUpdateTime",
      render: (date) => format.date(date) + "-" + format.time(date),
      hidden: true,
    },
    {
      title: _t("invoice", "number_short"),
      key: "invoiceId",
      dataIndex: "invoiceId",
      sorter: true,
      hidden: true,
    },
    {
      title: _t("total_price"),
      key: "totalPrice",
      sorter: true,
      hidden: true,
      dataIndex: "totalPrice",
      render: (val) => format.price(val),
      align: "right",
      width: 140,
    },
    {
      title: _t("registration_fee_short"),
      key: "registrationFee",
      sorter: true,
      dataIndex: "registrationFee",
      render: (val) => format.price(val),
      align: "right",
      width: 140,
      fixed: "right",
    },
    {
      ...isAdmin && {
        title: _t("caseHandler"),
        key: "caseHandler",
        dataIndex: "caseHandler",
        ellipsis: true,
        render: (caseHandler) => caseHandler && caseHandler.fullName
      }
    },
    {
      title: _t("followup"),
      align: "center",
      key: "followup",
      sorter: true,
      dataIndex: "followup",
      width: 110,
      render: (text, record) => (
        <span>{isAdmin && record.followUp && <Icon name="arrow-up-outline" color="green" size="large" className="priority-icon-table" />}</span>
      ),
    },
    {
      title: "Forv. Indreg.",
      align: "left",
      key: "expectedRegistrationDate",
      sorter: true,
      dataIndex: "expectedRegistrationDate",
      width: 120,
      render: (text) => format.date(text),
    },
  ];

  const tableActions: RenderFunc<TaxListItem> = (_, { id }) => {
    if (!hasPermission("tax.delete")) return null;
    return (
      <Popconfirm
        placement="topLeft"
        onVisibleChange={() => setDoRefund(false)}
        onConfirm={() => handleDelete(id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>{id}</strong>

            <div>
              <Checkbox
                checked={doRefund}
                onChange={e => setDoRefund(e.target.checked)}
              >{_t('refund', 'credits')}</Checkbox>
            </div>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    );
  };

  const handleRefresh = () => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    query.set("refreshId", new Date().getSeconds().toString());
    const url = taxCasesURL(query.toString());
    setUrl(url);
  };

  return (
    <>
      <PageTitle
        className="fluid-page-title pb-05"
        title={title}
        extra={
          <>
            {hasPermission("tax.send_creation_link") && <TaxCreateLink />}
            <Button type="primary">
              <Link to={getPath("tax", "new")}>{_t("create_tax_case")}</Link>
            </Button>
          </>
        }
      >
        {isAdmin && <TableStatistics context="tax" />}
      </PageTitle>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <div className="position-relative">
          <DataTable<TaxListItem>
            onRefresh={handleRefresh}
            filter={<TaxTableFilter />}
            loading={isLoading}
            renderActions={tableActions}
            columnStorageKey="TAX"
            columns={columns}
            dataSource={data.data}
            meta={data.meta}
          />

          {!hasArchive && (
            <div className="blur-banner">
              <div className="position-relative" style={{ margin: "5vh auto", maxWidth: "950px" }}>
                <LimitedAccessMessageBox
                  customMsgProps={{
                    theme: "gray",
                    className: "p-2 pt-0"
                  }}
                  productType="tax.view_archive"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Tax;
