import { Button, message, Popconfirm, Popover, Result, Space, Typography } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { FC, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import { renderErrorMessage } from "../components/messages/errorMessage";
import StatusCountDown from "../components/statusCountDown";
import StatusTag from "../components/statusTag";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { deleteTaxDocCase, taxDocCasesURL } from "../services/taxDocService";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { IdType, LocationStateType, ScreenProps } from "../types/appTypes";
import { TaxDocListItem } from "../types/taxDocTypes";
import format from "../utilities/formatNumbers";
import TaxDocTableFilter from "./taxDoc/taxDocTableFilter";

const TaxDoc: FC<ScreenProps> = ({ title }) => {
  const location = useLocation<LocationStateType>();
  const { hasPermission } = UserStore.useContainer();
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<TaxDocListItem>>(
    "",
    { data: [] },
    true
  );

  const isAdmin = hasPermission("taxDoc.isAdmin");

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = taxDocCasesURL(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, delayedSetUrl]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteTaxDocCase(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      renderErrorMessage(ex, _t("msg.not_deleted"))
    }
  };

  const columns: CustomColumnsType<TaxDocListItem> = [
    {
      title: _t("case", "number_short"),
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => (
        <>
          {record.isPriority && <Icon name="alert-outline" color="red" size="large" className="priority-icon-table" />}
          {hasPermission("screen.taxDoc") ? (
            <Link to={getPath("taxDoc", record.id)}>
              <strong>{text}</strong>
            </Link>
          ) : (
            text
          )}
        </>
      ),
      fixed: "left",
    },
    {
      title: _t("date"),
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      width: 190,
      render: (date) => {
        const d = moment(date);
        const now = moment();
        const OlderThanDay = d.isValid() && d.diff(now, "days") > -1;
        if (isAdmin && OlderThanDay) {
          return <span title={format.dateAndTime(date)}>{moment(date).fromNow()}</span>;
        } else {
          return format.dateAndTime(date);
        }
      },
    },
    {
      title: _t("dealer"),
      key: "dealerName",
      dataIndex: "dealerName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {isAdmin && record.dealerIsPriority && (
              <Icon name="star" size="small" color="lightblue" className="mr-05" />
            )}
            {`${text ? text + ", " : ""}${record.clientName}`}
          </>
        );
      },
    },
    /* {
      title: _t("created_by"),
      key: "clientName",
      dataIndex: "clientName",
      hidden: true,
      ellipsis: true,
    }, */
    {
      title: _t("vehicle"),
      key: "car",
      dataIndex: "car",
      ellipsis: true,
    },
    {
      title: _t("year"),
      key: "firstRegDate",
      dataIndex: "firstRegDate",
      width: 80,
      render: (text) => text && moment(text).year(),
      hidden: true,
    },
    {
      title: _t("vin"),
      key: "vin",
      dataIndex: "vin",
      width: 195,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 280,
      dataIndex: "status",
      render: (status, record) => (
        <Space>
          {isAdmin && status?.id === 7 && record.createdAt ? (
            <StatusCountDown status={status} size="small" startDate={record.createdAt} />
          ) : (
            <StatusTag status={status} size="small" />
          )}
          {record.isQuickCalculation && (
            <Typography.Text type="secondary">
              <Icon name="stopwatch-outline" size="large" />
            </Typography.Text>
          )}
          {isAdmin && typeof record.importantNote === "string" && (
            <Popover placement="top" arrowPointAtCenter content={record.importantNote}>
              <Typography.Text type="warning">
                <Icon name="information-outline" size="large" />
              </Typography.Text>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: _t("mileage_unit"),
      key: "mileage",
      dataIndex: "mileage",
      hidden: true,
      sorter: true,
      //width: 80,
      render: format.milage,
    },
    {
      title: _t("engine_power_unit"),
      key: "horsePower",
      sorter: true,
      dataIndex: "horsePower",
      width: 80,
      hidden: true,
    },
    {
      title: _t("registration_fee_short"),
      key: "registrationFee",
      sorter: true,
      dataIndex: "registrationFee",
      render: (val) => format.price(val),
      align: "right",
      width: 140,
      fixed: "right",
    },
  ];

  const tableActions: RenderFunc<TaxDocListItem> = (_, { id }) => {
    if (!hasPermission("taxDoc.delete")) return null;
    return (
      <Popconfirm
        placement="topLeft"
        onConfirm={() => handleDelete(id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>{id}</strong>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    );
  };

  const handleRefresh = () => {
    const query = new URLSearchParams(location.search);
    query.set("refreshId", new Date().getSeconds().toString());
    const url = taxDocCasesURL(query.toString());
    setUrl(url);
  };

  return (
    <>
      <PageTitle
        fluid
        title={title}
        extra={
          <>
            <Button type="primary">
              <Link to={getPath("taxDoc", "new")}>{_t("create", "tax-doc-case")}</Link>
            </Button>
          </>
        }
      />
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<TaxDocListItem>
          onRefresh={handleRefresh}
          filter={<TaxDocTableFilter />}
          loading={isLoading}
          renderActions={tableActions}
          columnStorageKey="TAXDOC"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      )}
    </>
  );
};

export default TaxDoc;
