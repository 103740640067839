import { CustomColumnsType } from "./dataTableTypes";

type SavedColType = {
  key: string | number | undefined;
  hidden?: boolean;
};
type SavedColsType = { [key: string]: SavedColType };

export type ColumnStorageKeyType =
  | "TAX"
  | "TAXLEGAL"
  | "TAXDOC"
  | "RESELLERTAX"
  | "USER"
  | "IMPORT"
  | "LEASING"
  | "LEASING-FILTERED"
  | "MERCHANT"
  | "NEWS"
  | "CUSTOMER"
  | "BILLING_HISTORY"
  | "VEHICLE_CATALOG_VARIANT"
  | "VEHICLE_CATALOG_EQUIPMENT"
  | "VEHICLE_CATALOG_TYPE"
  | "VEHICLE_CATALOG_FUEL"
  | "VEHICLE_CATALOG_MODEL"
  | "VEHICLE_CATALOG_MAKE"
  | "DEALER"
  | "MONTHLY_SPECIFICATION";

export const saveColumns = <T extends object>(columns: CustomColumnsType<T>, type: ColumnStorageKeyType) => {
  let columnsToSave: SavedColType[] = [];
  for (let i = 0; i < columns.length; i++) {
    columnsToSave.push({
      key: columns[i].key,
      hidden: columns[i].hidden,
    });
  }
  localStorage.setItem(type, JSON.stringify(columnsToSave));
};

export const getColumns = <T extends object>(
  columns: CustomColumnsType<T>,
  type: ColumnStorageKeyType
): CustomColumnsType<T> => {
  const savedColumns = localStorage.getItem(type);
  const parsedColumns = savedColumns !== null ? (JSON.parse(savedColumns) as SavedColsType) : null;
  const parsedColumnsArray = parsedColumns !== null ? (Object.values(parsedColumns) as SavedColType[]) : null;
  const newColumns =
    parsedColumnsArray !== null && parsedColumnsArray.length === columns.length
      ? (parsedColumnsArray.map((col) => {
        let originalCol = columns.find((c) => c.key === col.key);
        return { ...originalCol, hidden: col.hidden };
      }) as CustomColumnsType<T>)
      : columns;
  return newColumns !== null ? newColumns : columns;
};
