import React, { FC, useState } from "react";
import { Button, Col, Form, message, Modal, Popover, Row, Select, Space } from "antd";
import Icon from "../../../../assets/icons/icon";
import SwitchInput from "../../../../components/form/switchInput";
import DividerLine from "../../../../components/lineSplit";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";
import {
  publishTaxOffer,
  sentTaxOffer,
  followUpOnCase,
} from "../../../../services/taxService";
import { isAxiosError } from "../../../../utilities/typeGuard";
import type { SendOfferFormType, TaxCase } from "../../../../types/taxTypes";
import TextArea from "antd/lib/input/TextArea";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import { getDeprecitation, getDeviation } from "../calc/metaCalculators";
import format from "../../../../utilities/formatNumbers";

interface TaxOfferActionsProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSend?: () => void;
}

const reservationOptions = [
  {
    label: "Ingen stelnummer på køretøjet",
    value: "Da køretøjet ikke er angivet med stelnummer, kan vi ikke fastsætte bilens ekstraudstyr. Derfor er både køretøjets nypris og handelspris med forbehold for ændringer ved endelig værdifastsættelse. Send gerne stelnummer for opdateret beregning."
  },
  {
    label: "Eventuelt fejlagtigt CO2-emission",
    value: "Vi tager forbehold for registreringsafgiftsberegningen, da vi er usikre på CO2-tallet som er oplyst i forhåndsvurderingen.",
  },
  {
    label: "Eventuelt fejlagtigt batterikapacitet",
    value: "Vi tager forbehold for registreringsafgiftsberegningen, da vi er usikre på batterikapaciteten som er oplyst i forhåndsvurderingen.",
  },
  {
    label: "Nye biler",
    value: "Vær opmærksom på at vi kun har haft indkøbspris til rådighed, og derfor kan registreringsafgiften ændre sig når vi får tilsendt slutseddel. Send gerne slutseddel eller forventet salgspris til os når det er muligt, så regner vi en præcis registreringsafgift til jer.",
  }
];


const TaxOfferActions: FC<TaxOfferActionsProps> = ({ loading, setLoading, onSend }) => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [visibleOfferModal, setVisibleOfferModal] = useState<boolean>(false);
  const taxableValueIsOK = validateTaxableValue(taxCase)
  
  const warnings = getCaseWarnings(taxCase);

  const offerTitle = taxCase.flags.sent ? _t("send_new", "preassessment") : _t("send", "preassessment");
  const offerButtonText = taxCase.flags.sent ? _t("send_new") : _t("send");

  const publishOffer = async () => {
    setLoading(true);
    try {
      const { data } = await publishTaxOffer(taxCase.id, { status: !taxCase.flags.published });
      setTaxCase(data);
      message.success(_t(data.published ? "offer_published" : "offer_unpublished"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const followUp = async () => {
    setLoading(true);
    try {
      const { data } = await followUpOnCase(taxCase.id, { status: !taxCase.flags.followUp });
      setTaxCase(data);
      message.success(_t("saved"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };
  
  const getAcceptableState = (values: SendOfferFormType) => {
    const taxableValueIsOK = validateTaxableValue(taxCase)
    return taxableValueIsOK && !values.message;
  }

  const sendOffer = async (values: SendOfferFormType) => {
    onSend && onSend();
    setLoading(true);
    try {
      values.isAcceptable = getAcceptableState(values)
      const { data } = await sentTaxOffer(taxCase.id, values);
      setTaxCase(data);
      message.success(_t("offer", "sent"));
      setVisibleOfferModal(false);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DividerLine weight={1.5} fluid />
      <Row align="middle" gutter={[8,16]} justify="space-between">
        <Col>
          <SwitchInput
            size="small"
            checked={taxCase.flags.published}
            onChange={publishOffer}
            label={_t("publish")}
            id="publish-switch"
          />
        </Col>
        <Col>
          <SwitchInput
            size="small"
            checked={taxCase.flags.followUp}
            onChange={followUp}
            label={_t("followup")}
            id="followup-switch"
          />
        </Col>
        <Col flex={1}>
          <Space style={{justifyContent:"flex-end", width: '100%'}}>
            <Button
              disabled={!taxCase.links?.offerPdfLink}
              type="primary"
              ghost
              onClick={() => openFileLink(taxCase.links?.offerPdfLink)}
            >
              <Icon name="download-outline" size="large" />
              {_t("download")}
            </Button>
            <Button type="primary" onClick={() => setVisibleOfferModal(true)}>
              {offerButtonText}
            </Button>
          </Space>
        </Col>
      </Row>

      <Modal
        title={null}
        visible={visibleOfferModal}
        destroyOnClose={true}
        footer={null}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => setVisibleOfferModal(false)}
      >
        <Form<SendOfferFormType>
          onFinish={sendOffer}
          initialValues={{ message: taxableValueIsOK ? null : reservationOptions[3].value, reservation: null }}
          layout="vertical"
        >

          <h2 className="modal-title">{offerTitle}</h2>
          <Form.Item
            name="message" label={_t("send_tax_offer_message_label")} rules={[{ required: false, whitespace: true }]}
          >
            <TextArea
              disabled={loading}
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder={_t('send_tax_offer_placeholder')}
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item name="reservation" label={_t('send_tax_offer_extra_details_label')}>
                <Select
                  showSearch
                  placeholder={_t('choose')}
                  options={reservationOptions}
                  onChange={(val) => {
                    setFieldsValue({
                      message: val,
                      reservation: null,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Row justify="space-between" align="bottom">
            <Col>
              {warnings.map((item, i) => <Warning {...item} key={i} />)}
            </Col>
            <Col >
              <Form.Item shouldUpdate noStyle>
                {({ submit }) => (
                  <div className="text-right">
                    <Space>
                      <Button type="ghost" onClick={() => setVisibleOfferModal(false)} disabled={loading}>
                        {_t("cancel")}
                      </Button>
                      <Button onClick={submit} loading={loading} type="primary">
                        {offerButtonText}
                      </Button>
                    </Space>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TaxOfferActions;

const Warning = ({ message, remark }: CaseWarning) => {
  return (
    <WithPopover remark={remark}>
      <div className="d-flex align-items-center text-smallest mb-03">
        <Icon size="medium" className="mr-03 text-warning" name="alert" />
        {message}
      </div>
    </WithPopover>
  )
}

const WithPopover: FC<Pick<CaseWarning, "remark">> = ({ remark, children }) => {
  return remark
    ? (
      <Popover title={remark?.title} content={remark?.content}>
        {children}
      </Popover>
    ) : (
      <>{children}</>
    )
}

type CaseWarning = {
  message: string,
  remark?: {
    title: string | null,
    content: string | null
  }
}

function getCaseWarnings(taxCase: TaxCase): CaseWarning[] {
  const warnings: CaseWarning[] = [];
  const tradePrice = taxCase.calculation?.tradePrice?.tradePrice || 0;
  const newPriceWithoutEquipments = taxCase.calculation?.newPrice?.newPriceWithoutEquipments || 0;
  const robotTradePrice = taxCase.latestTaxRobotResult?.output?.tradePrice.result?.tradePrice || 0;
  const robotNewPriceWithoutEquipment = taxCase.latestTaxRobotResult?.output?.newPrice.newPriceWithoutEquipment || 0;

  const depreciation = getDeprecitation({
    firstRegDate: taxCase.vehicle?.firstRegDate || new Date().toISOString(),
    newPrice: taxCase.calculation?.newPrice?.newPrice || 0,
    tradePrice: tradePrice,
    mileage: taxCase.vehicle?.mileage || 0,
  })

  const newPriceDeviation = getDeviation(newPriceWithoutEquipments, robotNewPriceWithoutEquipment);
  const tradePriceDeviation = getDeviation(tradePrice, robotTradePrice);

  if (newPriceDeviation.isLargeDeviation) {
    warnings.push({
      message: _t("new_price", "without_equipments") + " " + _t("deviates").toLowerCase() + " " + _t("from", "the_robot"),
      remark: {
        title: _t("deviation_percentage") + ": " + format.percent(newPriceDeviation.deviation),
        content: `${_t("new_price", "without_equipments")} (${format.price(newPriceWithoutEquipments)})
          ${_t("deviates").toLowerCase()} ${_t("from", "the_robot")} (${format.price(robotNewPriceWithoutEquipment)})`
      }
    })
  }
  if (tradePriceDeviation.isLargeDeviation) {
    warnings.push({
      message: _t("trade_price") + " " + _t("deviates").toLowerCase() + " " + _t("from", "the_robot"),
      remark: {
        title: _t("deviation_percentage") + ": " + format.percent(tradePriceDeviation.deviation),
        content: `${_t("trade_price")} (${format.price(tradePrice)})
          ${_t("deviates").toLowerCase()} ${_t("from", "the_robot")} (${format.price(robotTradePrice)})`
      }
    })
  }
  if (depreciation.deviation.isLargeDeviation) {
    const depreciatedNewPrice = depreciation.deprecitationWithMileageRegulation;
    const standardFactor = depreciation.standard.factor;

    warnings.push({
      message: _t("standard_depreciation") + " " + _t("deviates").toLowerCase() + " " + _t("from", "the_vehicle"),
      remark: {
        title: `${_t("standard_depreciation")} (${format.percent(standardFactor)}) vs.
          ${_t("case_depreciation")} (${format.percent(depreciation.caseDeprecitationFactor)})`,
        content: `${format.price(depreciatedNewPrice)} (${_t("standard_depreciation")})
          ${_t("is_not", "comparable")} ${_t("with").toLowerCase()}
          ${format.price(tradePrice)} (${_t("trade_price")})`
      }
    })
  }
  return warnings;
}

function validateTaxableValue(taxCase: TaxCase) {
  const newPriceBreakdown = taxCase.calculation?.newPrice?.newPriceBreakdown;
  let taxableValueIsOK = true;
  
  if (taxCase.vehicle?.isNew && !(newPriceBreakdown?.purchasePrice?.taxableValue && newPriceBreakdown?.salesPrice?.taxableValue)) {
    taxableValueIsOK = false;
  }
  return taxableValueIsOK
}