import { Button, Col, Row } from "antd";
import { FC } from "react";
import Details from "../../../components/detailsList";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import DealerAdminStatistics from "./dealerAdminStatistics";

interface DealerSettingsInfoProps {
  startEditing: () => void;
}

const DealerSettingsInfo: FC<DealerSettingsInfoProps> = ({ startEditing }) => {
  const { admin } = DealerAdminStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const { inactive, suspended, accountingItemsEnabled, leasingEnabled, categories, taxLegalEnabled } =
    admin.settings || {};
  return (
    <>
      <Details borderLess className="m-0">
        <Details.Item label={_t("inactive")} value={format.yesOrNo(inactive)} />
        <Details.Item label={_t("suspended")} value={format.yesOrNo(suspended)} />
        <Details.Item label={_t("accounting_items_enabled")} value={format.yesOrNo(accountingItemsEnabled)} />
        <Details.Item label={_t("leasing_enabled")} value={format.yesOrNo(leasingEnabled)} />
        <Details.Item label={_t("taxLegalEnabled")} value={format.yesOrNo(taxLegalEnabled)} />
        <Details.Item label={_t("categories")} value={categories ? categories.join(", ") : "-"} />
      </Details>

      <Row justify="space-between">
        <Col>{hasPermission("dealer.statistics") && <DealerAdminStatistics />}</Col>
        <Col>
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DealerSettingsInfo;
