import { Result, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import _t from "../../lang/translate";
import { getNotification } from "../../services/notificationService";
import { pageKey, pageSizeKey, typeKey } from "../../services/urlQueryService";
import { NotificationType, notifiTabKeys, NotifiTabKeys, } from "../../types/notificationsType";
import LoadMore from "../loadMore";
import NotificationItem from "./notificationItem";

interface NotificationListProps {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  tapName: NotifiTabKeys;
}

const NotificationList: FC<NotificationListProps> = ({
  tapName,
  setVisibility,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<NotificationType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams();
        query.set(pageKey, `${currentPage}`);
        query.set(pageSizeKey, `${10}`);
        //Add filters for active tap:
        if (tapName !== notifiTabKeys.all) {
          query.set(typeKey, tapName);
        }
        const { data: newData } = await getNotification(query.toString());
        if (!didCancel) {
          setData([...data, ...newData.data]);
          setTotalPages(newData.meta?.last_page || 1);
        }
      } catch (error) {
        if (!didCancel) {
          setError(true);
        }
      } finally {
        if (!didCancel) setLoading(false);
      }
    };
    if (tapName && currentPage) fetchData();
    return () => {
      didCancel = true;
    };
  }, [
    tapName,
    currentPage,
    setData,
    setLoading,
    setError,
    setCurrentPage,
    setTotalPages,
  ]);

  return (
    <>
      {data.map((item) => (
        <NotificationItem
          key={item.id}
          data={item}
          setVisibility={setVisibility}
        />
      ))}
      {error && <Result status="error" title={_t("msg.unknown_error")} />}
      {loading ? (
        <div className="text-center p-2">
          <Spin spinning />
        </div>
      ) : (
        !loading && (
          <LoadMore
            canLoadMore={totalPages > currentPage}
            onClick={() => setCurrentPage((s) => s + 1)}
          />
        )
      )}
    </>
  );
};

export default NotificationList;
