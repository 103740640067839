import { ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import { initTaxLegalCase, TaxLegalCase } from '../types/taxLegalTypes';
import { createContainer } from "unstated-next";
import { EnumItemType, IdType, Vehicle } from '../types/appTypes';

type TaxCaseStoreProps = {
  taxLegalCase: TaxLegalCase;
  lastSaved: null | string;
  actions: {
    resetState: () => void;
    setTaxLegalCase: (newTaxLegalCase: TaxLegalCase) => void;
    setVehicle: (vehicle: TaxLegalCase['vehicle']) => void;
    setGovernmentCaseNumber: (governmentCaseNumber: TaxLegalCase['governmentCaseNumber']) => void;
    setEvaluation: (evaluation: TaxLegalCase['originalEvaluation']) => void;
    setFiles: (files: TaxLegalCase['files']) => void;
    setLastSaved: React.Dispatch<React.SetStateAction<string | null>>;
  }
}

const useTaxLegalCaseStore = (initialState?: TaxLegalCase): TaxCaseStoreProps => {
  const [taxLegalCase, dispatch] = useReducer(taxLegalCaseReducer, initialState || initTaxLegalCase);
  const [lastSaved, setLastSaved] = useState<string|null>(null)
  useEffect(() => {
    if (initialState) {
      dispatch({ type: "SET_TAX_LEGAL_CASE", payload: initialState })
    }
  }, [initialState])

  return {
    taxLegalCase,
    lastSaved,
    actions: {
      resetState:() => dispatch({ type: "RESET", payload: initialState || initTaxLegalCase }),
      setTaxLegalCase:(newTaxLegalCase) => dispatch({ type: "SET_TAX_LEGAL_CASE", payload: newTaxLegalCase }),
      setGovernmentCaseNumber: (governmentCaseNumber) => dispatch({ type: "SET_JOURNAL_CODE", payload: governmentCaseNumber }),
      setVehicle: (vehicle) => dispatch({ type: "SET_VEHICLE", payload: vehicle }),
      setEvaluation: (evaluation) => dispatch({ type: "SET_EVALUATION", payload: evaluation }),
      setFiles: (files) => dispatch({ type: "SET_FILES", payload: files }),
      setLastSaved
    }
  };
};

const TaxLegalCaseStore = createContainer(useTaxLegalCaseStore);


type TaxLegalCaseStoreProviderProps = {
  children: ReactNode;
  initialState?: TaxLegalCase;
}
export function TaxLegalCaseStoreProvider({ children, initialState }: TaxLegalCaseStoreProviderProps) {
  return (
    <TaxLegalCaseStore.Provider initialState={initialState}>
      {children}
    </TaxLegalCaseStore.Provider>
  )
}

function useTaxLegalCaseSelector(): TaxLegalCase
function useTaxLegalCaseSelector<T>(selector: (state: TaxLegalCase) => T): T
function useTaxLegalCaseSelector<T>(selector?: (state: TaxLegalCase) => T) {
  const { taxLegalCase } = TaxLegalCaseStore.useContainer();
  // useMemo will come in hand if more states are introduced to useTaxLegalCaseStore
  return useMemo(() => {
    return selector ? selector(taxLegalCase) : taxLegalCase
  }, [taxLegalCase]);
};

export const useTaxLegalActions = () => TaxLegalCaseStore.useContainer().actions
export const useTaxLegalCase = () => useTaxLegalCaseSelector((state) => state);
export const useTaxLegalLastSaved = () => TaxLegalCaseStore.useContainer().lastSaved
export const useTaxLegalId = () => useTaxLegalCaseSelector((state) => state.id);
export const useTaxLegalStatus = () => useTaxLegalCaseSelector((state) => state.status);
export const useTaxLegalVehicle = () => useTaxLegalCaseSelector((state) => state.vehicle);
export const useTaxLegalEvaluation = () => useTaxLegalCaseSelector((state) => state.originalEvaluation);
export const useTaxLegalFiles = () => useTaxLegalCaseSelector((state) => state.files);

const taxLegalCaseReducer = (
  state: TaxLegalCase,
  action: TaxLegalCaseAction
): TaxLegalCase => {
  switch (action.type) {
    case "SET_ID":
      return { ...state, id: action.payload };
    case "SET_CREATED_AT":
      return { ...state, createdAt: action.payload };
    case "SET_JOURNAL_CODE":
      return { ...state, governmentCaseNumber: action.payload };
    case "SET_REFERENCE_CODE":
      return { ...state, referenceCode: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_NEXT_DEADLINE":
      return { ...state, nextDeadline: action.payload };
    case "SET_VEHICLE":
      return { ...state, vehicle: action.payload };
    case "SET_EVALUATION":
      return { ...state, originalEvaluation: action.payload };
    case "SET_FILES":
      return { ...state, files: action.payload };
    case "SET_TAX_LEGAL_CASE":
      return action.payload;
    case "RESET":
      return action.payload; // Reset to a new state
    default:
      return state;
  }
};


// TODO: Take stock of all the items later, when the data shape is clearly defined
type TaxLegalCaseAction =
  | { type: "SET_ID"; payload: IdType }
  | { type: "SET_CREATED_AT"; payload: string }
  | { type: "SET_JOURNAL_CODE"; payload: TaxLegalCase['governmentCaseNumber'] }
  | { type: "SET_REFERENCE_CODE"; payload: TaxLegalCase['referenceCode'] }
  | { type: "SET_STATUS"; payload: EnumItemType | null }
  | { type: "SET_NEXT_DEADLINE"; payload: string | null }
  | { type: "SET_VEHICLE"; payload: TaxLegalCase['vehicle'] | null }
  | { type: "SET_EVALUATION"; payload: TaxLegalCase['originalEvaluation'] | null }
  | { type: "SET_FILES"; payload: TaxLegalCase['files'] }

  | { type: "SET_TAX_LEGAL_CASE"; payload: TaxLegalCase }
  | { type: "RESET"; payload: TaxLegalCase }; // For resetting the state
