import React, { FC, useState } from "react";
import Icon from "../assets/icons/icon";
import { Button, Divider, Form, message, Popover, Select, Space, Typography } from "antd";
import _t from "../lang/translate";
import { contactSupport } from "../services/miscService";
import TextArea from "antd/lib/input/TextArea";
import { ContactFormType } from "../types/appTypes";
import { renderErrorMessage } from "./messages/errorMessage";

const { Text } = Typography;

const ContactForm: FC = () => {
  const [form] = Form.useForm<ContactFormType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { data } = await contactSupport({ message: values.note, type: values.type });
      setLoading(false);
      setSubmitted(true);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return submitted ? (
    <Text type="success">
      {_t('msg.thanks_for_request_support')}
    </Text>
  ) : (
    <Form form={form} requiredMark={false} layout="vertical" onFinish={handleSubmit} initialValues={{ message: "", type: "tax" }}>
      <Form.Item name="type" label={_t('type')} rules={[{ required: true }]}>
        <Select
          placeholder={_t('choose')}
        >
          <Select.Option value="general">{_t('general')}</Select.Option>
          <Select.Option value="tax">{_t('tax')}</Select.Option>
          <Select.Option value="import">{_t('import')}</Select.Option>
          <Select.Option value="leasing">{_t('leasing')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item rules={[{ required: true, message: "Dette felt er påkrævet!" }]} name="note">
        <TextArea placeholder={_t("message")} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("send")}
          </Button>
        </Space>
      </div>
    </Form>
  )
}

const ContactButton: FC = () => {
  return (
    <Popover
      overlayInnerStyle={{ maxWidth: "300px" }}
      arrowPointAtCenter={true}
      placement="topRight"
      title={_t('message_support')}
      content={(<>
        {_t('message_support_text')}
        <Divider />
        <ContactForm />
      </>
      )}
      trigger="click"
    >
      <Button type="primary" shape="round" icon={<Icon name="mail-open-outline" size="large" />} size={"large"}>
        {_t('send', 'message')}
      </Button>
    </Popover>
  )
};

export default ContactButton;
