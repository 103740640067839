import { Button, Col, Divider, Popconfirm, Row } from "antd";
import React, { FC } from "react";
import appConfig from "../../../../appConfig";
import Details from "../../../../components/detailsList";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { unAcceptImportOffer } from "../../../../services/importService";
import { ImportCaseType } from "../../../../types/importTypes";
import format from "../../../../utilities/formatNumbers";

const ImportOrderInfoEditable: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { expressDelivery } = importCase.vehicleOffer?.offer?.prices || {};
  const { invoiceAddress, deliveryAddress, isCarStatusReport, isExpressDelivery, valutaId } = importCase.order || {};
  const [loading, setLoading] = React.useState<boolean>(false);
  const canUnAccept = !importCase.flags.completed;

  const unAcceptOffer = async () => {
    try {
      setLoading(true);
      const { data } = await unAcceptImportOffer(importCase.id);
      setImportCase(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };
  const handleDeliveryAddressEdit = async () => {
    try {
      const data = await openModal<ImportCaseType>("updateDeliveryAddress", {
        id: importCase.id,
        initialValues: deliveryAddress,
      });
      setImportCase(data);
    } catch (error) {
    }
  };

  const handleInvoiceAddressEdit = async () => {
    try {
      const data = await openModal<ImportCaseType>("updateInvoiceAddress", {
        id: importCase.id,
        initialValues: invoiceAddress,
      });
      setImportCase(data);
    } catch (error) {
    }
  };
  const dekra = isCarStatusReport ? appConfig("dekraPrice") : 0;
  const express = isExpressDelivery ? expressDelivery : 0;
  const price = importCase.vehicleOffer?.offer?.totalPrice + dekra + express;
  const valutaText =
    valutaId === 1 ? (
      <>
        DKK <small>({format.price(price * appConfig("conversionRate"))})</small>
      </>
    ) : (
      <>
        EUR <small>({format.number(price)})</small>
      </>
    );

  return (
    <>
      <Details borderLess>
        <Details.Title
          className="m-0"
          extra={
            <Button type="link" className="pl-0 pr-0" onClick={handleDeliveryAddressEdit}>
              {_t("edit")}
            </Button>
          }
        >
          <h3>{_t("delivery_address")}</h3>
        </Details.Title>
        <Details.Item label={_t("name")} value={deliveryAddress?.name || "-"} />
        <Details.Item label={_t("address")} value={deliveryAddress?.address || "-"} />
        <Details.Item
          label={_t("zip", "city", " & ")}
          value={`${deliveryAddress?.zip || "-"} ${deliveryAddress?.city || "-"}`}
        />
        <Details.Item label={_t("phone")} value={deliveryAddress?.phone || "-"} />
        <Details.Item label={_t("contact_person")} value={deliveryAddress?.contact || "-"} />
      </Details>
      <Divider />
      <Details borderLess>
        <Details.Title className="m-0">
          <h3>{_t("addons")}</h3>
        </Details.Title>
        <Details.Item label={_t("express_delivery")} value={format.yesOrNo(isExpressDelivery)} hidden={true} />
        <Details.Item label={_t("dekra")} value={format.yesOrNo(isCarStatusReport)} />
      </Details>
      <Divider />
      <Details borderLess>
        <Details.Title className="m-0">
          <h3>{_t("payment")}</h3>
        </Details.Title>
        <Details.Item label={_t("valuta")} value={valutaText} />
      </Details>
      <Divider />
      <Details borderLess>
        <Details.Title
          className="m-0"
          extra={
            <Button type="link" className="pl-0 pr-0" onClick={handleInvoiceAddressEdit}>
              {_t("edit")}
            </Button>
          }
        >
          <h3>{_t("billing_destination")}</h3>
        </Details.Title>
        <Details.Item label={_t("name")} value={invoiceAddress?.name || "-"} />
        <Details.Item label={_t("address")} value={invoiceAddress?.address || "-"} />
        <Details.Item
          label={_t("zip", "city", " & ")}
          value={`${invoiceAddress?.zip || "-"} ${invoiceAddress?.city || "-"}`}
        />
        <Details.Item label={_t("phone")} value={invoiceAddress?.phone || "-"} />
        <Details.Item label={_t("contact_person")} value={invoiceAddress?.contact || "-"} />
      </Details>

      <Row justify="end" gutter={[16, 16]} align="middle" className="mt-2">
        <Col>
          <Popconfirm title={_t('confirm_action')} onConfirm={unAcceptOffer}>
            <Button type="ghost" disabled={!canUnAccept} loading={loading}>
              {_t("cancel_request")}
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </>
  );
};

export default ImportOrderInfoEditable;
