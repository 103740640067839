import { Button } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { downloadDocumentsUrl } from "../../../services/leasingService";
import { openFileLink } from "../../../services/mediaService";

const LeasingDownloadContractsButton: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDocumentsDownload = async () => {
    try {
      setLoading(true);
      await openFileLink(downloadDocumentsUrl(leasingCase.id));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const disabled = !leasingCase.flags.contractGenerated;

  return (
    <Button loading={loading} type="primary" disabled={disabled} ghost onClick={handleDocumentsDownload}>
      <Icon name="download-outline" size="large" /> {_t("contracts")}
    </Button>
  );
};

export default LeasingDownloadContractsButton;
