import { Button, message, Result } from "antd";
import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { vehicleReportDownload, vehicleReportsUrl } from "../services/vehicleReportService";
import { PaginationedData } from "../types/apiTypes";
import { LocationStateType, ScreenProps } from "../types/appTypes";
import { VehicleReportType } from "../types/vehicleReportTypes";
import format from "../utilities/formatNumbers";
import { isAxiosError } from "../utilities/typeGuard";

const VehicleReports: FC<ScreenProps> = ({ title }) => {
  const location = useLocation<LocationStateType>();
  const [loadingId, setLoadingId] = useState<number | null>();
  const { hasPermission } = UserStore.useContainer();
  const canCreate = hasPermission("vehicleReport.create");
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<VehicleReportType>>("", { data: [] });

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = vehicleReportsUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, delayedSetUrl]);

  const columns: CustomColumnsType<VehicleReportType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      width: 110,
      sorter: true,
      dataIndex: "id",
    },
    {
      title: _t("date"),
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      width: 200,
      render: (date) => format.dateAndTime(date),
    },
    {
      title: _t("vin"),
      key: "vin",
      dataIndex: "vin",
      width: 200,
    },
    {
      title: _t("dealer"),
      key: "dealerName",
      dataIndex: "dealerName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {`${text ? text + ", " : ""}${record.clientName}`}
          </>
        );
      },
    },
  ];

  const handleDownload = async (id: number) => {
    try {
      setLoadingId(id);
      const { data } = await vehicleReportDownload(id);
      if (data.url) window.open(data.url, "_blank");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoadingId(null);
    }
  };

  const tableActions: RenderFunc<VehicleReportType> = (_, { id }) => {
    return (
      <Button
        className="muted"
        type="text"
        disabled={!!loadingId && loadingId !== id}
        loading={loadingId === id}
        shape="circle"
        onClick={() => handleDownload(id)}
        icon={<Icon name="download-outline" />}
      />
    );
  };

  const handleRefresh = () => {
    const query = new URLSearchParams(location.search);
    query.set("refreshId", new Date().getSeconds().toString());
    const url = vehicleReportsUrl(query.toString());
    setUrl(url);
  };

  return (
    <>
      <PageTitle
        fluid
        title={title}
        extra={
          canCreate && (
            <Button type="primary">
              <Link to={getPath("vehicleReport", "new")}>{_t("create", "vehicle_report")}</Link>
            </Button>
          )
        }
      />
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<VehicleReportType>
          onRefresh={handleRefresh}
          loading={isLoading}
          renderActions={tableActions}
          columnStorageKey="TAX"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      )}
    </>
  );
};

export default VehicleReports;
