import http from "./httpService";
import { ChangeClientType, IdType } from "../types/appTypes";
import getApiEndpoint from "./apiEndpoint";
import { mediaApiEndpoint } from "./mediaService";
import { ActivityEntryType } from "../types/activityTypes";
import { GovernmentCaseHandler, StoreTaxLegalCase, TagStoreQuick, TaxLegalCase, TaxLegalCategoryId, UpdateTaxLegalCase } from "../types/taxLegalTypes";
import { getApiQuery } from "./urlQueryService";

/* PERMISSIONS FOR TAX LEGAL: 'screen.tax_legal','screen.tax_legal_index','tax_legal.create','tax_legal.update' */


const apiEndpoint = `${getApiEndpoint()}/taxLegalCase`;

export function taxLegalCaseUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function taxLegalCasesURL(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function getTaxLegalCase(id:IdType) {
  return http.get(taxLegalCaseUrl(id));
}
export function createTaxLegalCase(data: StoreTaxLegalCase) {
  return http.post<TaxLegalCase>(apiEndpoint, data);
}
export function updateTaxLegalCase(id: string | number, data: UpdateTaxLegalCase) {
  return http.put<TaxLegalCase>(taxLegalCaseUrl(id), data);
}
export function deleteTaxLegalCase(id: IdType, doRefund?: boolean) {
  // TODO : Refund?
  const refundReason = doRefund ? 5 : -1;
  const url = `${taxLegalCaseUrl(id)}?refundReason=${refundReason}`;
  return http.delete(url);
}


export function taxLegalFileUrl(id?: IdType) {
  return id ? `${taxLegalCaseUrl(id)}/file` : mediaApiEndpoint;
}



/**
 * GET http://localhost:8007/api/v1/taxLegalCase/{taxLegalCase}/downloadAllFiles \
 * Download all files attached the tax legal case.
 */
export function downloadAllFiles(id: IdType) {
  return http.get(`${taxLegalCaseUrl(id)}/downloadAllFiles`);
}

export function updateTaxLegalCaseClient(id: IdType, data: ChangeClientType) {
  return http.put(`${taxLegalCaseUrl(id)}/client`, data);
}



/**
 * PUT taxLegalCase/{taxLegalCase}/restore
 */
export function restoreTaxLegalCase(id: IdType) {
  return http.put(`${taxLegalCaseUrl(id)}/restore`);
}


/**
 * URL taxLegalCase/{taxLegalCase}/activities \
 * URL for CRUD operations on the taxLegalCase acitivities
 */
export function taxLegalActivitiesUrl(id: IdType) {
  return `${taxLegalCaseUrl(id)}/activities`;
}
/**
 * PUT taxLegalCase/{taxLegalCase}/activities/{logEntry} \
 * Update an activity entry on the case
 */
export function updateTaxLegalActivity(id: IdType, data: ActivityEntryType) {
  return http.put(`${taxLegalCaseUrl(id)}/activities/${data.id}`, data);
}

/**
 * DELETE taxLegalCase/{taxLegalCase}/activities/{logEntry} \
 * Delete an activity entry from the case
 */
export function deleteTaxLegalActivity(id: IdType, deleteId: string) {
  return http.delete(`${taxLegalCaseUrl(id)}/activities/${deleteId}`);
}

/**
 * POST taxLegalCase/{taxLegalCase}/activities \
 * Create a new activity entry on the case
 */
export function createTaxLegalActivity(id: IdType, data: ActivityEntryType) {
  return http.post(`${taxLegalCaseUrl(id)}/activities`, data);
}


/**
 * GET /governmentCaseHandler/{governmentCaseHandler} \
 * Get government case handler of ID
 */
export function getGovernmentCaseHandler (id: IdType) {
  return http.get<GovernmentCaseHandler>(`${getApiEndpoint()}/governmentCaseHandler/${id}`);
}
/**
 * POST /governmentCaseHandler/storeQuick \
 * Create a new DataBase entry of government caseHandler in the system
 */
export function addGovernmentCaseHandler (data: GovernmentCaseHandler) {
  return http.post<GovernmentCaseHandler>(`${getApiEndpoint()}/governmentCaseHandler/storeQuick`, data);
}

/**
 * POST /tag/storeQuick \
 * Create a new DataBase entry of government caseHandler in the system
 */
export function addTaxLegalTag (data: TagStoreQuick) {
  return http.post<GovernmentCaseHandler>(`${getApiEndpoint()}/tag/storeQuick`, data);
}


/**
 * GET /tag/{tag} \
 * Get tag of id
 */
export function getTaxLegalTag (id: IdType) {
  return http.get(`${getApiEndpoint()}/tag/${id}`);
}




/**
 * GET taxLegalCase/{taxLegalCase}/downloadEquipmentReport \
 * Download a file of equipment list attached to the case.
 */
export function getEquipmentList(id: IdType) {
  return http.get<{url:string}>(`${taxLegalCaseUrl(id)}/downloadEquipmentReport`);
}



/**
 * GET taxLegalCase/initializeExport \
 * It just takes an input  like this: {"to": "asd@asd.asd"} and returns {"data": true} and starts a background job that handles the export and emailing
 */
export function initializeExport(data: {to: string}) {
  return http.post<{data:boolean}>(`${apiEndpoint}/initializeExport`, data);
}


