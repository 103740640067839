import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import debounce from "lodash/debounce";
import React, { FC, useCallback, useState } from "react";
import SavedStatus from "../../../../components/savedStatus";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { initMerchant, saveMerchant } from "../../../../services/merchantService";
import { MerchantType } from "../../../../types/merchantTypes";
import consts from "../../../../utilities/consts";
import { compare } from "../../../../utilities/deepCompare";
import MerchantFormFields from "../../merchant/merchantFormFields";
import { Vehicle } from "../../../../types/appTypes";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

interface ImportMerchantEditFormProps {
  stopEditing: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportMerchantEditForm: FC<ImportMerchantEditFormProps> = ({ stopEditing, setLoading }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { merchant, merchantContactPersonId } = importCase;
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const [form] = Form.useForm<MerchantType>();
  const handleSave = async (values: MerchantType) => {
    values = form.getFieldsValue(true);
    try {
      setLoading(true);
      const { data } = await saveMerchant(values);
      setImportCase({ ...importCase, merchant: data });
      stopEditing();
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  let contactPersonIndex = merchant?.contactPersons?.findIndex((cp) => cp.id === merchantContactPersonId) || 0;

  const isConstactPersonDisabled = contactPersonIndex < 0;

  const handleFormChange = (changedValues: any, values: MerchantType) => {
    delayedSetMatched(values, importCase.vehicle);
  };

  const delayedSetMatched = useCallback(
    debounce((values: MerchantType, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <Form
      form={form}
      initialValues={merchant || initMerchant}
      onValuesChange={handleFormChange}
      {...consts.formItemProps}
      className="m-0"
      onFinish={handleSave}
    >
      <MerchantFormFields />

      <Divider orientation="left">
        <label className="text-normal text-small">{_t("contact_persons")}</label>
      </Divider>

      <Form.Item name={["contactPersons", contactPersonIndex, "name"]} label={_t("name")}>
        <Input disabled={isConstactPersonDisabled} />
      </Form.Item>
      <Form.Item name={["contactPersons", contactPersonIndex, "email"]} label={_t("email")}>
        <Input disabled={isConstactPersonDisabled} />
      </Form.Item>

      <Form.Item name={["contactPersons", contactPersonIndex, "phone"]} label={_t("phone")}>
        <Input disabled={isConstactPersonDisabled} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <Row justify="space-between">
            <Col>
              <Button onClick={stopEditing}>{_t("cancel")}</Button>
            </Col>
            <Col>
              <SavedStatus status={savedStatus} />
              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default ImportMerchantEditForm;
