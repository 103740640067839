import React, { FC } from "react";
import { Button } from "antd";
import _t from "../../../../lang/translate";
import openModal from "../../../../components/modal/openModal";
import { ImportCaseType } from "../../../../types/importTypes";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import { isNum } from "../../../../utilities/typeGuard";

const ImportRejectCaseBtn: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const handleReview = async () => {
    const merchantId = importCase.merchant?.id;

    //Open review modal after sending offer:
    if (merchantId) {
      const { data: newRatingScore } = await openModal<{data: unknown}>("review", {
        initialValues: { sourceId: importCase.id, sourceContext: "import" },
        targetId: merchantId,
        targetContext: "merchant",
      });
      // Populate the new rating score:
      if (isNum(newRatingScore) && importCase.merchant) {
        const merchant = { ...importCase.merchant, rating: newRatingScore };
        setImportCase({ ...importCase, merchant });
      }
    }
  };
  const handleReject = async () => {
    try {
      const data = await openModal<ImportCaseType>("ImportRejectCase", { id: importCase.id });
      setImportCase(data);
      handleReview();
    } catch (error) {
    }
  };

  return (
    <Button danger onClick={handleReject}>
      {_t("reject", "case")}
    </Button>
  );
};

export default ImportRejectCaseBtn;
