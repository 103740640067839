import { Badge, Card, Collapse, Form, Input, Switch, Tabs } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import DateSelect from "../../../components/form/dateSelect";
import InputNum from "../../../components/form/inputNum";
import InputPrice from "../../../components/form/inputPrice";
import SelectGovernmentHandler from "../../../components/form/selectGovernmentHandler";
import SelectUser from "../../../components/form/selectUser";
import DividerLine from "../../../components/lineSplit";
import { useTaxLegalEvaluation } from "../../../contexts/taxLegalCaseStore";
import { useTaxLegalStages } from "../../../contexts/TaxLegalStagesStore";
import _t from "../../../lang/translate";
import consts from "../../../utilities/consts";
import { getValuesFilledStatus } from "../../../utilities/objectUtil";
import { CaseAttachmentsItem } from "../shared";

const { Panel } = Collapse;

type Props = {
  position: string;
  fingerprint: string
}
export default function Complaint(props: Props) {

  return (
    <Card className="tab-card" title={"Klagesag"}>
      <Tabs destroyInactiveTabPane={false} defaultActiveKey="submission">
        <Tabs.TabPane tab={_t("submission")} key="submission">
          <Reception {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={_t("case_presentation")} key="casePresentation">
          <CasePresentation {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={_t("conclusion")} key="complaintConclusion">
          <ComplaintConclusion {...props} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}


export type SubmissionForm = {
  submissionDate: string;
  submittedBy: number;
  calledBack: boolean;
}

type Names = {
  submission: {
    submission: (keyof SubmissionForm)[],
  },
  casePresentation: {
    presentation: (keyof CasePresentationForm)[]
    statement: (keyof CasePresentationForm)[]
  },
  conclusion: {
    conclusion: (keyof ComplaintConclusionForm)[]
  }
}

const names: Names = {
  submission: {
    submission: ["submissionDate", "submittedBy"],
  },
  casePresentation: {
    presentation: ["caseIdSANST", "casePresentationDocuments", "caseHandlerIdSANST"],
    statement: ["makingStatement", "statementDate", "statementDeadline"]
  },
  conclusion: {
    conclusion: ["conclusionDate", "equipmentsPrice", "newPrice", "registrationFee", "regulationAmount", "tradePrice", "statuteOfLimitationsDate"]
  }
}


function Reception({ position, fingerprint }: { position: string, fingerprint: string }) {
  const { registerForm, stages } = useTaxLegalStages()
  const initialValues = stages.find(stage => stage.fingerprint === fingerprint)?.data?.submission as SubmissionForm;
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.submission, initialValues))
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2', '3']);
  const [form] = Form.useForm<SubmissionForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "submission",
      position,
      type: "complaint"
    })
  }, [position])



  function handleValuesChange(val: Partial<SubmissionForm>, values: SubmissionForm) {
    debouncedCheckValues(values)
  }

  const debouncedCheckValues = useCallback(
    debounce((values: SubmissionForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.submission, values))
    }, 500),
    []);



  return (
    <Form key={fingerprint} form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("submission")}{' '}
            <Badge dot color={allFieldsHaveValues.submission ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >

          <Form.Item label={_t("dateOfSubmission")} name="submissionDate">
            <DateSelect />
          </Form.Item>
          <Form.Item className="fix-select-wrap" label={_t("submitted", "by")}>
            <SelectUser dealerId={1} typeId={1} name="submittedBy" noStyle />
          </Form.Item>
          <Form.Item label={_t("calledBack")} name="calledBack" valuePropName="checked">
            <Switch />
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("complaint")}>
            <CaseAttachmentsItem itemName="complaintDocuments" />
          </Form.Item>
        </Panel>
      </CustomCollapse>
    </Form>
  )
}

export type CasePresentationForm = {
  caseIdSANST: string;
  caseHandlerIdSANST: string;
  casePresentationDocuments: string;

  makingStatement: boolean;
  statementDeadline: string;
  statementDate: string;
  statementDocuments: string;
}

function CasePresentation({ position, fingerprint }: { position: string, fingerprint: string }) {

  const { registerForm, stages, getStagesForms } = useTaxLegalStages()
  const initialValues = stages.find(stage => stage.fingerprint === fingerprint)?.data?.casePresentation as CasePresentationForm;
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.casePresentation, initialValues))
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2']);
  const [form] = Form.useForm<CasePresentationForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "casePresentation",
      position,
      type: "complaint"
    })
  }, [position])
  useEffect(() => {
    setStatementDeadline()
  }, [])

  function handleValuesChange(val: Partial<CasePresentationForm>, values: CasePresentationForm) {
    debouncedCheckValues(values)
    if (val.makingStatement) {
      setStatementDeadline()
    }
  }

  function setStatementDeadline() {
    const currentStage = getStagesForms().get(fingerprint)?.data.submission
    const submissionDate = currentStage?.getFieldValue(['submissionDate'])
    const currentDeadline = form.getFieldValue(['statementDeadline'])
    if (submissionDate && !currentDeadline) {
      const statementDeadline = moment(submissionDate).add(14, 'days')
      form.setFieldsValue({ statementDeadline: statementDeadline.toISOString() })
    }
  }

  const debouncedCheckValues = useCallback(
    debounce((values: CasePresentationForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.casePresentation, values))
    }, 500),
    []);



  return (

    <Form key={fingerprint} form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("case_presentation")}{' '}
            <Badge dot color={allFieldsHaveValues.presentation ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >

          <Form.Item label={"Sagsnummer SANST"} name="caseIdSANST">
            <Input />
          </Form.Item>

          <Form.Item className="fix-select-wrap" label={_t("sanst", "caseHandler")} name="caseHandlerIdSANST">
            <SelectGovernmentHandler addModalExtraValues={{ typeId: "tax_appeal" }} />
          </Form.Item>

          <Form.Item label={_t("case_presentation")}>
            <CaseAttachmentsItem itemName="casePresentationDocuments" />
          </Form.Item>

        </Panel>
        <Panel header={
          <CustomCollapseHeader>
            2. {_t("statement")}{' '}
            <Badge dot color={allFieldsHaveValues.statement ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="2" >

          <Form.Item name="makingStatement" label={_t("statement")} valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const makingStatement = getFieldValue(['makingStatement'])
              return makingStatement && (
                <>
                  <Form.Item label={"Frist for udtalelsesdato"} name="statementDeadline">
                    <DateSelect />
                  </Form.Item>

                  <Form.Item label={"Udtalelsesdato"} name="statementDate">
                    <DateSelect />
                  </Form.Item>

                  <DividerLine style={{ marginBlock: 12 }} />

                  <Form.Item label={_t("statement")}>
                    <CaseAttachmentsItem itemName="statementDocuments" />
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>

        </Panel>
      </CustomCollapse>
    </Form>
  )
}




export type ComplaintConclusionForm = {
  conclusionDate: string;
  regulationAmount: number;
  newPrice: number;
  equipmentsPrice: number;
  tradePrice: number;
  registrationFee: number;
  statuteOfLimitationsDate: string;
  conclusionDocuments: string;
}

function ComplaintConclusion({ position, fingerprint }: { position: string, fingerprint: string }) {

  const { registerForm, stages } = useTaxLegalStages()
  const initialValues = stages.find(stage => stage.fingerprint === fingerprint)?.data?.complaintConclusion as ComplaintConclusionForm;
  const evaluation = useTaxLegalEvaluation();
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.conclusion, initialValues))
  const [activeKey, setActiveKey] = useState<string[]>(['1']);
  const [form] = Form.useForm<ComplaintConclusionForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "complaintConclusion",
      position,
      type: "complaint"
    })
  }, [position])


  function handleValuesChange(val: Partial<ComplaintConclusionForm>, values: ComplaintConclusionForm) {
    debouncedCheckValues(values)
    if (val.conclusionDate) {
      const statuteOfLimitationsDate = moment(val.conclusionDate).add(3, "months")
      form.setFieldsValue({ statuteOfLimitationsDate: statuteOfLimitationsDate.toISOString() })
    }
    if ("registrationFee" in val) {
      const diff = (val.registrationFee || 0) - (evaluation?.registrationFee || 0 )
      form.setFieldsValue({regulationAmount: diff})
    }
  }

  const debouncedCheckValues = useCallback(
    debounce((values: ComplaintConclusionForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.conclusion, values))
    }, 500),
    []);



  return (

    <Form key={fingerprint} form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("conclusion")}{' '}
            <Badge dot color={allFieldsHaveValues.conclusion ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >


          <Form.Item label={_t("dateOfConclusion")} name="conclusionDate">
            <DateSelect />
          </Form.Item>
          <Form.Item label={_t("limitationDate")} name="statuteOfLimitationsDate">
            <DateSelect />
          </Form.Item>

          <Form.Item name="newPrice" label={_t("sanst", "new_price")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="equipmentsPrice" label={_t("sanst", "equipment")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="tradePrice" label={_t("sanst", "trade_price")}>
            <InputNum suffix={"kr."} />
          </Form.Item>

          <Form.Item name="registrationFee" label={_t("sanst", "registration_fee")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item label={_t("regulation")} name="regulationAmount">
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("conclusion")}>
            <CaseAttachmentsItem itemName="conclusionDocuments" />
          </Form.Item>
        </Panel>
      </CustomCollapse>
    </Form>
  )
}