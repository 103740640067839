import { UploadFile } from "antd/lib/upload/interface";
import { Client, EnumItemType, IdType, Person, RelatedCasesType, Vehicle } from "./appTypes";
import { RequestForm } from "../screens/taxLegal/stages/resumption";
import { ConclusionForm, HearingOfPartiesForm, MaterialsForm } from "../screens/taxLegal/stages/priceCheck";
import { CasePresentationForm, ComplaintConclusionForm, SubmissionForm } from "../screens/taxLegal/stages/complaint";


export type TaxLegalCase = {
  id: IdType;
  governmentBatchId: string | null;
  // client: Client | null;
  clientName: string | null;
  dealerName: string | null;
  caseHandler: Person | null;
  createdAt: string;
  governmentCaseNumber: string;
  referenceCode: string;
  status: EnumItemType | null
  nextDeadline: string | null
  penaltyType: EnumItemType | null
  penaltyCaseId: string | null;
  vehicle: Vehicle | null;
  originalEvaluation: OriginalEvaluation | null;
  files: UploadFile[] | null;
  importantNote: string | null;
  note: string | null;
  relatedCases: RelatedCasesType | null;
  stages: TaxLegalStage[];
  activeStageValues: null | ActiveStageValues;
  allDMRDocumentsRequestedAt: string | null;
  repairRequestedAt: string | null;
}

export type TaxLegalStage = {
  version: string;
  type: "price_check" | "complaint" | "resumption",
  position: string,
  data: TaxLegalStageData;
  fingerprint: string;
  timestamp: string;
}

export type TaxLegalStageData = Partial<{
  materials: MaterialsForm;
  hearingOfParties: HearingOfPartiesForm;
  conclusion: ConclusionForm;
  submission: SubmissionForm;
  request: RequestForm;
  casePresentation: CasePresentationForm;
  complaintConclusion: ComplaintConclusionForm;
}>

export type StoreTaxLegalCase = {
  vin: string,
  externalReferenceCode: string,
  note?: string | null,
  materialFiles: { id: number }[],
  documentationFiles: { id: number }[],
  client: Partial<Client>
}
export type UpdateTaxLegalCase = TaxLegalCase & {
  revision?: TaxLegalCaseRevision,
}

export type TaxLegalDashboard = {
  id: IdType;
  createdAt: string;
  governmentCaseNumber: string;
  governmentBatchId: string | null;
  referenceCode: string;
  reporterCompany: string;
  car: string | null,
  vin: string | null,
  firstRegDate: string | null,
  status: EnumItemType | null,
  nextDeadline: EnumItemType | null,
  penaltyType: string | null,
  regulationAmount: number | null,
  clientName: string | null,
  dealerName: string | null,
  repairRequestedAt: string | null,
  caseHandler: Person;
}



export type EditVehicleAndEvaluation = {
  vehicle: Vehicle,
  originalEvaluation: OriginalEvaluation
}

export type OriginalEvaluation = {
  reviewDate: string | null,
  reporterCompany: string | null,
  caseHandler: string | null,
  dmfCaseId: string | number | null,
  newPrice: number | null,
  equipmentsPrice: number | null,
  tradePrice: number | null,
  registrationFee: number | null,
  externalReferenceCode: number | string | null,
  mileageDocumentation: boolean | null,
  newPriceSourceTag: null | number,
  equipmentPriceSourceTags: null | string[],
}

export type TaxLegalCaseRevision = {
  nextDeadline: string,
  note: string,
  statusId: number,
  caseHandlerId: number,
  penaltyTypeId: number,
  penaltyCaseId: string,
  allDMRDocumentsRequestedAt: string | null,
  repairRequested: boolean;
  governmentBatchId: string | null,
}


export type GovernmentCaseHandler = {
  id: number;
  email: string;
  name: string;
  phone: string;
  createdAt: string;
  typeId: "motor_registry" | "tax_appeal"
}

export type TagStoreQuick = {
  title: string;
  categoryId: TaxLegalCategoryId
}

export type TaxLegalCategoryId = "tax_legal_compliance" | "tax_legal_price_source" | "tax_legal_analysis";
export const taxLegalCategories: { categoryId: TaxLegalCategoryId, label: string }[] = [
  { categoryId: "tax_legal_analysis", label: "Analyse" },
  { categoryId: "tax_legal_compliance", label: "Mgl. regelefterlevelse" },
  { categoryId: "tax_legal_price_source", label: "Priskilde" },
] as const;

export type ActiveStageValues = {
  activeStage: TaxLegalStage['type'];
  regulationAmount: null | number,
  governmentNewPriceAssessment: number | null;
  governmentTradePriceAssessment: number | null;
  regulationReduction: null | number,
  governmentCaseHandlerId: number,
  governmentCaseNumber: string,
  analysisTags: string[],
  complianceTags: string[],
  mstNewPrice: number,
  mstEquipmentPrice: number,
  mstTradePrice: number,
  mstRegistrationFee: number,
  statuteOfLimitationsDate: string,
  conclusionDate: string,
}

export const initTaxLegalCase: TaxLegalCase = {
  id: 0,
  governmentBatchId: null,
  createdAt: "",
  caseHandler: null,
  nextDeadline: null,
  governmentCaseNumber: "",
  penaltyType: null,
  penaltyCaseId: null,
  referenceCode: "",
  status: null,
  vehicle: null,
  originalEvaluation: null,
  files: [],
  // client: null,
  importantNote: null,
  note: null,
  relatedCases: null,
  clientName: null,
  dealerName: null,
  stages: [],
  activeStageValues: null,
  allDMRDocumentsRequestedAt: null,
  repairRequestedAt: null,
}
