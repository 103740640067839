import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { FC } from "react";
import Icon from "../../../../assets/icons/icon";
import DragUpload from "../../../../components/dragUpload";
import DividerLine from "../../../../components/lineSplit";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import UploadButton from "../../../../components/uploadButton";
import UploadFileRender from "../../../../components/uploadFileRender";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import useIsDraggingFiles from "../../../../hooks/useIsDraggingFiles";
import _t from "../../../../lang/translate";
import { deleteFile, openFileLink } from "../../../../services/mediaService";
import { resellerTaxInternalInvoiceUrl } from "../../../../services/resellerTaxService";
import { accepted } from "../../../../utilities/acceptedFiles";

const { confirm } = Modal;

interface TaxReceiptActionsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResellerTaxInternalInvoiceActions: FC<TaxReceiptActionsProps> = ({ setLoading }) => {
  const [isDragging] = useIsDraggingFiles();
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const { internalInvoice } = resellerTaxCase;
  const action = resellerTaxInternalInvoiceUrl(resellerTaxCase.id);

  const handleFileDelete = async (file: UploadFile) => {
    const serverId = file.response?.id;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        setLoading(true);
        await deleteFile(action, serverId);
      } catch (error) {
        renderErrorMessage(error, _t("msg.file_not_removed"));
        canRemove = false;
      } finally {
        setLoading(false);
      }
    }
    if (canRemove) {
      setResellerTaxCase({ ...resellerTaxCase, internalInvoice: null });
    }
  };

  const handleUploadChange = ({ file, fileList }: any) => {
    setResellerTaxCase({ ...resellerTaxCase, internalInvoice: fileList });
  };

  const props = {
    disabled: !!internalInvoice?.length && internalInvoice[0].status === "done",
    fileList: internalInvoice || [],
    accept: accepted.join(","),
    action: resellerTaxInternalInvoiceUrl(resellerTaxCase.id),
    onChange: handleUploadChange,
  };

  return (
    <div className="position-relative">
      <Typography.Title className="mb-05" level={5}>
        {_t("DMF", "invoice")}
      </Typography.Title>

      {!!resellerTaxCase.internalInvoice?.length && (
        <>
          <UploadFileRender onRemove={handleFileDelete} hideDownload file={resellerTaxCase.internalInvoice[0]}
            loading={false} />
          <DividerLine className="mt-05 mb-05" />
        </>
      )}
      <DragUpload
        className={`${isDragging && !props.disabled ? "is-dragging" : ""} file-drop-zone`}
        showUploadList={false}
        {...props}
      >
        <Typography.Title level={3} className="mb-0">
          {_t("msg.drop_receipt_her")}
        </Typography.Title>
      </DragUpload>
      <Row justify="space-between" gutter={8} align="middle" className="mt-1">
        <Col>
          <UploadButton {...props} />
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              disabled={!resellerTaxCase.internalInvoice?.length}
              ghost
              onClick={() => resellerTaxCase.internalInvoice && openFileLink(resellerTaxCase.internalInvoice[0]?.url)}
            >
              <Icon name="download-outline" size="large" /> {_t("invoice")}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ResellerTaxInternalInvoiceActions;
