import React, { FC, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Button, Row, Col, Input, Space, Select, DatePicker, Result, Tooltip, message, Tag } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import _t from "../../lang/translate";
import Icon from "../../assets/icons/icon";
import DataTable from "../../components/table/dataTable";
import { CustomColumnsType } from "../../components/table/dataTableTypes";
import UploadButton from "../../components/uploadButton";
import CollapseCard from "../../components/collapseCard";
import { useApi } from "../../services/useApi";
import { pageSizeKey, searchKey } from "../../services/urlQueryService";
import { MonthlySpecificationListType } from "../../types/systemTypes";
import { PaginationedData } from "../../types/apiTypes";
import {
  LocationStateType,
  ReconcileOptionsListType,
  MonthlySpecificationFilterType,
  MonthlySpecificationStatusListType,
  LinkType
} from "../../types/appTypes";
import {
  systemMonthlySpecificationUrl,
  downloadMonthlySpecificationList,
  uploadMonthlySpecificationCsv
} from "../../services/systemService";
import { getPath } from "../../routes/appRoutes";
import format from "../../utilities/formatNumbers";
import { isAxiosError } from "../../utilities/typeGuard";
import hexToRGB from "../../utilities/colorUtil";
import { getFileLink } from "../../services/mediaService";
import { renderErrorMessage } from "../../components/messages/errorMessage";

// Hardcoded to 1000 for status type of 'all' since this isn't defined in the BE. The rest of the status types are defined in the BE.
const statuses: MonthlySpecificationStatusListType[] = [
  {
    id: 1000,
    name: "all"
  },
  {
    id: 1,
    name: "open",
  },
  {
    id: 2,
    name: "registered"
  },
  {
    id: 3,
    name: "closed",
  },
  {
    id: 4,
    name: "cancelled"
  }
];

const reconcileOptions: ReconcileOptionsListType[] = [
  {
    id: 1001,
    name: "all"
  },
  {
    id: 5,
    name: "yes",
  },
  {
    id: 6,
    name: "no"
  }
];

const MonthlySpecification: FC = () => {
  const dateFormat = "DD-MM-YYYY";
  const day = "day";
  const date = "date";
  const location = useLocation<LocationStateType>();

  const defaultStartDate = moment(1, 'DD')
  const defaultEndDate = moment();

  const defaultValues: MonthlySpecificationFilterType = {
    selectedStartDate: defaultStartDate,
    selectedEndDate: defaultEndDate,
    selectedStartingAmount: 0,
    selectedEndingAmount: 10000000,
    selectedStatus: 1000,
    selectedReconcileOption: 1001
  };

  const [filters, setFilters] = useState<MonthlySpecificationFilterType>(defaultValues);
  const [exporting, setExporting] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [refreshId, setRefreshId] = useState<string | undefined>();
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<MonthlySpecificationListType>>("", { data: [] });

  const getQuery = () => {
    const query = new URLSearchParams(location.search);

    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");

    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }

    if (filters.selectedStatus !== 1000) {
      // Set the value of the status in the query only for other types of status, except for status type of 'all'
      query.set('status', filters.selectedStatus.toString());
    }

    if (filters.selectedReconcileOption !== 1001) {
      let value: any = filters.selectedReconcileOption;
      value = (value === 5);

      // Set the value of the reconciled in the query only for other types of status, except for status type of 'all'
      query.set('isReconciled', value);
    }

    query.set('startingAmount', filters.selectedStartingAmount.toString());
    query.set('endingAmount', filters.selectedEndingAmount.toString());

    query.set('startDate', filters.selectedStartDate.toISOString());
    query.set('endDate', filters.selectedEndDate.toISOString());

    return query;
  };

  const handleDownloadMonthlySpecificationList = async () => {
    try {
      setExporting(true);

      const query = getQuery();

      const { data } = await downloadMonthlySpecificationList(query.toString());

      data.url && window.open(data.url, "_blank");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;

      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setExporting(false);
    }
  };

  const handleCsvDownload = async (link: LinkType) => {
    try {
      if (!link.external) return;

      const { data } = await getFileLink(link.external);

      window.open(data.url, "_blank");
    } catch (error) {
      renderErrorMessage(error);
    }
  };

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  const [form] = Form.useForm();

  const clearFieldErrors = (allValues: MonthlySpecificationFilterType) => {
    const updatedFields = Object.keys(allValues)
      .filter(name => form.getFieldError(name).length)
      .map(name => ({ name, errors: [] }));

    form.setFields(updatedFields);
  };

  const onFinish = (values: MonthlySpecificationFilterType) => {
    const filters = {
      selectedStartDate: values.selectedStartDate,
      selectedEndDate: values.selectedEndDate,
      selectedStartingAmount: values.selectedStartingAmount,
      selectedEndingAmount: values.selectedEndingAmount,
      selectedStatus: values.selectedStatus,
      selectedReconcileOption: values.selectedReconcileOption
    };

    setFilters(filters);
  };

  const downloadIcon = <Icon size="large" name="download-outline" />;
  const searchIcon = <Icon size="large" name="search" />;

  const columns: CustomColumnsType<MonthlySpecificationListType> = [
    {
      title: _t("date"),
      align: "left",
      sorter: true,
      key: "registrationDate",
      dataIndex: "registrationDate",
      render: (registrationDate) => registrationDate ? format.date(registrationDate) : "-"
    },
    {
      title: _t("registration_number_short"),
      align: "left",
      key: "licensePlate",
      dataIndex: "licensePlate",
      render: (licensePlate) => licensePlate ? licensePlate : "-"
    },
    {
      title: _t("vehicle"),
      align: "left",
      key: "car",
      dataIndex: "car",
    },
    {
      title: _t("vin"),
      align: "left",
      key: "vin",
      dataIndex: "vin",
      render: (vin) => vin ? vin.toUpperCase() : '-'
    },
    {
      title: _t("case"),
      align: "left",
      key: "id",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <Link to={getPath("tax", record.id)} target="_blank" rel="noopener noreferrer" >
            <strong>{text}</strong>
          </Link>
        </>
      ),
    },
    {
      title: _t("feeKr"),
      align: "left",
      key: "registrationFee",
      dataIndex: "registrationFee",
      render: (registrationFee) => registrationFee ? format.number(registrationFee) : format.number(0)
    },
    {
      title: _t("invoiceAmountKr"),
      align: "left",
      key: "invoiceAmount",
      dataIndex: "invoiceAmount",
      render: (invoiceAmount, record) => {
        const registrationFee = record.registrationFee;
        const showIcon = (invoiceAmount > registrationFee || invoiceAmount < registrationFee);

        return (
          <Tooltip title={invoiceAmount ? `${_t("invoiceNumber")}: ${record.invoiceNumber}` : ''}>
            <span>{invoiceAmount ? format.number(invoiceAmount) : format.number(0)}</span>
            <span>{showIcon && <Icon name="information-circle" color="#FAAD14"></Icon>}</span>
          </Tooltip>
        );
      }
    },
    {
      title: _t("status"),
      align: "left",
      render: (text, record) => {
        const status = record.status;
        const styles = status && {
          color: status.color,
          backgroundColor: hexToRGB(status.color!, 0.2),
          borderColor: status.color,
          borderWidth: 1,
          borderStyle: "solid",
          fontSize: 12,
          fontWeight: 400,
          padding: "1px 8px"
        };

        return status ? (
          <Tag style={styles}>
            {status.title}
          </Tag>
        ) : "-";
      }
    },
    {
      title: _t("attachmentNumber"),
      align: "left",
      key: "attachmentNumber",
      dataIndex: "attachmentNumber",
      render: (attachmentNumber) => attachmentNumber ? attachmentNumber : "-"
    },
    {
      title: _t("valueAssessedDate"),
      align: "left",
      sorter: true,
      key: "receiptSentAt",
      dataIndex: "receiptSentAt",
      render: (date) => format.date(date),
    },
    {
      title: _t("reconciled"),
      align: "left",
      key: "isReconciled",
      dataIndex: "isReconciled",
      render: (isReconciled, record) => {
        return isReconciled ?
          (
            <Tooltip title={_t("clickToDownloadCsv")}>
              <Icon name="checkmark-circle-outline" className="text-green" size="medium" onClick={() => handleCsvDownload(record.reconciledFileLink)} />
            </Tooltip>
          )
          : '-';
      }
    }
  ];

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  const handleUploadChange = ({ file, fileList }: any) => {
    if ((fileList[0].status === "done" || fileList[0].status === "error") && uploading) {
      setUploading(false);

      handleRefresh();
    } else if (fileList[0].status === "uploading" && !uploading) {
      setUploading(true);
    }
  };

  const uploadProps = {
    accept: ".csv",
    showUploadList: false,
    maxCount: 1,
    action: uploadMonthlySpecificationCsv(),
    onChange: handleUploadChange,
  };

  const tableFilters = (
    <Space>
      <UploadButton
        {...uploadProps}
        loading={uploading}
        buttonText={`${_t("upload")} csv`}
        uploadIcon={<Icon name="cloud-upload-outline" size="medium" />}
      />
    </Space>
  )

  useEffect(() => {
    const query = getQuery();

    const url = systemMonthlySpecificationUrl(query.toString());

    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, refreshId, filters]);

  return (
    <>
      <div className="site-card-border-less-wrapper">
        <CollapseCard title={_t("filterAndExport")} style={{ width: "auto" }} defaultOpen={true}>

          {/* Start and end dates */}
          <Form
            layout="horizontal"
            labelAlign="left"
            initialValues={defaultValues}
            form={form}
            onFinish={onFinish}
            onValuesChange={clearFieldErrors}
          >
            <Row gutter={24} justify={'space-between'}>
              <Col flex={0}>
                <Form.Item
                  label={_t("chooseDateFrom")}
                  name="selectedStartDate"
                  rules={[
                    {
                      validator: (_, value) => {
                        const selectedEndDate = form.getFieldValue('selectedEndDate');

                        if (value.isAfter(selectedEndDate)) {
                          return Promise.reject(new Error(_t("startDateAfterEndDateErrMsg")));
                        }

                        return Promise.resolve();
                      }
                    }]
                  }
                >
                  <DatePicker
                    format={dateFormat}
                    picker={date}
                    allowClear={false}
                    style={{ width: 200 }}
                    disabledDate={(current) => {
                      return defaultEndDate.add(0, day) < current;
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={_t("chooseDateTo")}
                  name="selectedEndDate"
                  rules={[
                    {
                      validator: (_, value) => {
                        const selectedStartDate = form.getFieldValue('selectedStartDate');

                        if (value.isBefore(selectedStartDate)) {
                          return Promise.reject(new Error(_t("endDateBeforeStartDateErrMsg")));
                        }

                        return Promise.resolve();
                      }
                    }]
                  }
                >
                  <DatePicker
                    format={dateFormat}
                    picker={date}
                    allowClear={false}
                    style={{ width: 200 }}
                    disabledDate={(current) => {
                      return defaultEndDate.add(0, day) < current;
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Starting and ending amount(s) */}
              <Col flex={0}>
                <Form.Item
                  label={_t("chooseAmountFrom")}
                  name="selectedStartingAmount"
                  rules={[
                    { transform: (value) => format.number(value) },
                    { message: _t("amountMustBeNumber"), pattern: new RegExp(/^[0-9]+$/) },
                    {
                      validator: (_, value) => {
                        const selectedEndingAmount = form.getFieldValue('selectedEndingAmount');

                        if (Number(value) >= Number(selectedEndingAmount)) {
                          return Promise.reject(new Error(_t("startAmountGreaterThanEndAmountErrMsg")));
                        }

                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input
                    suffix={_t("dkk_price_short")}
                    style={{ width: 200 }}
                  />
                </Form.Item>

                <Form.Item
                  label={_t("chooseAmountTo")}
                  name="selectedEndingAmount"
                  rules={[
                    { message: _t("amountMustBeNumber"), pattern: new RegExp(/^[0-9]+$/) },
                    {
                      validator: (_, value) => {
                        const selectedStartingAmount = form.getFieldValue('selectedStartingAmount');

                        if (Number(value) <= Number(selectedStartingAmount)) {
                          return Promise.reject(new Error(_t("endAmountLesserThanStartAmountErrMsg")));
                        }

                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input
                    suffix={_t("dkk_price_short")}
                    style={{ width: 200 }}
                  />
                </Form.Item>
              </Col>

              {/* Status */}
              <Col flex={0}>
                <Form.Item label={_t("status")} name="selectedStatus">
                  <Select style={{ width: 200 }}>
                    {statuses.map((status: any) => <Select.Option key={status.id} value={status.id}>{_t(status.name)}</Select.Option>)}
                  </Select>
                </Form.Item>

                <Form.Item label={_t("reconciled")} name="selectedReconcileOption">
                  <Select style={{ width: 200 }}>
                    {reconcileOptions.map((option: any) => <Select.Option key={option.id} value={option.id}>{_t(option.name)}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Button Actions */}
            <div style={{ width: "100%", marginTop: 25, display: "flex", justifyContent: "flex-end" }}>
              <Space>
                <Button
                  type="default"
                  onClick={handleDownloadMonthlySpecificationList}
                  loading={exporting}
                  size="middle"
                  icon={downloadIcon}
                >
                  {_t("exportList")}
                </Button>

                <Button
                  type="primary"
                  size="middle"
                  icon={searchIcon}
                  htmlType="submit"
                >
                  {_t("search")}
                </Button>
              </Space>
            </div>
          </Form>
        </CollapseCard>
      </div>

      {/* Data Table */}
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) :
        <DataTable<MonthlySpecificationListType>
          onRefresh={handleRefresh}
          filter={tableFilters}
          loading={isLoading}
          columnStorageKey={"MONTHLY_SPECIFICATION"}
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      }
    </>
  );
};

export default MonthlySpecification;
