import { Button, Col, Form, Row } from "antd";
import debounce from "lodash/debounce";
import { FC, useCallback, useState } from "react";
import CardBox from "../../../../components/cardBox";
import CollapseCard from "../../../../components/collapseCard";
import LinkBox from "../../../../components/form/linkBox";
import LogText from "../../../../components/logText";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import SavedStatus from "../../../../components/savedStatus";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import { updateTaxDocVehicle } from "../../../../services/taxDocService";
import { Vehicle } from "../../../../types/appTypes";
import consts from "../../../../utilities/consts";
import { compare } from "../../../../utilities/deepCompare";
import TaxDocVehicleInfo from "../taxDocVehicleInfo";
import TaxVehicleForm from "./taxDocVehicleForm";
import TaxDocVehicleSourceModal from "./taxDocVehicleSourceModal";

const TaxDocVehicleEdit: FC = () => {
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const vehicleLink = taxDocCase.vehicle?.link;

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  const handleSave = async (values: Vehicle) => {
    try {
      setLoading(true);
      const { data } = await updateTaxDocVehicle(taxDocCase.id, values);
      setTaxDocCase(data);
      stopEditing();
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changedValues: any, values: Vehicle) => {
    delayedSetMatched(values, taxDocCase.vehicle);
  };

  const delayedSetMatched = useCallback(
    debounce((values: Vehicle, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <>
      <CollapseCard title={_t("vehicle")} loading={loading}>
        <Form
          {...consts.formItemProps}
          className="m-0"
          onValuesChange={handleFormChange}
          initialValues={taxDocCase.vehicle || {}}
          requiredMark={false}
          onFinish={handleSave}
        >
          {isEditing ? (
            <>
              {vehicleLink && (
                <Form.Item label={_t("link")} shouldUpdate>
                  {({ setFieldsValue }) => {
                    return (
                      <LinkBox
                        link={vehicleLink}
                        onFetch={(data) => {
                          const vehicle = { ...data.vehicle };
                          vehicle.typeId = taxDocCase.vehicle?.typeId;
                          setFieldsValue(vehicle);
                        }}
                      />
                    );
                  }}
                </Form.Item>
              )}
              <TaxVehicleForm showFetchCarData />
            </>
          ) : (
            <>
              <TaxDocVehicleInfo />
              <CardBox title={_t("note")}>{taxDocCase.note || "-"}</CardBox>
            </>
          )}

          <div className="text-right">
            {isEditing ? (
              <Form.Item noStyle shouldUpdate>
                {({ submit }) => (
                  <Row justify="space-between">
                    <Col>
                      <Button onClick={stopEditing}>{_t("cancel")}</Button>
                    </Col>
                    <Col>
                      <SavedStatus status={savedStatus} />
                      <Button type="primary" onClick={submit}>
                        {_t("save")}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            ) : (
              <Row justify="space-between" align="bottom">
                <Col>
                  <TaxDocVehicleSourceModal />
                </Col>
                <Col>
                  <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
                    {_t("edit")}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </CollapseCard>
      {taxDocCase.log?.creation && (
        <LogText className="p-0 text-right mt--1  mb-1" small logData={taxDocCase.log.creation} />
      )}
    </>
  );
};

export default TaxDocVehicleEdit;
