import {Button, Space} from "antd";
import React, {FC, useState} from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import Icon from "../../../../assets/icons/icon";
import {downloadFullDocumentation} from "../../../../services/taxService";
import {renderErrorMessage} from "../../../../components/messages/errorMessage";

const TaxFullDocumentationDownloadBtns: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {taxCase} = TaxCaseStore.useContainer();

  async function downloadFile() {
    try {
      setLoading(true);
      const {data} = await downloadFullDocumentation(taxCase.id);
      if (data.url) window.open(data.url, "_blank");
      setLoading(false);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="text-right">
      <Space>
        <Button
          type="primary"
          onClick={downloadFile}
          disabled={loading}
        >
          <Icon name="download-outline" size="large"/>
          {_t("download")}
        </Button>
      </Space>
    </div>
  );
};

export default TaxFullDocumentationDownloadBtns;
