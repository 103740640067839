
export const constructURL = (...data: string[]) => {
  return data.reduce((acc, item) => acc + (item.endsWith("/") ? item : item + "/"), "").slice(0, -1);
};

export const getRedirectParts = (pathname: string|undefined = undefined) => {
  pathname = pathname || '';
  const id = pathname.split('/')?.[2]
  let context: string|null = null;
  if (pathname.indexOf("/tax") === 0) {
    context = "tax";
  } else if (pathname.indexOf("/bilimport") === 0) {
    context = "import";
  } else if (pathname.indexOf("/leasing-dmf") === 0) {
    context = "leasing";
  }

  let value: number|null = parseInt(id);
  value = isNaN(value) ? null : value

  return {
    context: context,
    value: value
  }
}
