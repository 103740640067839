import { PaginationedData } from "../types/apiTypes";
import { ChangeClientType, IdType, Vehicle, } from "../types/appTypes";
import {
  AcceptOfferFormType, ResellerTaxCase,
  ResellerTaxCaseCreation,
  ResellerTaxCustomerType,
  ResellerTaxListItem
} from "../types/resellerTaxTypes";
import { CloneTaxCaseType, SendOfferFormType } from "../types/taxTypes";
import getApiEndpoint from "./apiEndpoint";
import http from "./httpService";
import { mediaApiEndpoint } from "./mediaService";
import { getApiQuery } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/resellerTaxCase`;

export function resellerTaxCaseUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function resellerTaxFileUrl(id?: IdType) {
  return id ? `${resellerTaxCaseUrl(id)}/file` : mediaApiEndpoint;
}

export function resellerTaxCasesURL(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function getResellerTaxCases(query?: string) {
  return http.get<PaginationedData<ResellerTaxListItem>>(
    `${apiEndpoint}?${getApiQuery(query)}`
  );
}

export function saveOfferOptimization(id: IdType, data: any, optimizationMode?: boolean) {
  return http.post(
    `${resellerTaxCaseUrl(id)}/${optimizationMode ? "optimization" : "offer"
    }`,
    data
  );
}

export function deleteResellerTaxCase(id: IdType) {
  return http.delete(resellerTaxCaseUrl(id));
}

export function createResellerTaxCase(data: ResellerTaxCaseCreation) {
  return http.post<ResellerTaxCaseCreation>(apiEndpoint, data);
}

export function saveResellerTaxCaseStatus(id: IdType, data: any) {
  return http.put(`${resellerTaxCaseUrl(id)}/status`, data);
}

export function resellerTaxActivitiesUrl(id: IdType) {
  return `${resellerTaxCaseUrl(id)}/activities`;
}

export function acceptResellerTaxOffer(id: IdType, data: AcceptOfferFormType) {
  return http.put(`${resellerTaxCaseUrl(id)}/accepted`, data);
}

export function toggleChecklistFlag(id: IdType, data: { key: string, status: boolean }) {
  return http.post(`${resellerTaxCaseUrl(id)}/toggleChecklistFlag`, data);
}

export function paidTaxOffer(id: IdType, data: any) {
  return http.put(`${resellerTaxCaseUrl(id)}/paid`, data);
}

export function uploadedTaxOffer(id: IdType, data: any) {
  return http.put(`${resellerTaxCaseUrl(id)}/documented`, data);
}

export function createResellerTaxInvoice(id: IdType) {
  return http.post(`${resellerTaxCaseUrl(id)}/createInvoice`, {
    draftOnly: false,
    send: false,
  });
}

export function saveResellerTaxDraftInvoice(id: IdType) {
  return http.post(`${resellerTaxCaseUrl(id)}/createInvoice`, {
    draftOnly: true,
    send: false,
  });
}

export function updateFromTaxCase(id: IdType, data: any) {
  return http.post(`${resellerTaxCaseUrl(id)}/updateFromTaxCase`, data);
}

export function sentResellerTaxOffer(id: IdType, data: SendOfferFormType) {
  return http.post(`${resellerTaxCaseUrl(id)}/sendOffer`, data);
}

export function requestOptimization(id: IdType) {
  return http.post(`${resellerTaxCaseUrl(id)}/requestOptimization`);
}

export function requestRecalculation(id: IdType) {
  return http.post(`${resellerTaxCaseUrl(id)}/requestRecalculation`);
}

export function updateResellerTaxActivity(id: IdType, data: any) {
  return http.put(`${resellerTaxActivitiesUrl(id)}/${data?.id}`, data);
}

export function saveResellerTaxActivity(id: IdType, data: any) {
  return http.post(resellerTaxActivitiesUrl(id), data);
}

export function deleteResellerTaxActivity(id: IdType, deletedId: string) {
  return http.delete(`${resellerTaxActivitiesUrl(id)}/${deletedId}`);
}

export function getSavedOfferOptimizationUrl(id: IdType, optimizationMode: boolean) {
  return `${resellerTaxCaseUrl(id)}/${optimizationMode ? "optimization" : "offer"
    }/`;
}

export function resellerTaxInternalInvoiceUrl(id?: IdType) {
  return id ? `${resellerTaxCaseUrl(id)}/internalInvoice` : mediaApiEndpoint;
}

export function resellerTaxExternalReceiptUrl(id?: IdType) {
  return id ? `${resellerTaxCaseUrl(id)}/externalReceipt` : mediaApiEndpoint;
}

export function sentResellerTaxReceipt(id: IdType) {
  return http.put(`${resellerTaxCaseUrl(id)}/receipted`, { status: true });
}

export function updateResellerTaxCaseCustomerInfo(id: IdType, data: ResellerTaxCustomerType) {
  return http.put(`${resellerTaxCaseUrl(id)}/updateCustomerInfo`, data);
}

export function downloadAllFiles(id: IdType) {
  return http.get(`${resellerTaxCaseUrl(id)}/downloadAllFiles`);
}

export function updateResellerTaxCaseClient(id: IdType, data: ChangeClientType) {
  return http.put(`${resellerTaxCaseUrl(id)}/client`, data);
}

export function updateResellerTaxVehicle(id: IdType, data: Vehicle) {
  return http.put(`${resellerTaxCaseUrl(id)}/vehicle`, data);
}

export function toTaxCase(id: IdType, data: CloneTaxCaseType) {
  return http.post<ResellerTaxCase>(`${resellerTaxCaseUrl(id)}/toTaxCase`, data);
}
