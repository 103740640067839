import {FC, useState} from "react";
import {Tabs} from "antd";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import TaxFlagsSwitches from "../../../../components/taxFlagsSwitches";
import _t from "../../../../lang/translate";
import TaxInvoiceActions from "./taxInvoiceActions";
import TaxReceiptActions from "./taxReceiptActions";
import TaxDocUploads from "../documentation/taxDocUploads";
import TaxDocNote from "../documentation/taxDocNote";
import TaxDocPrerequisites from "../documentation/taxDocPrerequisites";
import TaxDocDownloadBtns from "../documentation/taxDocDownloadBtns";
import TaxDocMotorAgencyStatus from "../documentation/taxDocMotorAgencyStatus";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import PaymentCardDetails from "../../../../components/paymentDetails/PaymentCardDetails";
import TaxDownloadInvoiceBtn from "../user/taxDownloadInvoiceBtn";
import TaxFullDocumentationSupplementaryData from "../fullDocumentation/taxFullDocumentationSupplementaryData";
import TaxFullDocumentationUploads from "../fullDocumentation/taxFullDocumentationUploads";
import TaxFullDocumentationDownloadBtns from "../fullDocumentation/taxFullDocumentationDownloadBtns";

const {TabPane} = Tabs;

const TaxActions: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultActiveTab = taxCase.flags.documentationBought ? "2" : "1";

  return (
    <CollapseCard
      title={_t("actions")}
      className="collapse-card-tabs"
      loading={loading}
    >
      <Tabs defaultActiveKey={defaultActiveTab}>
        <TabPane tab={_t("registration_fee")} key="1" className="pb-05">
          <TaxFlagsSwitches setLoading={setLoading}/>
          {taxCase.flags.paid && (
            <PaymentCardDetails
              paymentDate={taxCase.log?.paid?.date}
              paymentPerson={taxCase.log?.paid?.person.fullName}
              paymentId={taxCase.paymentDetails?.paymentId}
              footer={
                <div className="d-flex justify-content-end mt-1">
                  <TaxDownloadInvoiceBtn>
                    {_t("receipt")}
                  </TaxDownloadInvoiceBtn>
                </div>
              }
            />
          )}
          <DividerLine fluid/>
          <TaxInvoiceActions setLoading={setLoading} loading={loading}/>
          <DividerLine fluid/>
          <TaxReceiptActions setLoading={setLoading}/>
        </TabPane>
        <TabPane tab={_t("documentation")} key="2" className="pb-05">
          <TaxDocUploads/>
          <DividerLine fluid/>
          <TaxDocNote/>
          <DividerLine fluid/>
          <TaxDocPrerequisites setLoading={setLoading}/>
          <DividerLine fluid/>
          <TaxDocDownloadBtns/>
          <DividerLine fluid/>
          <TaxDocMotorAgencyStatus/>
        </TabPane>
        <TabPane tab={_t('full', 'documentation')} key="3" className="pb-05">
          <TaxFullDocumentationSupplementaryData/>
          <DividerLine fluid/>
          <TaxFullDocumentationUploads/>
          <DividerLine fluid/>
          <TaxFullDocumentationDownloadBtns/>
        </TabPane>
      </Tabs>
    </CollapseCard>
  );
};

export default TaxActions;
