import React, {FC, useState} from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import {
  priceCheckFileEndpoint,
  suppliedDocumentationAddonFileUrl,
  suppliedDocumentationFileEndpoint,
} from "../../../../services/taxService";
import DocumentsUpload from "../../../../components/documentsUpload";
import {Col, Row, Typography} from "antd";
import UploadFileRender from "../../../../components/uploadFileRender";
import DividerLine from "../../../../components/lineSplit";
import DragUpload from "../../../../components/dragUpload";
import useIsDraggingFiles from "../../../../hooks/useIsDraggingFiles";
import {accepted} from "../../../../utilities/acceptedFiles";
import {UploadFile} from "antd/lib/upload/interface";
import {deleteFile} from "../../../../services/mediaService";
import {renderErrorMessage} from "../../../../components/messages/errorMessage";
import UploadButton from "../../../../components/uploadButton";
import {FullDocumentationType} from "../../../../types/taxTypes";

type SingleFileUploadProps = {
  fileKey: keyof FullDocumentationType;
  action: string;
}
const SingleFileUpload: FC<SingleFileUploadProps> = ({fileKey, action}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDragging] = useIsDraggingFiles();
  const {taxCase, setTaxCase} = TaxCaseStore.useContainer();

  const handleFileDelete = async (file: UploadFile) => {
    const serverId = file.response?.id;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        setLoading(true);
        await deleteFile(action, serverId);
      } catch (error) {
        renderErrorMessage(error)
        canRemove = false;
      } finally {
        setLoading(false);
      }
    }
    if (canRemove) {
      setTaxCase({...taxCase, fullDocumentation: {...taxCase.fullDocumentation, [fileKey]: null}});
    }
  };

  const handleUploadChange = ({file, fileList}: any) => {
    setTaxCase({...taxCase, fullDocumentation: {...taxCase.fullDocumentation, [fileKey]: file}});
  };

  let file;
  if(taxCase.fullDocumentation && taxCase.fullDocumentation[fileKey]) {
    file = taxCase.fullDocumentation[fileKey] as UploadFile;
  } else {
    file = null;
  }

  const props = {
    disabled: !!file || loading,
    fileList: (file ? [file] : []) as Array<any>,
    accept: accepted.join(","),
    action: action,
    onChange: handleUploadChange,
  }

  return (
    <Row>
      <Col span={!!file ? 0 : undefined}>
        <DragUpload
          className={`${isDragging && !props.disabled ? "is-dragging" : ""} file-drop-zone`}
          showUploadList={false}
          {...props}
        >
          <Typography.Title level={3} className="mb-0">
            {_t("msg.drop_her_to_attach")}
          </Typography.Title>
        </DragUpload>
        <UploadButton {...props} />
      </Col>
      {!!file && (
        <Col>
          <UploadFileRender
            onRemove={handleFileDelete}
            hideDownload
            file={file}
            loading={false}/>
          <DividerLine className="mt-05 mb-05"/>
        </Col>
      )}
    </Row>
  )
}

const TaxFullDocumentationUploads: FC = () => {
  const {taxCase, setTaxCase} = TaxCaseStore.useContainer();

  return (
    <>
      <Row justify="space-between" align="middle" className="mb-05">
        <Col>
          <label>{_t('price', "check", '-')}</label>
        </Col>
        <Col>
          <SingleFileUpload
            action={priceCheckFileEndpoint(taxCase.id)}
            fileKey={"priceCheckFile"}
          />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-05">
        <Col>
          <label>{_t("documentation")}</label>
        </Col>
        <Col>
          <SingleFileUpload
            action={suppliedDocumentationFileEndpoint(taxCase.id)}
            fileKey={"suppliedDocumentationFile"}
          />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mb-05">
        <Col>
          <label>{_t('supplement', "documentation", ` ${_t('to')} `)}</label>
        </Col>
        <Col>
          <DocumentsUpload
            fileList={taxCase.fullDocumentation?.suppliedDocumentationAddonFiles || []}
            onChange={({fileList}) => {
              setTaxCase({...taxCase, fullDocumentation: {...taxCase.fullDocumentation, suppliedDocumentationAddonFiles: fileList}});
            }}
            action={suppliedDocumentationAddonFileUrl(taxCase?.id)}
          />
        </Col>
      </Row>
    </>
  );
};

export default TaxFullDocumentationUploads;
