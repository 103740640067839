import { Col, Form, Row } from "antd";
import React, { FC, useEffect, useMemo } from "react";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import DividerLine from "../../../../components/lineSplit";
import format from "../../../../utilities/formatNumbers";
import calculateRegistrationFee from "../calc/regFeeCalculatorIndex"
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { FormInstance } from 'rc-field-form';
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";

interface TaxablePriceInputProps {
}

const TaxablePriceInput: FC<TaxablePriceInputProps> = () => {
  return (
    <>
      <Form.Item shouldUpdate={true} noStyle>
        {(formInstance) => <TaxableValueFields {...formInstance} />}
      </Form.Item>

      <DividerLine />
      <Row className="flex-nowrap">
        <Col flex="auto">
          <Form.Item noStyle shouldUpdate>
            {({getFieldValue}) => {
              const disabled = getFieldValue(["taxablePriceAutoCalculate"])
              return (
              <Form.Item name="taxablePrice" label={_t("taxable_value")}>
                <InputNum disabled={disabled} min={0} suffix="kr." className="bg-white no-border" />
              </Form.Item>
            )}}
          </Form.Item>
        </Col>
        <Col flex="49px" className="text-right">
          <Form.Item noStyle name={["taxablePriceAutoCalculate"]}>
            <AutoCalcSwitch />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TaxablePriceInput;


function TaxableValueFields({ getFieldValue, setFieldsValue }: FormInstance<any>) {
  const { taxCase: { vehicle } } = TaxCaseStore.useContainer()

  const taxablePriceAutoCalculate = getFieldValue(["taxablePriceAutoCalculate"])
  const purchasePrice = getFieldValue(["newPrice", "newPriceBreakdown", "purchasePrice", "purchasePrice"]);
  const salesPrice = getFieldValue(["newPrice", "newPriceBreakdown", "salesPrice", "salesPrice"]);
  const regFee = getFieldValue(["newPrice", "newPriceBreakdown", "salesPrice", "registrationFee"])
  const salesPriceTaxableValue = getFieldValue(["newPrice", "newPriceBreakdown", "salesPrice", "taxableValue"])
  const purchasePriceTaxableValue = getFieldValue(["newPrice", "newPriceBreakdown", "purchasePrice", "taxableValue"])

  const regFeeMemo = useMemo(async () => {
    const res = await calculateRegistrationFee({
      isNew: false,
      batteryCapacity: vehicle?.batteryCapacity,
      electricConsumption: vehicle?.electricConsumption,
      electricRange: vehicle?.electricRange,
      emissionAmount: vehicle?.emissionAmount,
      emissionUnitId: vehicle?.emissionUnitId,
      firstRegDate: vehicle?.firstRegDate,
      isHighWeight: !!vehicle?.isHighWeight,
      taxablePrice: null,
      newPrice: salesPrice,
      tradePrice: salesPrice,
      vehicleTypeId: vehicle?.typeId,
      versionYear: new Date().getFullYear()
    })
    return res
  }, [salesPrice, vehicle])

  useEffect(() => {
    const docalc = async () => {
      const res = await regFeeMemo
      const taxableValue = salesPrice - (res.registrationFee || 0)
      setFieldsValue({ newPrice: { newPriceBreakdown: { salesPrice: { registrationFee: res.registrationFee } } } })
      setFieldsValue({ newPrice: { newPriceBreakdown: { salesPrice: { taxableValue: taxableValue > 0 ? taxableValue : 0} } } })
    }
    docalc()
  }, [salesPrice, setFieldsValue, vehicle])


 useEffect(() => {
  const docalc = () => {
    const res = (purchasePrice || 0) * 1.09 * 1.25
    setFieldsValue({ newPrice: { newPriceBreakdown: { purchasePrice: { taxableValue: res } } } })
  }
  docalc()
}, [purchasePrice, setFieldsValue])

 useEffect(() => {
  if (taxablePriceAutoCalculate && (salesPriceTaxableValue > 0 || purchasePriceTaxableValue > 0)) {
    if (salesPriceTaxableValue > purchasePriceTaxableValue) {
      setFieldsValue({ taxablePrice: salesPriceTaxableValue })
    } else {
      setFieldsValue({ taxablePrice: purchasePriceTaxableValue })
    }

  }
}, [salesPriceTaxableValue, purchasePriceTaxableValue, taxablePriceAutoCalculate])

return (<>
  <Row gutter={[8, 8]} className="mb-05">
    <Col span={8}></Col>
    <Col span={8} className="text-center">
      {_t("purchase_price")} eksl. moms
    </Col>
    <Col span={8} className="text-center">
      {_t("sales_price")} inkl. moms
    </Col>
  </Row>
  <Row gutter={[8, 8]}>
    <Col span={8}>{_t("price")}</Col>
    <Col span={8}>
      <Form.Item wrapperCol={{ span: 24 }} name={["newPrice", "newPriceBreakdown", "purchasePrice", "purchasePrice"]}>
        <InputNum min={0} placeholder={"Indkøbspris eksl. moms"} suffix="kr." />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item wrapperCol={{ span: 24 }} name={["newPrice", "newPriceBreakdown", "salesPrice", "salesPrice"]}>
        <InputNum min={0} placeholder={"Salgspris inkl. moms"} suffix="kr." />
      </Form.Item>
    </Col>
  </Row>

  <Row gutter={[8, 8]}>
    <Col span={8}>{_t("registration_fee")}</Col>
    <Col span={8}>
    </Col>
    <Col span={8}>
      <Form.Item wrapperCol={{ span: 24 }} name={["newPrice", "newPriceBreakdown", "salesPrice", "registrationFee"]}>
        <InputNum disabled min={0} suffix="kr." />
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={[8, 8]} className="mb-05">
    <Col span={8}>{_t("info")}</Col>
    <Col span={8} className="text-center">
      {format.number(purchasePrice)} x 1,09 x 1,25
    </Col>
    <Col span={8} className="text-center">
      {format.number(salesPrice, { maximumFractionDigits: 0 })} &mdash; {format.number(regFee, { maximumFractionDigits: 0 })}
    </Col>
  </Row>
  <Row gutter={[8, 8]}>
    <Col span={8}>{_t("taxable_value")}</Col>
    <Col span={8}>
      <Form.Item wrapperCol={{ span: 24 }} name={["newPrice", "newPriceBreakdown", "purchasePrice", "taxableValue"]}>
        <InputNum disabled min={0} suffix="kr." />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item wrapperCol={{ span: 24 }} name={["newPrice", "newPriceBreakdown", "salesPrice", "taxableValue"]}>
        <InputNum disabled min={0} suffix="kr." />
      </Form.Item>
    </Col>
  </Row>
</>
)
}
