import { useEffect, useRef, useState } from 'react';
import { createContainer } from "unstated-next";
import { FormInstance } from 'rc-field-form';
import { TaxLegalCase, TaxLegalStage, TaxLegalStageData } from '../types/taxLegalTypes';
import { getRandomString } from '../utilities/stringExtraFunctions';

export type Names = keyof TaxLegalStageData; 

type TaxLegalStagesStoreProps = {
  stages: TaxLegalCase['stages'];
  setStages: React.Dispatch<React.SetStateAction<TaxLegalStage[]>>;
  addStage(stage: TaxLegalStage["type"]): void
  deleteStage(fingerprint: Fingerprint): void
  getStagesForms(): Map<Fingerprint, FormsRef>;
  reorderStages(insertionIndex: number, itemIndex: number): void
  registerForm: (formStage: RegisterFormRef) => void;
  unRegisterForm: (fingerprint: Fingerprint) => void;
  resetForms(): void
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>

}
type Fingerprint = string;
type RegisterFormRef = {
  type: TaxLegalStage["type"],
  position: TaxLegalStage['position'],
  formName: Names,
  formInstance: FormInstance,
  fingerprint: Fingerprint
}
type FormsRef = {
  type: TaxLegalStage["type"],
  position: TaxLegalStage['position'],
  data: Partial<Record<Names, FormInstance<TaxLegalStageData[Names]>>>;
  fingerprint: Fingerprint
}
 
/**
 * Stores a ref that is a collection of Ant design FormInstance of each form inside the different stages.
 */
const useTaxLegalStagesStore = (initial?: TaxLegalCase['stages']): TaxLegalStagesStoreProps => {
  const formsRef = useRef<Map<Fingerprint, FormsRef>>(new Map())
  const [stages, setStages] = useState(initial?.length ? initial : getInitialStage())
  const [activeTab, setActiveTab] = useState(stages[stages.length - 1].fingerprint)

  const registerForm = ({ formInstance, formName, position, fingerprint, type }: RegisterFormRef) => {

    if (!formsRef.current) formsRef.current = new Map();

    const formStage: FormsRef = {
      position,
      type,
      fingerprint,
      data: {}
    }
    const prev = formsRef.current.get(fingerprint);

    if (prev) {
      formStage.data = {
        ...prev.data,
      }
    }
    formStage.data[formName] = formInstance
    formsRef.current.set(fingerprint, formStage)
  };
  const unRegisterForm = (fingerprint: Fingerprint) => {
    if (formsRef.current) {
      formsRef.current.delete(fingerprint)
    }
  };

  const getStagesForms = () => {
    return formsRef.current
  };

  function addStage(stage: TaxLegalStage['type']) {
    setStages(prev => {
      prev.push(getNewStage(stage, String(prev.length), {}))
      setActiveTab(prev[prev.length -1].fingerprint);
      return [...prev]
    })

  }
  function deleteStage(fingerprint: Fingerprint) {
    const isActiveTab = activeTab === fingerprint
    const isLastTab = stages[stages.length - 1].fingerprint === fingerprint
    setStages(prev => {
      const newlist = prev
        .filter(stage => stage.fingerprint !== fingerprint)
        .map((stage, i) => { stage.position = i + ""; return stage; });

      if (isActiveTab || (isActiveTab && isLastTab)) {
        setActiveTab(newlist[newlist.length - 1].fingerprint)
      }
      return newlist;
    }
    )

    if (formsRef.current && fingerprint) {
      formsRef.current.delete(fingerprint)
    }
  }

  function reorderStages(insertionIndex: number, itemIndex: number) {

    setStages(prev => {
      const item = prev[itemIndex];
      if (insertionIndex > itemIndex) {
        prev.splice(insertionIndex + 1, 0, item)
      } else {
        prev.splice(insertionIndex, 0, item)
      }

      if (itemIndex > insertionIndex) {
        prev.splice(itemIndex + 1, 1)
      } else {
        prev.splice(itemIndex, 1)
      }
      const newlist = prev.map((stage, i) => ({ ...stage, position: String(i) }))
      return newlist
    })
  }

  function resetForms() {
    const stagesForms = getStagesForms()
    for (const formStage of stagesForms.values()) {
      for (const formInstance of Object.values(formStage.data)) {
        formInstance.resetFields()
      }
    }
  }


  useEffect(() => {
    if (initial && initial.length) {
      setStages(initial.toSorted((a, b) => Number(a.position) - Number(b.position)))
    }
  }, [initial])

  return {
    stages,
    setStages,
    addStage,
    deleteStage,
    reorderStages,
    getStagesForms,
    registerForm,
    resetForms,
    unRegisterForm,
    activeTab,
    setActiveTab
  };
};

const TaxLegalStagesStore = createContainer(useTaxLegalStagesStore);

export default TaxLegalStagesStore

export const useTaxLegalStages = () => TaxLegalStagesStore.useContainer()


function getInitialStage(): TaxLegalCase['stages'] {
  return [getNewStage("price_check", "0", {})]
}

export function getNewStage(type: TaxLegalStage['type'], position: string, data: TaxLegalStage['data']): TaxLegalStage {
  return {
    position: position,
    data: data,
    type: type,
    version: "2024123001",
    fingerprint: getRandomString(2),
    timestamp: new Date().toISOString()
  }
}
