export const pageKey = "page",
  pageSizeKey = "pageSize",
  sortKey = "sort",
  searchKey = "search",
  statusKey = "status",
  reviewerKey = "reviewer",
  urgentKey = "urgent",
  dealerKey = "dealerId",
  caseHandlerKey = "caseHandlerId",
  vinKey = "vin",
  typeKey = "type",
  segmentKey = "segment",
  archivedKey = "archived",
  paid = "paid",
  uploaded = "uploaded",
  isReconciled = "isReconciled",
  repairRequestedKey = "repairRequested";

export function getApiQuery(query?: string | null) {
  if (!query) return "";

  const originalQuery = new URLSearchParams(query);
  const result = new URLSearchParams("");
  originalQuery.forEach((value, key) => {
    switch (key) {
      case pageKey:
        result.set("page", value);
        break;
      case pageSizeKey:
        result.set("per_page", value);
        break;
      case sortKey:
        result.set("sort", value);
        break;
      case searchKey:
        result.set("filter[q]", value);
        break;
      case segmentKey:
        result.set("filter[segment]", value);
        break;
      case statusKey:
        result.set("filter[status]", value);
        break;
      case urgentKey:
        result.set("filter[isUrgent]", value);
        break;
      case archivedKey:
        result.set("filter[isArchived]", value);
        break;
      case dealerKey:
        result.set("filter[dealerId]", value);
        break;
      case vinKey:
        result.set("filter[vin]", value);
        break;
      case typeKey:
        result.set("filter[type]", value);
        break;
      case paid:
        result.set("filter[paid]", value);
        break;
      case uploaded:
        result.set("filter[uploaded]", value);
        break;
      case isReconciled:
        result.set("filter[isReconciled]", value);
        break;
      case repairRequestedKey:
        result.set("filter[repairRequested]", value)
        break;
      case caseHandlerKey:
        result.set("filter["+caseHandlerKey+"]", value)
        break;

      default:
        result.set(key, value);
        break;
    }
  });

  return result.toString();
}

export function convertToAppQuery(query?: string | null) {
  if (!query) return "";

  const originalQuery = new URLSearchParams(query);
  const result = new URLSearchParams("");
  originalQuery.forEach((value, key) => {
    switch (key) {
      case "page":
        result.set(pageKey, value);
        break;
      case "per_page":
        result.set(pageSizeKey, value);
        break;
      case "sort":
        result.set(sortKey, value);
        break;
      case "filter[q]":
        result.set(searchKey, value);
        break;
      case "filter[status]":
        result.set(statusKey, value);
        break;
      case "filter[isUrgent]":
        result.set(urgentKey, value);
        break;
      case "filter[isArchived]":
        result.set(archivedKey, value);
        break;
      case "filter[type]":
        result.set(archivedKey, value);
        break;

      default:
        break;
    }
  });
  return result.toString();
}
