import { Button, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import DateSelect from "../../../../components/form/dateSelect";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updateExpectedDeliveryDate } from "../../../../services/importService";

const ImportExpectedDeliveryDate: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loadingCaseHandler, setLoadingCaseHandler] = useState<boolean>();

  const disabled = importCase.flags.pickupSelf;

  const handleExpectedDeliveryDate = async (values: any) => {
    try {
      setLoadingCaseHandler(true);
      const { data } = await updateExpectedDeliveryDate(importCase.id, values);
      message.success(<SavedMessage />);
      setImportCase(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoadingCaseHandler(false);
    }
  };

  return (
    <Form
      onFinish={handleExpectedDeliveryDate}
      initialValues={{ expectedDeliveryDate: importCase.expectedDeliveryDate }}
      layout="vertical"
    >
      <Row align="bottom" gutter={12} className="flex-wrap mb-1">
        <Col flex="auto">
          <Form.Item name="expectedDeliveryDate" label={_t("expected", "delivery_date")} className="mb-0">
            <DateSelect disabled={disabled} />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loadingCaseHandler} htmlType="submit" type="primary" disabled={disabled}>
            {_t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ImportExpectedDeliveryDate;
