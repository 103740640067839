import { Button, Form, message, Modal } from "antd";
import { FC, useState } from "react";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import {getConflictedCustomer, updateCustomer} from "../../../../services/leasingService";
import {CustomerType} from "../../../../types/customerTypes";
import {LeasingCustomerUpdateType} from "../../../../types/leasingTypes";
import consts from "../../../../utilities/consts";
import {isAxiosError} from "../../../../utilities/typeGuard";
import LeasingUpdateCustomerForm from "./leasingUpdateCustomerForm";

const LeasingUpdateCustomer: FC = () => {
  const {leasingCase, setLeasingCase, isPrivate, isAdmin} = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [systemCustomer, setSystemCustomer] = useState<CustomerType | null>(null);
  const [updateCustomerForm] = Form.useForm<LeasingCustomerUpdateType>();

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const {flags, customer, legalOwner, contactPerson} = leasingCase;
  const {conflictedCustomer, validationStarted, readonly} = flags;

  const handleEditCustomer = async () => {
    if (conflictedCustomer) {
      try {
        setLoading(true);
        const {data} = await getConflictedCustomer(leasingCase.id);
        setSystemCustomer(data);
        openModal();
      } catch (error) {
        renderErrorMessage(error)
      } finally {
        setLoading(false);
      }
    } else {
      openModal();
    }
  };

  const handleSave = async () => {
    const values: LeasingCustomerUpdateType = updateCustomerForm.getFieldsValue(true);
    try {
      setLoading(true);
      const {data} = await updateCustomer(leasingCase.id, values);
      setLeasingCase({...leasingCase, ...data});
      closeModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button disabled={!validationStarted || readonly} loading={loading} onClick={handleEditCustomer}>
        {_t("edit", "customer")}
      </Button>
      <Modal
        confirmLoading={loading}
        width={800}
        visible={isVisible}
        onCancel={closeModal}
        title={_t("edit", "customer")}
        okText={_t(systemCustomer ? "update_customer" : "add_customer")}
        onOk={updateCustomerForm.submit}
      >
        <Form
          {...consts.formItemProps}
          form={updateCustomerForm}
          requiredMark={false}
          onFinish={handleSave}
          initialValues={{legalOwner, contactPerson, customer}}
        >
          <LeasingUpdateCustomerForm
            leasingCase={leasingCase}
            systemCustomer={systemCustomer}
            isPrivate={isPrivate}
            hidePBS={!isAdmin}
            form={updateCustomerForm}
          />
        </Form>
      </Modal>
    </>
  );
};

export default LeasingUpdateCustomer;
