import { Badge, Card, Collapse, Form, Input, Tabs } from "antd";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import DateSelect from "../../../components/form/dateSelect";
import SelectGovernmentHandler from "../../../components/form/selectGovernmentHandler";
import SelectUser from "../../../components/form/selectUser";
import DividerLine from "../../../components/lineSplit";
import { useTaxLegalStages } from "../../../contexts/TaxLegalStagesStore";
import _t from "../../../lang/translate";
import consts from "../../../utilities/consts";
import { getValuesFilledStatus } from "../../../utilities/objectUtil";
import { CaseAttachmentsItem, ResumptionRequestOutcome } from "../shared";
import { Conclusion, HearingOfParties, HearingOfPartiesTabTitle } from "./priceCheck";


const { Panel } = Collapse;

type Props = {
  position: string;
  fingerprint: string
}
export default function Resumption(props:Props) {
  return (
    <Card className="tab-card" title={_t("resumption")}>
      <Tabs destroyInactiveTabPane={false} defaultActiveKey="request">
        <Tabs.TabPane tab={"Anmodning"} key="request">
          <Request {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<HearingOfPartiesTabTitle fingerprint={props.fingerprint}/>} key="hearingOfParties">
          <HearingOfParties {...props}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={_t("conclusion")} key="conclusion">
          <Conclusion {...props}/>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export type RequestForm = {
  submissionDate: string;
  submittedBy: number;

  responseDate:string;
  outcomeType: number;
  newGovernmentCaseNumber: string;
  governmentCaseHandlerId: number;
}

type Names = {
  request: {
    submission: (keyof RequestForm)[],
    reception: (keyof RequestForm)[],
  },
}
const names: Names = {
  request: {
    submission: ["submissionDate", "submittedBy"],
    reception: ["responseDate", "outcomeType", "newGovernmentCaseNumber", "governmentCaseHandlerId"]
  },
}

function Request({position, fingerprint}:Props) {
  const {registerForm, stages} = useTaxLegalStages()
  const initialValues = stages.find(stage => stage.fingerprint === fingerprint)?.data?.request as RequestForm;
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.request, initialValues))
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2']);
  const [form] = Form.useForm<RequestForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "request",
      position,
      type: "resumption"
    })
  }, [position])

  const debouncedCheckValues = useCallback(
    debounce((values: RequestForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.request, values))
    }, 500),
  []);

  function handleValuesChange(changedValues: any, values: RequestForm) {
    debouncedCheckValues(values)
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("submission")}{' '}
            <Badge dot color={allFieldsHaveValues.submission ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >
          <Form.Item label={_t("dateOfSubmission")} name="submissionDate">
            <DateSelect />
          </Form.Item>
          <Form.Item className="fix-select-wrap" label={_t("submitted", "by")}>
            <SelectUser dealerId={1} typeId={1} name="submittedBy" noStyle />
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("resumptionRequest")}>
            <CaseAttachmentsItem itemName="resumptionRequestDocuments"/>
          </Form.Item>
        </Panel>
        <Panel header={
          <CustomCollapseHeader>
            2. {_t("reception")}{' '}
            <Badge dot color={allFieldsHaveValues.reception ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="2" >
          <Form.Item label={_t("responseDate")} name="responseDate">
            <DateSelect />
          </Form.Item>
          <Form.Item label={_t("outcome")} name="outcomeType">
            <ResumptionRequestOutcome/>
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("newCaseNumber")} name="newGovernmentCaseNumber">
            <Input />
          </Form.Item>
          <Form.Item className="fix-select-wrap" label={_t("MS", "caseHandler")} name="governmentCaseHandlerId">
            <SelectGovernmentHandler addModalExtraValues={{typeId: "motor_registry"}}/>
          </Form.Item>

          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("document", "external_brackets")}>
            <CaseAttachmentsItem itemName="externalDocuments"/>
          </Form.Item>
        </Panel>
      </CustomCollapse>
    </Form>
  )
}
