import { Button, Checkbox, Col, Divider, Form, Input, Popconfirm, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { FC, useState } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { Activity, ActivityEntryType } from "../../types/activityTypes";
import { compare } from "../../utilities/deepCompare";
import ActivityItemBody from "./activityItemBody";
import ActivityItemInfo from "./activityItemInfo";

interface ActivityItemEditProps {
  onUpdate: (values: ActivityEntryType) => void;
  loading: boolean;
  item: Activity;
  onCancel: () => void;
  isAdmin?: boolean;
  editing: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const ActivityItemEdit: FC<ActivityItemEditProps> = ({
  onUpdate,
  loading,
  item,
  isAdmin,
  onEdit,
  onDelete,
  editing,
  onCancel,
}) => {
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const isNote = item.type.id === 1;
  const handleCancel = () => {
    setSaveDisabled(true);
    onCancel();
  };
  const updateDisableSave = (_: any, values: ActivityEntryType) => {
    const isMatched = compare(values, item);

    if (isMatched !== saveDisabled) setSaveDisabled(isMatched);
  };
  return (
    <div className="position-relative drawer-item">
      <ActivityItemInfo {...{ item, isAdmin }} />
      {editing ? (
        <Form<ActivityEntryType> onFinish={onUpdate} onValuesChange={updateDisableSave} initialValues={item}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name={isNote ? "note" : "text"} noStyle>
            <TextArea
              disabled={loading}
              className="noresize-text-area"
              placeholder={_t(isNote ? "note" : "message")}
              maxLength={2048}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          </Form.Item>
          <div className="text-right">
            <small className="muted">{_t("maximum _short", "letters", " 2048 ")}</small>
          </div>
          <Space>
            <Form.Item name="isImportant" noStyle valuePropName="checked">
              <Checkbox>{_t("urgent")}</Checkbox>
            </Form.Item>
            <Form.Item name="isPublic" noStyle valuePropName="checked">
              <Checkbox>{_t("publish")}</Checkbox>
            </Form.Item>
          </Space>
          <Row align="middle" gutter={10}>
            <Col flex="auto">
              <Divider />
            </Col>
            <Col>
              <Space>
                <Button onClick={handleCancel} disabled={loading}>
                  {_t("cancel")}
                </Button>
                <Button htmlType="submit" loading={loading} type="primary" disabled={saveDisabled}>
                  {_t("save")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ) : (
        <ActivityItemBody item={item} />
      )}
      {!editing && (
        <div
          className="drawer-item-actions position-absolute top-0 right-0 d-flex flex-column p-05 border-radius bg-muted">
          <Button
            className="muted"
            type="link"
            size="small"
            shape="circle"
            icon={<Icon name="pencil-outline" />}
            onClick={onEdit}
          />
          <Divider className="mt-05 mb-05" />
          <Popconfirm
            placement="topLeft"
            onConfirm={onDelete}
            icon={<Icon fill="red" name="information-circle-outline" />}
            title={_t("msg.confirm_delete")}
          >
            <Button
              className="muted hover-color-red"
              type="link"
              size="small"
              shape="circle"
              icon={<Icon name="trash-outline" />}
            />
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default ActivityItemEdit;
