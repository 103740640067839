import React, { FC } from "react";
import { Typography } from "antd";
import Details from "../../../components/detailsList";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import ImportCaseStore from "../../../contexts/importCaseStore";

const { Link } = Typography;

const ImportVehicleInfo: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { brand, model, firstRegDate, equipmentVariant, body, vin, mileage, link } = importCase.vehicle || {};

  const formatValue = (v?: string | null) => (v && v?.trim() ? v : "-");
  return (
    <>
      <Details borderLess className="m-0">
        {link && (
          <Details.Item
            value={
              <Link ellipsis className="max-width-240" href={link} target="_blank">
                {link}
              </Link>
            }
            className="mb-1"
          />
        )}
        <Details.Item label={_t("brand")} value={formatValue(brand)} />
        <Details.Item label={_t("model")} value={formatValue(model)} />
        <Details.Item label={_t("equipment_variant")} value={formatValue(equipmentVariant)} />
        <Details.Item label={_t("firstRegDate")} value={format.date(firstRegDate)} />
        <Details.Item label={_t("vin")} value={formatValue(vin)} />
        <Details.Item label={_t("mileage")} value={format.milage(mileage)}/>
        <Details.Item label={_t("trim")} value={formatValue(body)} className="mb-1" />
      </Details>
    </>
  );
};

export default ImportVehicleInfo;
