import { Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CollapseCard from "../../../../components/collapseCard";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import LeasingStore from "../../../../contexts/leasingStore";
import UserStore from "../../../../contexts/userStore";
import _t from "../../../../lang/translate";
import { isNew } from "../../../../routes/appRoutes";
import { getAdministration } from "../../../../services/leasingService";
import { ParamTypes } from "../../../../types/appTypes";
import LeasingAdminContract from "./leasingAdminContract";
import LeasingAdminDeal from "./leasingAdminDeal";
import LeasingAdminMotorAgency from "./leasingAdminMotorAgency";
import LeasingAdminValidation from "./leasingAdminValidation";
import LeasingKeyNumbers from "./leasingKeyNumbers";

const { TabPane } = Tabs;

const LeasingAdminCard: FC = () => {
  const { id } = useParams<ParamTypes>();
  const { hasPermission } = UserStore.useContainer();
  const { leasingCase, setAdminValues } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const canSeekeyNumbers = hasPermission(leasingCase.partnerCase ? "partner_leasing.keys_value" : "leasing.keys_value");

  useEffect(() => {
    const fetchAdminValues = async () => {
      try {
        setLoading(true);
        const { data } = await getAdministration(Number(id));
        setAdminValues(data);
      } catch (error) {
        renderErrorMessage(error)
      } finally {
        setLoading(false);
      }
    };
    !isNew(id) && !loading && fetchAdminValues();
  }, [id, setAdminValues]);

  return (
    <CollapseCard
      title={_t("administration")}
      loading={loading}
      className="collapse-card-tabs"
      defaultOpen={leasingCase.flags.validationStarted}
    >
      <Tabs defaultActiveKey={"1"} destroyInactiveTabPane={canSeekeyNumbers}>
        <TabPane tab={_t("validation")} key="1" className="pb-05">
          <LeasingAdminValidation />
        </TabPane>
        {!leasingCase.partnerCase && (
          <TabPane tab={_t("contract")} key="2" className="pb-05">
            <LeasingAdminContract />
          </TabPane>
        )}
        <TabPane tab={_t("deal")} key="3" className="pb-05" forceRender>
          <LeasingAdminDeal />
        </TabPane>
        <TabPane tab={_t("DMR", "info")} key="4" className="pb-05" forceRender>
          <LeasingAdminMotorAgency />
        </TabPane>
        {canSeekeyNumbers && (
          <TabPane tab={_t("key_number")} key="5" className="pb-05">
            <LeasingKeyNumbers />
          </TabPane>
        )}
      </Tabs>
    </CollapseCard>
  );
};

export default LeasingAdminCard;
