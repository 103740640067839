import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../assets/icons/icon";
import SavedMessage from "../../../components/messages/savedMessage";
import ImportCaseStore from "../../../contexts/importCaseStore";
import _t from "../../../lang/translate";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import {AxiosResponse} from "axios";

interface Props {
  label: string,
  callable: (id: number) => Promise<AxiosResponse<{url: string}>>
}

const DownloadBtn: FC<Props> = ({label, callable}) => {
  const { importCase } = ImportCaseStore.useContainer();
  const { requested } = importCase.flags;
  const [loading, setLoading] = useState<boolean>();

  const handleDownload = async () => {
    try {
      setLoading(true);
      const { data } = await callable(importCase.id);
      if (data.url) window.open(data.url, "_blank");
      message.success(<SavedMessage message={"OK"} />);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={handleDownload} loading={loading} type="primary" ghost disabled={!requested}>
      <Icon name="download-outline" size="large" />
      {label}
    </Button>
  );
};

export default DownloadBtn;
