import { message } from "antd";
import { FC, useState } from "react";
import SwitchInput from "../../../../components/form/switchInput";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updateFullService } from "../../../../services/importService";

const ImportFullServiceToggle: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>();

  const toggle = async (checked: boolean, _event: MouseEvent) => {
    try {
      setLoading(true);
      const { data } = await updateFullService(importCase.id, { status: checked });
      message.success(_t('saved'));
      setImportCase(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (<>
    <SwitchInput
      label={_t('full_service')}
      id="import_full_service"
      checked={!!importCase.flags.fullService}
      loading={loading}
      onChange={(checked, _event) => toggle(checked, _event)}
    />
  </>
  )
};

export default ImportFullServiceToggle;
