import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { IdType, RemoteVehicleData } from "../types/appTypes";

const apiEndpoint = `${getApiEndpoint()}/misc/vinDetails`;


export function getVinDetails(vin: string) {
  return http.get(`${apiEndpoint}/${vin}`);
}

// Route::post('{vin}/getRemoteData'
type getRemoteDataConfig = {
  items: ("dmr" | "dat")[];
  forceFresh?: boolean;
}
export function getRemoteData(vin: string, data: getRemoteDataConfig) {
  return http.post<{ data: RemoteVehicleData }>(`${getApiEndpoint()}/vin/${vin}/getRemoteData`, data);
}

/**
 * POST '/v1/vehicleValuation/{vin}/getValuationValue' 
  - it expects the body to be {dataPoint: “salesPrice”, firstRegDate: string, mileage: number}
 */
export function getValuationValue(vin:string, data: {dataPoint: "salesPrice", firstRegDate: string, mileage: number}) {
  return http.post<{data:number}>(`${getApiEndpoint()}/vehicleValuation/${vin}/getValuationValue`, data)
} 