import _t from "../lang/translate";
import { ChangeClientType, GetStatusesType, IdType, StatusesType, StatusUpdateType, Vehicle } from "../types/appTypes";
import { CaseStatusUpdate } from "../types/casesCommonTypes";
import {
  CloseImportCaseType,
  CompleteImportCaseType,
  DeliveryAddress,
  ImportCaseCreationType,
  ImportCaseHandlerType,
  ImportCaseType,
  ImportOrderType,
  ImportVehicleReport,
  PickupDocumentFormType,
  SendImportOfferFormType,
  settingsKeys,
  TransportDocumentFormType,
  UpdateMerchantFormType,
  VehicleComingHome,
} from "../types/importTypes";
import { ToLeasingFormType } from "../types/leasingTypes";
import getApiEndpoint from "./apiEndpoint";
import { geStatus } from "./enumService";
import http from "./httpService";
import { mediaApiEndpoint } from "./mediaService";
import { getApiQuery } from "./urlQueryService";
import { CloneTaxCaseType } from "../types/taxTypes";

const apiEndpoint = `${getApiEndpoint()}/importCase`;

export function importCaseUrl(id: IdType | string) {
  return `${apiEndpoint}/${id}`;
}

export function importCasesUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function importFileUrl(id?: IdType) {
  return id ? `${importCaseUrl(id)}/file` : mediaApiEndpoint;
}

export function createImportCase(values: ImportCaseCreationType) {
  return http.post<ImportCaseCreationType>(apiEndpoint, values);
}

export function saveImportCase(values: ImportCaseType) {
  return http.put<ImportCaseType>(importCaseUrl(values.id), values);
}

export function deleteImportCase(id: IdType, doRefund: boolean) {
  const refundReason = doRefund ? 5 : -1;
  const url = `${importCaseUrl(id)}?refundReason=${refundReason}`;
  return http.delete(url);
}

export function importActivitiesUrl(id: IdType) {
  return `${importCaseUrl(id)}/activities`;
}

export function saveImportActivity(id: IdType, data: any) {
  return http.post(importActivitiesUrl(id), data);
}

export function updateImportActivity(id: IdType, data: any) {
  return http.put(`${importActivitiesUrl(id)}/${data?.id}`, data);
}

export function deleteImportActivity(id: IdType, deletedId: string) {
  return http.delete(`${importActivitiesUrl(id)}/${deletedId}`);
}

export function validateMerchant(id: IdType) {
  return http.put(`${importCaseUrl(id)}/validateMerchant`);
}

export function validateDealer(id: IdType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/validateDealer`);
}

export function updateImportVehicle(id: IdType, data: Vehicle) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateVehicle`, data);
}

export function updateImportClient(id: IdType, data: ChangeClientType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/client`, data);
}

export function updateImportMerchant(id: IdType, data: UpdateMerchantFormType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateMerchant`, data);
}

export function updateImportReportOffer(id: IdType, data: ImportVehicleReport) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateReportOffer`, data);
}

export function updateImportPayment(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updatePayment`, data);
}

export function updateImportVehicleComingHome(id: IdType, data: VehicleComingHome) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateVehicleComingHome`, data);
}

export function updateImportPublished(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/published`, data);
}

export function updateImportDocumentation(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateDocumentation`, data);
}

export function saveImportCaseStatus(id: IdType, data: CaseStatusUpdate) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateStatus`, data);
}

export function downloadAllFiles(id: IdType) {
  return http.get(`${importCaseUrl(id)}/downloadAllFiles`);
}

export function downloadImportReport<T>(id: IdType) {
  return http.get<T>(`${importCaseUrl(id)}/downloadReport`);
}

export function acceptImportOffer(id: IdType, data: ImportOrderType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/acceptOffer`, data);
}

export function unAcceptImportOffer(id: IdType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/unAcceptOffer`);
}

export function sendImportOffer(id: IdType, data?: SendImportOfferFormType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/sendOffer`, data);
}

export function closeImportCase<T>(id: IdType, data: CloseImportCaseType) {
  return http.post<T>(`${importCaseUrl(id)}/close`, data);
}

export function completeImportCase(id: IdType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/complete`);
}

export function saveSettings(id: IdType, key: string, data: any) {
  if (key === settingsKeys.vehicle) return updateImportVehicle(id, data);
  if (key === settingsKeys.merchant) return updateImportMerchant(id, data);
  if (key === settingsKeys.offer) return updateImportReportOffer(id, data);
  if (key === "changeClientForm") return updateImportClient(id, data);

  throw new Error("Unhandled form type" + key);
}

export function autoFetchMobileDe(id: IdType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/attemptAutoDataFetch`);
}

export function autoFetchDatDe(id: IdType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/attemptAutoReportFetch`);
}

export function cloneImportCase(id: IdType) {
  return http.post<{ id: number }>(`${importCaseUrl(id)}/toImportCase`);
}

export function createLeasingofImport(id: IdType, data: ToLeasingFormType) {
  return http.post(`${importCaseUrl(id)}/toLeasingCase`, data);
}

export function createTaxofImport(id: IdType, data: CloneTaxCaseType) {
  return http.post(`${importCaseUrl(id)}/toTaxCase`, data);
}

export function updateDeliveryAddress<T>(id: IdType, data: DeliveryAddress) {
  return http.put<T>(`${importCaseUrl(id)}/updateDeliveryAddress`, data);
}

export function updateInvoiceAddress<T>(id: IdType, data: DeliveryAddress) {
  return http.put<T>(`${importCaseUrl(id)}/updateInvoiceAddress`, data);
}

export function updateCaseHandler(id: IdType, data: ImportCaseHandlerType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateCaseHandler`, data);
}

// Buyer relation:

export function updatePurchaseContractSigned(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updatePurchaseContractSigned`, data);
}

export function updateEntryCertificateSigned(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateEntryCertificateSigned`, data);
}

export function updateTakeHomeAgreementSigned(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateTakeHomeAgreementSigned`, data);
}

export function updatePaid(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updatePaid`, data);
}

export function downloadPurchaseContract(id: IdType) {
  return http.get<{ url: string }>(`${importCaseUrl(id)}/downloadPurchaseContract`);
}

export function downloadEntryCertificate(id: IdType) {
  return http.get<{ url: string }>(`${importCaseUrl(id)}/downloadEntryCertificate`);
}

export function downloadTakeHomeAgreement(id: IdType) {
  return http.get<{ url: string }>(`${importCaseUrl(id)}/downloadTakeHomeAgreement`);
}

export function downloadPaymentAdvice(id: IdType) {
  return http.get<{ url: string }>(`${importCaseUrl(id)}/downloadPaymentAdvice`);
}

// Merchant relation:

export function updateVehiclePurchaseContractRequested(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateVehiclePurchaseContractRequested`, data);
}

export function updateVehiclePurchaseContractSigned(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateVehiclePurchaseContractSigned`, data);
}

export function updateVehiclePaid(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateVehiclePaid`, data);
}

export function updateRegistrationCertificate1Received(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateRegistrationCertificate1Received`, data);
}

export function updateRegistrationCertificate2Received(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateRegistrationCertificate2Received`, data);
}

export function updateCertificateOfConformityReceived(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateCertificateOfConformityReceived`, data);
}

export function updateOriginalInvoiceReceived(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateOriginalInvoiceReceived`, data);
}

// Transport:

export function updateTransportOrdered(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateTransportOrdered`, data);
}

export function updatePickupSelf(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updatePickupSelf`, data);
}

export function updateProblematic(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateProblematic`, data);
}

export function updateExpectedDeliveryDate(id: IdType, data: CompleteImportCaseType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateExpectedDeliveryDate`, data);
}

export function downloadCollectionAuthorization<T>(id: IdType, data: TransportDocumentFormType) {
  return http.put<T>(`${importCaseUrl(id)}/downloadCollectionAuthorization`, data);
}

export function downloadPickupAgreement<T>(id: IdType, data: PickupDocumentFormType) {
  return http.put<T>(`${importCaseUrl(id)}/downloadPickupAgreement`, data);
}

export function orderPickup(id: IdType, data: PickupDocumentFormType) {
  return http.post<ImportCaseType>(`${importCaseUrl(id)}/orderCarTransport`, data);
}

export function updateFullService<T>(id: IdType, data: StatusUpdateType) {
  return http.put<ImportCaseType>(`${importCaseUrl(id)}/updateFullService`, data);
}

// Filter statuses on table page:

export const getImportStatuses: GetStatusesType = () => {
  let results: StatusesType = {
    open: { id: -1, title: _t("open") },
  };
  const completed = geStatus(27);
  if (completed) results = { ...results, completed };
  const rejected = geStatus(13);
  if (rejected) results = { ...results, rejected };
  return results;
};
