import { Button, message, Popconfirm, Result } from "antd";
import { FC, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import { renderErrorMessage } from "../components/messages/errorMessage";
import StatusTabs from "../components/statusTabs";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import useActiveStatus from "../hooks/useActiveStatus";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { deletePost, getPostStatuses, postsUrl } from "../services/postService";
import { pageKey, pageSizeKey, statusKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { IdType, LocationStateType, ScreenProps } from "../types/appTypes";
import { PostsType, PostType } from "../types/postTypes";
import format from "../utilities/formatNumbers";

const Posts: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const { hasPermission } = UserStore.useContainer();
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<PostsType>>("", { data: [] });
  const isAdmin = hasPermission("post.create");
  const statuses = getPostStatuses();
  const [activeStatusId, setActiveStatusId] = useActiveStatus(Object.values(statuses));

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (activeStatusId !== -1) {
      const key = Object.keys(statuses).find((k) => statuses[k].id === activeStatusId);
      key && query.set(key, "1");
    }
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    setUrl(postsUrl(query.toString()));
  }, [setUrl, location.search, activeStatusId, refreshId, statuses]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deletePost(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      renderErrorMessage(ex, _t("msg.not_deleted"));
    }
  };

  const date: CustomColumnsType<PostType> = isAdmin
    ? [
      {
        title: _t("date"),
        key: "createdAt",
        dataIndex: "createdAt",
        sorter: true,
        width: 130,
        render: (date) => format.date(date),
      },
    ]
    : [
      {
        title: _t("publish_date"),
        key: "date",
        dataIndex: "date",
        hidden: false,
        render: (date) => format.date(date),
      },
    ];

  const publishDate: CustomColumnsType<PostType> = isAdmin
    ? [
      {
        title: _t("publish_date"),
        key: "date",
        dataIndex: "date",
        hidden: true,
        render: (date) => format.date(date),
      },
    ]
    : [];

  const columns: CustomColumnsType<PostsType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => (
        <>
          {record.isUrgent && <Icon name="alert-outline" color="red" size="large" className="priority-icon-table" />}
          {hasPermission("screen.post") ? (
            <Link to={getPath("post", record.id)}>
              <strong>{text}</strong>
            </Link>
          ) : (
            text
          )}
        </>
      ),
      fixed: "left",
    },
    ...date,
    {
      title: _t("title"),
      key: "title",
      dataIndex: "title",
      sorter: true,
      ellipsis: true,
    },
    {
      title: _t("content"),
      key: "content",
      dataIndex: "content",
      sorter: true,
      ellipsis: true,
      render: (content) => {
        const strippedString = content.replace(/(<([^>]+)>)/gi, "");
        return strippedString;
      },
    },
    ...publishDate,
  ];

  const tableActions: RenderFunc<PostsType> = (_, record) => {
    return hasPermission("post.delete") ? (
      <Popconfirm
        placement="topLeft"
        onConfirm={() => handleDelete(record.id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>
              {record.id} : {record.title}
            </strong>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    ) : null;
  };

  const handleTabChange = (nextKey?: string) => {
    const query = new URLSearchParams(location.search);
    query.delete(pageKey);
    query.delete(statusKey);
    setActiveStatusId(nextKey || "-1");
    history.push({ ...history.location, search: query.toString() });
  };

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  return (
    <>
      <PageTitle
        fluid
        headerMargin={false}
        title={title}
        extra={
          hasPermission("post.create") && (
            <Button key="1" type="primary">
              <Link to={getPath("post", "new")}>{_t("new_post")}</Link>
            </Button>
          )
        }
      >
        <StatusTabs
          statuses={Object.values(statuses)}
          onTabChange={handleTabChange}
          activeTabId={`${activeStatusId}`}
        />
      </PageTitle>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<PostsType>
          onRefresh={handleRefresh}
          loading={isLoading}
          renderActions={tableActions}
          columnStorageKey={"NEWS"}
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      )}
    </>
  );
};

export default Posts;
