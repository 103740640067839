import { Badge, Card, Col, Collapse, Dropdown, Form, Input, Menu, Row, Space, Tabs } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Icon from "../../../assets/icons/icon";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import DateSelect from "../../../components/form/dateSelect";
import InputNum from "../../../components/form/inputNum";
import InputPrice from "../../../components/form/inputPrice";
import SelectGovernmentHandler from "../../../components/form/selectGovernmentHandler";
import SelectUser from "../../../components/form/selectUser";
import DividerLine from "../../../components/lineSplit";
import LoadingContainer from "../../../components/loadingContainer";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import { useTaxLegalEvaluation } from "../../../contexts/taxLegalCaseStore";
import { useTaxLegalStages } from "../../../contexts/TaxLegalStagesStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { getGovernmentCaseHandler } from "../../../services/taxLegalService";
import { IdType } from "../../../types/appTypes";
import consts from "../../../utilities/consts";
import { getValuesFilledStatus } from "../../../utilities/objectUtil";
import { AnalysisTags, CaseAttachmentsItem, GovernmentAssessmentSelect, LackOfComplianceTags, OutcomeTypeSelect } from "../shared";
import { getEnumTitleById, useEnums } from "../../../services/enumService";

const { Panel } = Collapse;

type Props = {
  position: string;
  fingerprint: string
}

export default function PriceCheck(props: Props) {
  return (
    <Card className="tab-card" title={"Pristjek"}>
      <Tabs destroyInactiveTabPane={false} defaultActiveKey="Materiale">
        <Tabs.TabPane tab={"Materiale"} key="Materiale">
          <Materials {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={ <HearingOfPartiesTabTitle fingerprint={props.fingerprint} /> } key="Partshøring">
          <HearingOfParties {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Afgørelse"} key="Afgørelse">
          <Conclusion {...props} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export function HearingOfPartiesTabTitle({fingerprint}:{fingerprint: string}) {

  const { getStagesForms, stages } = useTaxLegalStages()

  const grabValue = <T extends {}, K extends keyof T >(key: K, obj: T, fallback: T): T[K] | undefined => {
    if (obj && key in obj && obj[key] !== undefined) {
      return obj[key]
    }

    return fallback ? fallback[key] : undefined
  }

  const mapValues = (
    conclusion: ConclusionForm, 
    fallbackConclusion: ConclusionForm, 
    hearingOfParties: HearingOfPartiesForm, 
    fallbackHearingOfParties: HearingOfPartiesForm,
  ): Partial<ConclusionForm> => {
    
    return {
      conclusionDate: grabValue("conclusionDate", conclusion, fallbackConclusion),
      deadlineForComplaint: grabValue("deadlineForComplaint", conclusion, fallbackConclusion),
      internalNote: grabValue("internalNote", conclusion, fallbackConclusion),
      responsibilityEvaluationType: grabValue("responsibilityEvaluationType", conclusion, fallbackConclusion),
      outcomeType: grabValue("outcomeType", conclusion, fallbackConclusion) ?? grabValue("outcomeType", hearingOfParties, fallbackHearingOfParties),
      lackOfComplianceTags: grabValue("lackOfComplianceTags", conclusion, fallbackConclusion) ??  grabValue("lackOfComplianceTags", hearingOfParties, fallbackHearingOfParties),
      newPrice: grabValue("newPrice", conclusion, fallbackConclusion) ??  grabValue("newPrice", hearingOfParties, fallbackHearingOfParties),
      equipmentsPrice: grabValue("equipmentsPrice", conclusion, fallbackConclusion) ??  grabValue("equipmentsPrice", hearingOfParties, fallbackHearingOfParties),
      tradePrice: grabValue("tradePrice", conclusion, fallbackConclusion) ??  grabValue("tradePrice", hearingOfParties, fallbackHearingOfParties),
      registrationFee: grabValue("registrationFee", conclusion, fallbackConclusion) ??  grabValue("registrationFee", hearingOfParties, fallbackHearingOfParties),
      regulationAmount: grabValue("regulationAmount", conclusion, fallbackConclusion) ??  grabValue("regulationAmount", hearingOfParties, fallbackHearingOfParties),
      governmentNewPriceAssessment: grabValue("governmentNewPriceAssessment", conclusion, fallbackConclusion) ??  grabValue("governmentNewPriceAssessment", hearingOfParties, fallbackHearingOfParties),
      governmentTradePriceAssessment: grabValue("governmentTradePriceAssessment", conclusion, fallbackConclusion) ??  grabValue("governmentTradePriceAssessment", hearingOfParties, fallbackHearingOfParties),

    }
  }

  function handleCopy() {
    const initialStage = stages.find(stage => stage.fingerprint === fingerprint)?.data
    // Use initialStage as fallback for when the form elements haven not been rendered, and therefore not been added as a form element.
    const stagesForms = getStagesForms()
    const currentStage = stagesForms.get(fingerprint)
    const hearingOfParties = currentStage?.data.hearingOfParties?.getFieldsValue(true) as HearingOfPartiesForm | undefined
    const hearingOfPartiesFallback = initialStage?.hearingOfParties
    const conclusionForm = currentStage?.data.conclusion
    const conclusion = conclusionForm?.getFieldsValue() as ConclusionForm | undefined
    const conclusionFallback = initialStage?.conclusion
    // @ts-expect-error form objects can be undefined
    const newConclusion = mapValues(conclusion, conclusionFallback, hearingOfParties, hearingOfPartiesFallback)
    conclusionForm?.setFieldsValue(newConclusion) 
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleCopy}>{_t("copyToConclusion")}</Menu.Item>
    </Menu>
  );

  return (
    <Space>
      <span>{_t("hearingOfParties")}</span>
      <span onClick={(e) => e.stopPropagation()}>
        <Dropdown overlay={menu}>
          <Icon size="large" name="ellipsis-vertical-outline" />
        </Dropdown>
      </span>
    </Space>
  )

}

export type MaterialsForm = {
  caseStartAt: string;
  deadlineForDocumentation: string;
  governmentCaseNumber: string;

  submissionDate: string;
  submittedBy: number;
  expectedConclusion: string;
  internalNote: string;
  analysisTags: string[];
}


type Names = {
  materials: {
    reception: (keyof MaterialsForm)[]
    processing: (keyof MaterialsForm)[]
  },
  hearingOfParties: {
    reception: (keyof HearingOfPartiesForm)[]
    processing: (keyof HearingOfPartiesForm)[]
  },
  conclusion: {
    reception: (keyof ConclusionForm)[]
    processing: (keyof ConclusionForm)[]
  },
}
export const names: Names = {
  materials: {
    reception: ['caseStartAt', 'deadlineForDocumentation', 'governmentCaseNumber'],
    processing: ['submissionDate', 'submittedBy', 'expectedConclusion', "analysisTags", 'internalNote'],
  },
  hearingOfParties: {
    reception: ["dateForReception", "deadlineForNotation", "outcomeType", "responsibilityEvaluationType", "regulationAmount", "newPrice", "equipmentsPrice", "tradePrice", "registrationFee", "governmentNewPriceAssessment", "governmentTradePriceAssessment"],
    processing: ["submissionDate", ],
  },
  conclusion: {
    reception: ["conclusionDate", "deadlineForComplaint", "regulationAmount", "newPrice", "tradePrice", "equipmentsPrice", "lackOfComplianceTags", "outcomeType", "responsibilityEvaluationType", "registrationFee", "governmentNewPriceAssessment", "governmentTradePriceAssessment"],
    processing: ["internalNote"]
  },
}

function Materials({ position, fingerprint }: Props) {
  const { registerForm, stages } = useTaxLegalStages()
  const initialValues = stages.find(stage => stage.fingerprint === fingerprint)?.data?.materials as MaterialsForm;
  const { user } = UserStore.useContainer()
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2']);
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.materials, initialValues))
  const [form] = Form.useForm<MaterialsForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "materials",
      position,
      type: "price_check"
    })
  }, [position])

  const debouncedCheckValues = useCallback(
    debounce((values: MaterialsForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.materials, values))
    }, 500),
    []);

  function handleValuesChange(val: Partial<MaterialsForm>, values: MaterialsForm) {
    debouncedCheckValues(values)
    if (val.caseStartAt) {
      const deadlineForDocumentation = moment(val.caseStartAt).add(14, 'days')
      form.setFieldsValue({ deadlineForDocumentation: deadlineForDocumentation.toISOString() })
    }
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("reception")}{' '}
            <Badge dot color={allFieldsHaveValues.reception ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >
          <Form.Item label={_t("caseStart")} name="caseStartAt">
            <DateSelect />
          </Form.Item>
          <Form.Item label={_t("deadlineForDocumentation")} name="deadlineForDocumentation">
            <DateSelect />
          </Form.Item>
          <Form.Item label={_t("ms.caseNumber")} name="governmentCaseNumber">
            <Input />
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("materialsSummons")}>
            <CaseAttachmentsItem itemName="materialsSummonsDocuments" />
          </Form.Item>

        </Panel>
        <Panel header={
          <CustomCollapseHeader>
            2. {_t("processing")}{' '}
            <Badge dot color={allFieldsHaveValues.processing ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="2" >
          <Form.Item label={_t("dateOfSubmission")} name="submissionDate">
            <DateSelect />
          </Form.Item>
          {user?.dealer.id && (
            <Form.Item className="fix-select-wrap" label={_t("submitted", "by")}>
              <SelectUser dealerId={user.dealer.id} typeId={1} name="submittedBy" noStyle />
            </Form.Item>
          )}
          <Form.Item label={_t("expectation", "forConclusion")} name="expectedConclusion">
            <OutcomeTypeSelect />
          </Form.Item>
          <AnalysisTags />
          <Form.Item label={_t("internalNote")} name="internalNote">
            <Input.TextArea />
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />
          <Form.Item label={_t("documentationForEvaluation")}>
            <CaseAttachmentsItem itemName="documentationForEvaluationDocuments" />
          </Form.Item>
        </Panel>
      </CustomCollapse>
    </Form>
  )
}

export type HearingOfPartiesForm = {
  dateForReception: string;
  deadlineForNotation: string;
  outcomeType: number;
  regulationAmount: number;
  governmentNewPriceAssessment: number;
  governmentTradePriceAssessment: number;
  responsibilityEvaluationType: number;
  lackOfComplianceTags: string[];
  newPrice: number;
  equipmentsPrice: number;
  tradePrice: number;
  registrationFee: number;
  submissionDate: string;
}


export function HearingOfParties({ position, fingerprint }: Props) {
  const [enums] = useEnums()
  const { registerForm, stages } = useTaxLegalStages()
  const stage = stages.find(stage => stage.fingerprint === fingerprint)
  const initialValues = stage?.data?.hearingOfParties as HearingOfPartiesForm;
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.hearingOfParties, initialValues))
  const evaluation = useTaxLegalEvaluation();
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2']);
  const [form] = Form.useForm<HearingOfPartiesForm>()


  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "hearingOfParties",
      position,
      type: "price_check"
    })
  }, [position])

  function handleValuesChange(val: Partial<HearingOfPartiesForm>, values: HearingOfPartiesForm) {

    debouncedCheckValues(values)

    if (val.dateForReception) {
      const deadlineForNotation = moment(val.dateForReception).add(14, 'days')
      form.setFieldsValue({ deadlineForNotation: deadlineForNotation.toISOString() })
    }
    if ("registrationFee" in val) {
      const diff = (val.registrationFee || 0) - (evaluation?.registrationFee || 0 )
      form.setFieldsValue({regulationAmount: diff})
    }
    if (val.outcomeType && getEnumTitleById(val.outcomeType, ["taxLegalCase", "outcomeType"]) === "Godkendt") {
      const OK_ID = enums?.taxLegalCase.governmentAssessment.find(item => item.title === "OK")?.id
      if (OK_ID) {
        form.setFieldsValue({governmentNewPriceAssessment: OK_ID, governmentTradePriceAssessment: OK_ID})
      }
    }
  }


  const debouncedCheckValues = useCallback(
    debounce((values: HearingOfPartiesForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.hearingOfParties, values))
    }, 500),
    []);

  return (
    <Form form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("reception")}{' '}
            <Badge dot color={allFieldsHaveValues.reception ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >

          <Form.Item label={_t("dateFor", "reception")} name="dateForReception">
            <DateSelect />
          </Form.Item>

          <Form.Item label={_t("deadlineForNotation")} name="deadlineForNotation">
            <DateSelect />
          </Form.Item>


          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("theCase'", "outcome")} name="outcomeType" >
            <OutcomeTypeSelect />
          </Form.Item>

          <LackOfComplianceTags />

          <DividerLine style={{ marginBlock: 12 }} />


          <Form.Item name="newPrice" label={_t("MS", "new_price")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="equipmentsPrice" label={_t("MS", "equipment")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="tradePrice" label={_t("MS", "trade_price")}>
            <InputNum suffix={"kr."} />
          </Form.Item>

          <Form.Item name="registrationFee" label={_t("MS", "registration_fee")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item label={_t("regulation")} name="regulationAmount">
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item label={_t("MSAssessment")}>
            <Row gutter={8} className="flex-nowrap">
              <Col flex={"50%"}>
                <Form.Item style={{margin: 0}} name="governmentNewPriceAssessment">
                  <GovernmentAssessmentSelect nameSpace="NP"/>
                </Form.Item>
              </Col>
              <Col flex={"50%"}>
                <Form.Item style={{margin: 0}} name="governmentTradePriceAssessment">
                  <GovernmentAssessmentSelect nameSpace="HP"/>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("proposal", "forConclusion")}>
            <CaseAttachmentsItem itemName="proposalForConclusionDocuments" />
          </Form.Item>
        </Panel>

        <Panel header={
          <CustomCollapseHeader>
            2. {_t("processing")}{' '}
            <Badge dot color={allFieldsHaveValues.processing ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="2" >
          <Form.Item label={_t("dateOfSubmission")} name="submissionDate">
            <DateSelect />
          </Form.Item>


          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("notesForProposal")}>
            <CaseAttachmentsItem itemName="notesForProposalDocuments" />
          </Form.Item>
        </Panel>
      </CustomCollapse>
    </Form>
  )
}


export type ConclusionForm = {
  conclusionDate: string;
  deadlineForComplaint: string;
  governmentCaseHandlerId: number;
  governmentCaseHandlerEmail: string;
  governmentCaseHandlerPhone: string;
  outcomeType: number;
  newPrice: number;
  equipmentsPrice: number;
  tradePrice: number;
  registrationFee: number;
  regulationAmount: number;
  governmentNewPriceAssessment: number;
  governmentTradePriceAssessment: number;
  responsibilityEvaluationType: number;
  lackOfComplianceTags: string[];
  internalNote: string;
}


export function Conclusion({ position, fingerprint }: Props) {
  const [enums] = useEnums()
  const { registerForm, stages } = useTaxLegalStages()
  const stage = stages.find(stage => stage.fingerprint === fingerprint)
  const initialValues = stage?.data?.conclusion as ConclusionForm;
  const evaluation = useTaxLegalEvaluation();
  const [loadingGovernmentCaseHandler, setLoadingGovernmentCaseHandler] = useState(false)
  const [allFieldsHaveValues, setAllFieldsHaveValues] = useState(getValuesFilledStatus(names.conclusion, initialValues))
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2']);
  const [form] = Form.useForm<ConclusionForm>()

  useEffect(() => {
    registerForm({
      fingerprint,
      formInstance: form,
      formName: "conclusion",
      position,
      type: "price_check"
    })
  }, [position])

  async function getAndSetGovernmentCaseHandler(id: IdType) {
    try {
      setLoadingGovernmentCaseHandler(true)
      const { data } = await getGovernmentCaseHandler(id)
      form.setFieldsValue({
        governmentCaseHandlerId: data.id,
        governmentCaseHandlerEmail: data.email,
        governmentCaseHandlerPhone: data.phone
      })
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoadingGovernmentCaseHandler(false)
    }
  }

  function handleValuesChange(val: Partial<ConclusionForm>, values: ConclusionForm) {
    if (val.governmentCaseHandlerId) {
      getAndSetGovernmentCaseHandler(val.governmentCaseHandlerId)
    }
    debouncedCheckValues(values)
    if (val.conclusionDate) {
      const deadlineForComplaint = moment(val.conclusionDate).add(3, 'months')
      form.setFieldsValue({ deadlineForComplaint: deadlineForComplaint.toISOString() })
    }
    if ("registrationFee" in val) {
      const diff = (val.registrationFee || 0) - (evaluation?.registrationFee || 0 )
      form.setFieldsValue({regulationAmount: diff})
    }
    if (val.outcomeType && getEnumTitleById(val.outcomeType, ["taxLegalCase", "outcomeType"]) === "Godkendt") {
      const OK_ID = enums?.taxLegalCase.governmentAssessment.find(item => item.title === "OK")?.id
      if (OK_ID) {
        form.setFieldsValue({governmentNewPriceAssessment: OK_ID, governmentTradePriceAssessment: OK_ID})
      }
    }
  }

  const debouncedCheckValues = useCallback(
    debounce((values: ConclusionForm) => {
      setAllFieldsHaveValues(getValuesFilledStatus(names.conclusion, values))
    }, 500),
    []);

  return (
    <Form form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...consts.formItemProps}
    >
      <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
        <Panel header={
          <CustomCollapseHeader>
            1. {_t("reception")}{' '}
            <Badge dot color={allFieldsHaveValues.reception ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="1" >

          <Form.Item label={_t("conclusionDate")} name="conclusionDate">
            <DateSelect />
          </Form.Item>

          <Form.Item label={_t("deadlineForComplaint")} name="deadlineForComplaint">
            <DateSelect />
          </Form.Item>

          <LoadingContainer showLogo loading={loadingGovernmentCaseHandler} >
            <Form.Item className="fix-select-wrap" label={_t("MS", "caseHandler")} name="governmentCaseHandlerId">
              <SelectGovernmentHandler addModalExtraValues={{ typeId: "motor_registry" }} onItemAdded={(_, e) => getAndSetGovernmentCaseHandler(e as number)} />
            </Form.Item>

            <Form.Item label={_t("email")} name="governmentCaseHandlerEmail">
              <Input type="email" />
            </Form.Item>

            <Form.Item label={_t("phone")} name="governmentCaseHandlerPhone">
              <Input type="phone" />
            </Form.Item>

          </LoadingContainer>

          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("theCase'", "outcome")} name="outcomeType" >
            <OutcomeTypeSelect />
          </Form.Item>


          <LackOfComplianceTags />

          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item name="newPrice" label={_t("MS", "new_price")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="equipmentsPrice" label={_t("MS", "equipment")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item name="tradePrice" label={_t("MS", "trade_price")}>
            <InputNum suffix={"kr."} />
          </Form.Item>

          <Form.Item name="registrationFee" label={_t("MS", "registration_fee")}>
            <InputPrice suffix={"kr."} />
          </Form.Item>


          <Form.Item label={_t("regulation")} name="regulationAmount">
            <InputPrice suffix={"kr."} />
          </Form.Item>

          <Form.Item label={_t("MSAssessment")}>
            <Row gutter={8} className="flex-nowrap">
              <Col flex={"50%"}>
                <Form.Item style={{margin: 0}} name="governmentNewPriceAssessment">
                  <GovernmentAssessmentSelect nameSpace="NP"/>
                </Form.Item>
              </Col>
              <Col flex={"50%"}>
                <Form.Item style={{margin: 0}} name="governmentTradePriceAssessment">
                  <GovernmentAssessmentSelect nameSpace="HP"/>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <DividerLine style={{ marginBlock: 12 }} />

          <Form.Item label={_t("conclusion")}>
            <CaseAttachmentsItem itemName="conclusionDocuments" />
          </Form.Item>
        </Panel>

        <Panel header={
          <CustomCollapseHeader>
            2. {_t("processing")}{' '}
            <Badge dot color={allFieldsHaveValues.processing ? "green" : "orange"} />
          </CustomCollapseHeader>
        } key="2" >

          <Form.Item label={_t("internalNote")} name="internalNote">
            <Input.TextArea />
          </Form.Item>

        </Panel>
      </CustomCollapse>
    </Form>
  )
}
