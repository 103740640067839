import React, {FC} from "react";
import {updateProblematic} from "../../../../services/importService";
import _t from "../../../../lang/translate";
import StatusFlagToggle from "./statusFlagToggle";

const ImportOtherActions: FC = () => {
  return (
    <>
      <StatusFlagToggle
        flag={"problematic"}
        callable={updateProblematic}
        label={_t("problematic", "case")}
      />
    </>
  );
};

export default ImportOtherActions;
