import { getSavedEnums } from "../services/authService";
import { Vehicle } from "../types/appTypes";


export function mapDMREmissionUnit(fuelTypeCode: string | null | undefined): Pick<Vehicle, "emissionUnit" | "emissionUnitId"> | undefined {
    const enums = getSavedEnums()?.vehicle.emissionUnit;
    if (!enums) {
        return undefined;
    }
    let emissionUnitEnum;
    switch (fuelTypeCode) {
        case "NEDC": emissionUnitEnum = enums.find(item => item.id === 1); break;
        case "WLTP": emissionUnitEnum = enums.find(item => item.id === 2); break;
        case "NEDC2": emissionUnitEnum = enums.find(item => item.id === 3); break;
        case "NEDC-2": emissionUnitEnum = enums.find(item => item.id === 3); break;
        default: emissionUnitEnum = enums.find(item => item.id === 3); break;
    };
    return {
        emissionUnit: emissionUnitEnum?.title,
        emissionUnitId: emissionUnitEnum?.id
    }
}

export function mapDMRFuelCode(fuelType: string | null | undefined): Pick<Vehicle, "fuel" | "fuelId"> | undefined {
    const enums = getSavedEnums()?.vehicle.fuel;
    if (!enums) {
        return undefined;
    }
    let fuelEnum;
    switch (fuelType) {
        case "Benzin": fuelEnum = enums.find(item => item.id === 1); break; // Benzin;
        case "Diesel": fuelEnum = enums.find(item => item.id === 2); break; // Diesel;
        case "El": fuelEnum = enums.find(item => item.id === 4); break; // Benzin;
        default: return;
    };

    return {
        fuel: fuelEnum?.title,
        fuelId: fuelEnum?.id
    }
}

export function mapDMRBodyType(body: string | null | undefined): Pick<Vehicle, "body" | "bodyId"> | undefined {
    const enums = getSavedEnums()?.vehicle.body;
    if (!enums) {
        return undefined;
    }
    let bodyEnum;
    switch (body) {
        case "Sedan": bodyEnum = enums.find(item => item.id === 1); break; // Cabriolet
        case "Stationcar": bodyEnum = enums.find(item => item.id === 2); break; // Stationcar
        case "Cabriolet": bodyEnum = enums.find(item => item.id === 3); break; // This is a guess, i could not find any examples
        case "Coupe": bodyEnum = enums.find(item => item.id === 4); break; // Coupe
        case "SUV": bodyEnum = enums.find(item => item.id === 5); break; // SUV
        case "MPV": bodyEnum = enums.find(item => item.id === 5); break; // SUV --> TODO. Every time DMR says MPV or TAX-Team inputs MPV.
        case "Kassevogn": bodyEnum = enums.find(item => item.id === 6); break; // This is a guess, i could not find any examples
        case "Hatchback": bodyEnum = enums.find(item => item.id === 9); break; // Hatchback
        default: return;
    };
    return {
        body: bodyEnum?.title,
        bodyId: bodyEnum?.id
    }
}

export function mapDMRGearBox(automatic?: boolean): Pick<Vehicle, 'gearbox' | "gearboxId"> | undefined {
    const enums = getSavedEnums()?.vehicle.gearbox;
    if (!enums || automatic == undefined) {
        return undefined;
    }
    let gearboxEnum;
    if (automatic) {
        gearboxEnum = enums.find(item => item.id === 2);
    } else {
        gearboxEnum = enums.find(item => item.id === 1);
    }
    return {
        gearbox: gearboxEnum?.title,
        gearboxId: gearboxEnum?.id
    }
}


export const isDMREmissionEqual = (vehicle: Vehicle) => {
    return vehicle.remoteData?.dmr?.mapped?.emissionAmount === vehicle.emissionAmount
}
export const isDMREmssionUnitEqual = (vehicle: Vehicle) => {
    const dmrRaw = vehicle.remoteData?.dmr?.raw;
    let dmrUnit: unknown = "";
    const emissionUnitEnums = getSavedEnums()?.vehicle.emissionUnit
    const totalCalculatedCo2 = dmrRaw?.dmr?.totalCalculatedCo2;
    let isDerived = false;
    if (totalCalculatedCo2 || totalCalculatedCo2 === '0' || totalCalculatedCo2 === 0) {
        dmrUnit = dmrRaw?.dmr?.norm;
        isDerived = dmrUnit == null;
    } else if (dmrRaw?.emissions?.co2) {
        dmrUnit = dmrRaw?.emissions?.norm;
        isDerived = true;
    } else {
        dmrUnit = emissionUnitEnums?.find(unit => unit.id === 2)?.title // WLTP
        isDerived = true;
    }

    // find the dmr enum equivalent.
    const dmrUnitId = emissionUnitEnums?.find(unit => unit.title === String(dmrUnit).toUpperCase())?.id

    return {
        isEqual: vehicle.emissionUnitId === dmrUnitId,
        isDerived,
    }
}
export const isDMRBatteryCapacityEqual = (vehicle: Vehicle) => {
    return vehicle.remoteData?.dmr?.mapped?.batteryCapacity === vehicle.batteryCapacity
}
export const isDMRElectricConsumptionEqual = (vehicle: Vehicle) => {
    return vehicle.remoteData?.dmr?.mapped?.electricConsumption === vehicle.electricConsumption
}
export const isDMRElectricRangeEqual = (vehicle: Vehicle) => {
    return vehicle.remoteData?.dmr?.mapped?.electricRange === vehicle.electricRange
}
