import { Button, message, Popconfirm, Result } from "antd";
import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import { renderErrorMessage } from "../components/messages/errorMessage";
import StatusTabs from "../components/statusTabs";
import StatusTag from "../components/statusTag";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import useActiveStatus from "../hooks/useActiveStatus";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { pageKey, pageSizeKey, searchKey, statusKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { deleteUser, getUserStatuses, usersUrl } from "../services/userService";
import { PaginationedData } from "../types/apiTypes";
import { IdType, LocationStateType, ScreenProps } from "../types/appTypes";
import { UserType } from "../types/userTypes";

const Users: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const { hasPermission } = UserStore.useContainer();
  const statuses = getUserStatuses();
  const [activeStatusId, setActiveStatusId] = useActiveStatus(Object.values(statuses));

  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<UserType>>("", { data: [] });

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (activeStatusId !== -1) {
      query.set(statusKey, activeStatusId.toString());
    }
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = usersUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, activeStatusId, refreshId, delayedSetUrl]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteUser(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      renderErrorMessage(ex, _t("msg.not_deleted"))
    }
  };

  const columns: CustomColumnsType<UserType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) =>
        hasPermission("screen.user") ? (
          <Link to={getPath("user", record.id)}>
            <strong>{text}</strong>
          </Link>
        ) : (
          text
        ),
      fixed: "left",
    },
    {
      title: _t("name"),
      key: "name",
      sorter: true,
      ellipsis: true,
      dataIndex: "name",
    },
    {
      title: _t("phone"),
      key: "phone",
      sorter: true,
      dataIndex: "phone",
      width: 160,
    },
    {
      title: _t("email"),
      key: "email",
      sorter: true,
      dataIndex: "email",
    },
    {
      title: _t("role"),
      key: "role",
      dataIndex: "role",
      hidden: true,
      render: (role) => role.title,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      dataIndex: "status",
      width: 120,
      render: (status) => status && <StatusTag status={status} size="small" />,
    },
    {
      title: _t("initials"),
      key: "initials",
      dataIndex: "initials",
      sorter: true,
      width: 100,
    },
  ];

  const tableActions: RenderFunc<UserType> = (_, record) => {
    return hasPermission("user.delete") ? (
      <Popconfirm
        placement="topLeft"
        onConfirm={() => handleDelete(record.id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>
              {record.id} : {record.name}
            </strong>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    ) : null;
  };

  const handleTabChange = (nextKey?: string) => {
    const query = new URLSearchParams(location.search);
    query.delete(pageKey);
    query.delete(statusKey);
    setActiveStatusId(nextKey || "-1");
    history.push({ ...history.location, search: query.toString() });
  };

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  return (
    <>
      <PageTitle
        fluid
        headerMargin={false}
        title={title}
        extra={
          hasPermission("user.create") && (
            <Button key="1" type="primary">
              <Link to={getPath("user", "new")}>{_t("new_user")}</Link>
            </Button>
          )
        }
      >
        <StatusTabs
          statuses={Object.values(statuses)}
          onTabChange={handleTabChange}
          activeTabId={`${activeStatusId}`}
        />
      </PageTitle>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<UserType>
          loading={isLoading}
          onRefresh={handleRefresh}
          renderActions={tableActions}
          columnStorageKey="USER"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      )}
    </>
  );
};

export default Users;
