export function findTagTitleFromId(tagId: number | string) {
    const tag = tags.find(tag => tag.id === Number(tagId))
    if (tag) return tag.title;
    return null;
}

const tags = [
    {
        "title": "Basispris",
        "id": 1,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Udstyr",
        "id": 2,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Bilinfo",
        "id": 25,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "DAT.de",
        "id": 26,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Estimatic",
        "id": 27,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Sydfyns MC",
        "id": 28,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Sk\u00f8n",
        "id": 29,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Faktura",
        "id": 30,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Prisliste",
        "id": 31,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Genberegnet",
        "id": 32,
        "category_id": "tax_legal_price_source"
    },
    {
        "title": "Faktisk",
        "id": 33,
        "category_id": "tax_legal_price_source"
    }
]