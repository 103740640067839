import { ModalContentProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import AcceptLeasingCase from "./acceptLeasingCase";
import AddContactPerson from "./addContactPerson";
import AddMerchantModal from "./addMerchant";
import AddUserModal from "./addUserModal";
import BuyTaxDoc from "./buyTaxDoc";
import ChangeClient from "./changeClient";
import ChangeStatus from "./changeStatus";
import CloneTaxCase from "./cloneTaxCase";
import ExportCustomers from "./exportCustomers";
import ExportDealers from "./exportDealers";
import ExportDealersStats from "./exportDealersStats";
import ImportCollectionAuthorization from "./importCollectionAuthorization";
import ImportPickupAgreement from "./importPickupAgreement";
import ImportPickupOrder from "./importPickupOrder";
import LeasingInitClient from "./leasingInitClient";
import LeasingSendToPartner from "./leasingSendToPartner";
import ModalBillingDestination from "./modalBillingDestination";
import ModalDepartments from "./modalDepartment";
import NewOfferLeasing from "./newOfferLeasing";
import StartDigitalSigning from "./startDigitalSigning";
import RejectImportCase from "./rejectImportCase";
import RejectLeasingCase from "./rejectLeasingCase";
import RejectTaxDoc from "./rejectTaxDoc";
import ReviewModal from "./reviewModal";
import SendCreditPortalLink from "./sendCreditPortalLink";
import TaxToLeasingCase from "./taxToLeasingCase";
import UpdateDeliveryAddress from "./updateDeliveryAddress";
import UpdateInvoiceAddress from "./updateInvoiceAddress";
import ImportToTaxCase from "./importToTaxCase";
import UpdateResellerTaxCustomerInfo from "./updateResellerTaxCustomerInfo";
import ResellerTaxToTaxCase from "./resellerTaxToTaxCase";
import AddGovernmentCaseHandler from "./addGovernmentCaseHandler";
import AddTag from "./addTag";

export default function ModalContent<T extends object>({
  type,
  ...rest
}: ModalContentProps<T>) {
  switch (type) {
    case "addMerchant":
      return <AddMerchantModal<T> {...rest} />;

    case "addUser":
      return <AddUserModal<T> {...rest} />;

    case "addMerchantContact":
      return <AddContactPerson<T> {...rest} />;

    case "addDepartments":
      return <ModalDepartments<T> {...rest} />;

    case "addBillingDestination":
      return <ModalBillingDestination<T> {...rest} />;

    case "updateDeliveryAddress":
      return <UpdateDeliveryAddress<T> {...rest} />;

    case "updateInvoiceAddress":
      return <UpdateInvoiceAddress<T> {...rest} />;

    case "updateResellerTaxCustomerInfo":
      return <UpdateResellerTaxCustomerInfo<T> {...rest} />;

    case "addLeasingOffer":
      return <NewOfferLeasing<T> {...rest} />;

    case "ImportCollectionAuthorization":
      return <ImportCollectionAuthorization<T> {...rest} />;

    case "ImportPickupAgreement":
      return <ImportPickupAgreement<T> {...rest} />;

    case "ImportPickupOrder":
      return <ImportPickupOrder<T> {...rest} />;

    case "resellerTaxToTaxCase":
      return <ResellerTaxToTaxCase<T> {...rest} />;

    case "ImportRejectCase":
      return <RejectImportCase<T> {...rest} />;

    case "review":
      return <ReviewModal<T> {...rest} />;

    case "leasingRejectCase":
      return <RejectLeasingCase<T> {...rest} />;

    case "leasingAcceptCase":
      return <AcceptLeasingCase<T> {...rest} />;

    case "leasingSendToPartner":
      return <LeasingSendToPartner<T> {...rest} />;

    case "taxToLeasingCase":
      return <TaxToLeasingCase<T> {...rest} />;

    case "importToTaxCase":
      return <ImportToTaxCase<T> {...rest} />;

    case "cloneTaxCase":
      return <CloneTaxCase<T> {...rest} />;

    case "buyTaxDoc":
      return <BuyTaxDoc<T> {...rest} />;

    case "rejectTaxDoc":
      return <RejectTaxDoc<T> {...rest} />;

    case "exportCustomers":
      return <ExportCustomers<T> {...rest} />;

    case "startDigitalSigning":
      return <StartDigitalSigning<T> {...rest} />;

    case "sendCreditPortalLink":
      return <SendCreditPortalLink<T> {...rest} />;

    case "exportDealers":
      return <ExportDealers<T> {...rest} />;

    case "leasingInitClient":
      return <LeasingInitClient<T> {...rest} />;

    case "changeClient":
      return <ChangeClient<T> {...rest} />;

    case "changeStatus":
      return <ChangeStatus<T> {...rest} />;

    case "exportDealersStats":
      return <ExportDealersStats<T> {...rest} />;

    case "addGovernmentCaseHandler":
      return <AddGovernmentCaseHandler<T> {...rest} />;

    case "addTag":
      return <AddTag<T> {...rest} />;

    default:
      throw Error("Unhandled modal type" + type);
  }
}

assertFC(ModalContent);
