import { SelectProps } from "antd";
import { OptionData, OptionGroupData } from "rc-select/lib/interface";
import { FC, useState } from "react";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import ServerSelectBox from "../../../components/serverSelectBox";
import { customerContactPersonsAutoComplete } from "../../../services/autocompleteService";
import { getContactPerson } from "../../../services/customerService";
import { ContactPersonType, initContactPerson } from "../../../types/appTypes";
import { isNum } from "../../../utilities/typeGuard";

interface ContactPersonSelectProps extends SelectProps<[]> {
  customerId: number;
  onFetched: (contactPerson: ContactPersonType) => void;
}

const ContactPersonSelectField: FC<ContactPersonSelectProps> = ({
  customerId,
  onFetched,
  onSelect,
  loading: propLoading,
  disabled,
  ...rest
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelection = async (value: never, option: OptionData | OptionGroupData) => {
    if (value === 0) {
      onFetched(initContactPerson);
    }
    if (isNum(value)) {
      try {
        setLoading(true);
        const { data } = await getContactPerson(customerId, value);
        onFetched(data);
      } catch (error) {
        renderErrorMessage(error)
      } finally {
        setLoading(false);
      }
    }
    onSelect && onSelect(value, option);
  };

  return (
    <ServerSelectBox
      allowClear
      loading={loading || propLoading}
      disabled={loading || disabled}
      apiUrl={customerContactPersonsAutoComplete()}
      filters={`filter[customerId]=${customerId}`}
      onSelect={handleSelection}
      {...rest}
    />
  );
};

export default ContactPersonSelectField;
