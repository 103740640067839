import { Button, ButtonProps, Card, Carousel, Space, Statistic, StatisticProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { getCreditCount } from "../../../services/authService";
import { useHistory } from "react-router-dom";
// import posterImage from "../../../assets/images/video_cover_2.jpg";
// import importIllustration from '../../../assets/images/illustration-import.png';
import taxIllustration from '../../../assets/images/illustration-tax.png';
import _t from "../../../lang/translate";
import { Settings as SlickSettings } from '@ant-design/react-slick';
import { getPath } from "../../../routes/appRoutes";
import { IconNames } from "../../../assets/icons/icon-list";
import Icon from "../../../assets/icons/icon";
import format from "../../../utilities/formatNumbers";
import { calculateDeviation } from "../tax/calc/metaCalculators";
import { getStatiticsPersonal } from "../../../services/statisticsService";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import { PersonalStatistic, PersonalStatisticContext, PersonalStatisticItem } from "../../../types/statisticsType";
import { isValidNum } from "../../../utilities/typeGuard";

export default function DashboardCardCarousel() {
    const history = useHistory()
    const items = [];
    const credits = getCreditCount();
    const [data, setData] = useState<null | PersonalStatistic>(null);
    const totalCreatedCases = getTotalOfPersonalStatistic(data, "created");
    const acceptanceRate = getAcceptanceRate(data);

    useEffect(() => {
        async function getAndSetData() {
            try {
                const { data } = await getStatiticsPersonal()
                setData(data.data)
            } catch (error) {
                renderErrorMessage(error)
            }
        }
        getAndSetData();
    }, [])

    items.push(
        <BasicStatistic
            title={_t("cases", "created")}
            suffix={<span className="text-small text-secondary">0-30 dage</span>}
            growth={{
                currentValue: totalCreatedCases.thisPeriod,
                previousValue: totalCreatedCases.previousPeriod,
                suffix: <span className="ml-03 text-tiny">31-60 dage</span>
            }}
            precision={0}
            icon="file-plus"
        />
    );
    items.push(
        <BasicStatistic
            title={"Accept-rate"}
            growth={{
                currentValue: acceptanceRate.thisPeriod,
                previousValue: acceptanceRate.previousPeriod,
                suffix: <span className="ml-03 text-tiny">31-60 dage</span>
            }}
            suffix={<span className="text-small text-secondary">0-30 dage</span>}
            icon="checkmark-circle-outline"
            formatType="percentage"
        />
    )

    items.push(
        <BasicStatistic
            title="Antal klip tilbage"
            growth={{ currentValue: credits }}
            precision={0}
            suffix="Klip"
            icon="coupon-2-line"
        />
    )
    items.push(
        <CTACard
            imgSrc={taxIllustration}
            label="Gå til tax"
            title="Tax"
            buttonProps={{
                children: "Opret sag",
                type: "primary",
                onClick: () => history.push(getPath("tax", "new"))
            }}
        />
    )

    const slickSettings: SlickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnDotsHover: true,
        infinite: true,
        draggable: true,
        touchMove: true,
        touchThreshold: 30,
    }

    let gridTemplateColumns = ""; // fallback when slider is not applied due to not enough children to trigger it.
    switch (items.length) {
        case 4: gridTemplateColumns = "grid-cols-4"; break;
        case 3: gridTemplateColumns = "grid-cols-3"; break;
        case 2: gridTemplateColumns = "grid-cols-2"; break;
        case 1: gridTemplateColumns = "grid-cols-1"; break;
        default: gridTemplateColumns = "";
    }

    return (
        <Carousel
            className={`dashboard-slider ${gridTemplateColumns}`}
            {...slickSettings}
            responsive={[
                {
                    breakpoint: 9999,
                    settings: items.length > 4 ? {
                        ...slickSettings,
                        slidesToShow: 4,
                    }
                        : "unslick"
                },
                {
                    breakpoint: 1720,
                    settings: items.length > 3
                        ? {
                            ...slickSettings,
                            slidesToShow: 3,
                        }
                        : "unslick"
                },
                {
                    breakpoint: 1224,
                    settings: items.length > 2
                        ? {
                            ...slickSettings,
                            slidesToShow: 2,
                        }
                        : "unslick"
                },
                {
                    breakpoint: 600,
                    settings: items.length > 1
                        ? {
                            ...slickSettings,
                            slidesToShow: 1,
                        }
                        : "unslick"
                },
            ]}
        >
            {items.map((element, index) => {
                // this (React.cloneElement) is just to insert the key attribute
                return React.cloneElement(element, { key: index });
            })}
        </Carousel>
    )
}


type BasicStatisticProps = {
    growth: {
        currentValue: number;
        previousValue?: number;
        suffix?: React.ReactNode;
    };
    icon?: IconNames;
    formatType?: "number" | "percentage"
} & Pick<StatisticProps, "precision" | "prefix" | "suffix" | "title">;

function BasicStatistic({ growth, icon, precision, prefix, suffix, title, formatType }: BasicStatisticProps) {
    return (
        <Card bordered={false}>
            <Space size={"middle"}>
                {icon && (
                    <div style={{ backgroundColor: "#95A5AE33", color: "#95A5AE", width: 80, height: 80, borderRadius: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon size="mega" name={icon} />
                    </div>
                )}
                <div>
                    <Statistic
                        className="dashboard-statistic mb-05"
                        title={title}
                        value={growth.currentValue}
                        prefix={prefix}
                        suffix={suffix}
                        formatter={(value) => {
                            if (formatType === 'percentage') {
                                return format.percent(value)
                            }
                            return format.number(value, { maximumFractionDigits: precision, minimumFractionDigits: precision })
                        }}
                    />
                    <GrowthTag {...growth} />
                </div>
            </Space>
        </Card>
    )
}

function GrowthTag({ currentValue, previousValue, suffix }: BasicStatisticProps['growth']) {
    const showGrowth = !!previousValue
    const deviation = calculateDeviation(currentValue, previousValue || 0);
    const growthPercent = formatGrowthPercent(deviation);
    const growthColors = deviation > 0
        ? "text-blue bg-light-primary"
        : deviation < 0
            ? "text-danger bg-danger-ligth"
            : "text-secondary bg-muted";

    return showGrowth ? (
        <div className={`d-inline-block pt-05 pb-05 pl-1 pr-1 text-medium border-rounded-full text-nowrap ${growthColors}`}>
            <Icon size="large"
                name="grown-up"
                style={{
                    transform: deviation < 0 ? "rotateX(180deg)" : "rotateX(0deg)",
                    marginRight: 8
                }}
            />
            {growthPercent} {suffix}
        </div>
    ) : null
}

function formatGrowthPercent(percent: number) {
    let rounded = Math.round(100 * percent);
    const prefix = percent > 0
        ? "+ "
        : ""; // "-" automatically added as number
    return prefix + rounded + "%";
}

// type ListStatisticProps = {
//     title: string;
//     list: {
//         label: string;
//         tagValue: number;
//     }[];
//     icon?: IconNames;
// }

// function ListStatistic({ list, title, icon }: ListStatisticProps) {
//     return (
//         <Card bordered={false}>
//             <Space size={"middle"}>
//                 {icon && (
//                     <div style={{ backgroundColor: "#95A5AE33", color: "#95A5AE", width: 80, height: 80, borderRadius: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
//                         <Icon size="mega" name={icon} />
//                     </div>
//                 )}
//                 <div className="text-dark2">
//                     <p className="text-smaller">{title}</p>
//                     {list.map((item, index) => {
//                         return (
//                             <div key={item.label + index} className="d-flex align-items-center">
//                                 <span className=" mr-05 flex-none">{index + 1}.</span>
//                                 <span className=" text-16 text-semibold mr-03 flex-none">{item.label}</span>
//                                 <span className={`d-inline-block flex-none pl-03 pr-03 text-smallest border-rounded-full text-blue bg-light-primary`}>
//                                     {formatGrowthPercent(item.tagValue)}
//                                 </span>
//                             </div>
//                         )
//                     })}

//                 </div>
//             </Space>
//         </Card>
//     )
// }

type CTACardProps = {
    label: string;
    title: string;
    imgSrc: string;
    buttonProps: ButtonProps;
}

function CTACard({ label, title, buttonProps, imgSrc }: CTACardProps) {
    return (
        <Card bordered={false}>
            <Space size={"middle"}>
                <img style={{ width: 110, height: "auto", maxHeight: 110 }} src={imgSrc} alt="" />
                <div className="text-dark2">
                    <p className="text-smaller mb-0">{label}</p>
                    <p className="text-16 text-semibold mb-03">{title}</p>
                    <Button {...buttonProps} />
                </div>
            </Space>
        </Card>
    )
}

// function PlayVideoModalButtonCard() {
//     const [showIntroModal, setShowIntroModal] = useState<boolean>(false);

//     const handleClick = () => {
//         setShowIntroModal(true);
//     }

//     const closeIntroModal = () => {
//         setShowIntroModal(false);
//     }

//     return (
//         <>
//             <div onClick={handleClick} className="dashboard-card-video">
//                 <div className="dashboard-card-video-inner">
//                     <span className="play-btn-outer">
//                         <span className="play-btn-inner">
//                             <Icon size="huge" className="text-blue -mr-4px" name="play" />
//                         </span>
//                     </span>
//                     <div>
//                         <p className="mb-0 text-white text-sm">
//                             Velkommen til!
//                         </p>
//                         <h2 className="mb-0 text-white">
//                             Sådan gør du
//                         </h2>
//                     </div>
//                 </div>
//                 <div className="overlay-gradient"></div>
//                 <div className="overlay-image ">
//                     <img className="img" alt="Video preview" src={posterImage} />
//                 </div>
//             </div>
//             <Modal
//                 width={800}
//                 visible={showIntroModal}
//                 onCancel={closeIntroModal}
//                 destroyOnClose
//                 footer={null}
//                 title={_t("hey_welcome")}
//             >
//                 <video autoPlay={true} controls className="w-100">
//                     <source type="video/mp4" src={process.env.REACT_APP_INTRO_VIDEO} />
//                 </video>
//             </Modal>
//         </>
//     )
// }

function getTotalOfPersonalStatistic(data: null | PersonalStatistic, key: keyof PersonalStatisticContext) {
    let thisPeriodSum = 0;
    let previousPeriodSum = 0;
    const setDayAmount = (day: PersonalStatisticItem, index: number) => {
        if (isValidNum(day.amount)) {
            if (index < 30) {
                previousPeriodSum += day.amount;
            } else {
                thisPeriodSum += day.amount;
            }
        }
    }
    data?.import[key].forEach((day, index) => setDayAmount(day, index))
    data?.leasing[key].forEach((day, index) => setDayAmount(day, index))
    data?.tax[key].forEach((day, index) => setDayAmount(day, index))

    return {
        thisPeriod: thisPeriodSum,
        previousPeriod: previousPeriodSum
    }
}

function getAcceptanceRate(data: null | PersonalStatistic) {
    const totalCreated = getTotalOfPersonalStatistic(data, "created")
    const totalAccepted = getTotalOfPersonalStatistic(data, "accepted")

    const thisPeriodAcceptanceRate = totalAccepted.thisPeriod / totalCreated.thisPeriod;
    const previousPeriodAcceptanceRate = totalAccepted.previousPeriod / totalCreated.previousPeriod;
    return {
        thisPeriod: isValidNum(thisPeriodAcceptanceRate) ? thisPeriodAcceptanceRate : 0,
        previousPeriod: isValidNum(previousPeriodAcceptanceRate) ? previousPeriodAcceptanceRate : 0,
    }
}
