import { Col, Row } from "antd";
import React, { FC, ReactNode } from "react";
import Icon from "../assets/icons/icon";

interface InfoListProps {
  children: ReactNode;
  layout?: "horizontal" | "vertical";
  split?: ReactNode;
  className?: string;
}

interface TitleProps {
  title: string;
  layout?: "horizontal" | "vertical";
}

interface ItemProps {
  label: string;
  value?: string | ReactNode | number | null | boolean;
  layout?: "horizontal" | "vertical";
  bold?: boolean;
  spaceAfter?: boolean;
  boldLabel?: boolean;
  boldValue?: boolean;
}

interface InputProps {
  label: ReactNode;
  value: ReactNode;
  layout?: "horizontal" | "vertical";
}

const Title: FC<TitleProps> = ({ title, layout = "horizontal" }) => {
  const isVertical = layout === "vertical";
  return (
    <Col span={24}>
      <span className={`info_list_title${!isVertical ? " text-center" : ""}`}>{title}</span>
    </Col>
  );
};

const Item: FC<ItemProps> = ({ label, value, layout = "horizontal", bold, boldLabel, boldValue, spaceAfter }) => {
  const isVertical = layout === "vertical";
  const props = {
    span: 24,
    sm: { span: isVertical ? 24 : 12 },
  };
  const valueType = typeof value;
  const nextLabel = boldLabel || bold ? <strong>{label}</strong> : label;
  const nextValue = boldValue || bold ? <strong>{value}</strong> : value;
  return valueType === "number" || valueType === "string" || valueType === "object" || valueType === "boolean" ? (
    <>
      <Col {...props}>
        <span className={`info-label${!isVertical ? " text-right" : ""}`}>{nextLabel}</span>
      </Col>
      <Col className={spaceAfter ? "mb-1" : ""} {...props}>
        {typeof value === "boolean" ? <Icon name="checkmark-outline" /> : nextValue}
      </Col>
    </>
  ) : null;
};

const Input: FC<InputProps> = ({ label, value, layout = "horizontal" }) => {
  const isVertical = layout === "vertical";
  const props = {
    span: 24,
    sm: { span: isVertical ? 24 : 12 },
  };
  return (
    <>
      <Col {...props}>
        <span className="info-label">{label}</span>
      </Col>
      <Col {...props}>{value}</Col>
    </>
  );
};

interface MenuSubComponents {
  Title: FC<TitleProps>;
  Item: FC<ItemProps>;
  Input: FC<InputProps>;
}

const InfoList: FC<InfoListProps> & MenuSubComponents = ({ children, layout = "horizontal", split, className }) => (
  <div className={`info-group-wp ${className || ""}`}>
    <Row gutter={[16, 10]} align="middle">
      {React.Children.map(children, (child, i) => {
        let element = child;
        const printSplit = split && Array.isArray(children) && i !== children.length - 1;
        if (layout && React.isValidElement(element)) {
          element = React.cloneElement(element, { layout } as any, null);
        }
        return printSplit && element ? (
          <>
            {element}
            <Col span={24}>{split}</Col>
          </>
        ) : (
          element
        );
      })}
    </Row>
  </div>
);

InfoList.Item = Item;
InfoList.Input = Input;
InfoList.Title = Title;

export default InfoList;
