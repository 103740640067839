import { Alert, Button, Select, Spin } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { FC, useCallback, useEffect, useState } from "react";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import { getApiQuery, pageSizeKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { EnumItemType, IdType } from "../types/appTypes";
import { isValidModalType, ModalTypes } from "../types/modalTypes";
import openModal from "./modal/openModal";
import debounce from "lodash/debounce";

const { Option } = Select;

export interface ServerSelectBoxProps extends SelectProps<[]> {
  apiUrl: string;
  initTitle?: string | null;
  onAddClick?: () => void;
  addModalType?: ModalTypes | null;
  skipItems?: IdType[] | null;
  dark?: boolean;
  onItemAdded?: (option: EnumItemType, updatedValues: IdType[] | IdType) => void;
  value?: any;
  selectedTitle?: string | null;
  addModalExtraValues?: { [x: string]: any };
  filters?: string;
  urlSetDelay?: number;
}

const ServerSelectBox: FC<ServerSelectBoxProps> = ({
  apiUrl,
  value,
  onAddClick,
  onItemAdded,
  addModalExtraValues,
  addModalType,
  skipItems,
  initTitle,
  dark,
  dropdownClassName,
  selectedTitle,
  disabled,
  loading,
  mode,
  filters = "",
  urlSetDelay = 200,
  ...rest
}) => {
  const [{ data: response, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<EnumItemType>>("", {
    data: [],
  });

  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, urlSetDelay),
    [setUrl, urlSetDelay]
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const { data } = response;

  useEffect(() => {
    const query = new URLSearchParams(filters);
    query.set(pageSizeKey, "9999");

    const url = apiUrl + getApiQuery(query.toString());
    delayedSetUrl(url);
  }, [filters, delayedSetUrl, apiUrl]);

  const handleAddNewItem = async () => {
    onAddClick && onAddClick();
    if (addModalType && isValidModalType(addModalType)) {
      try {
        const newData = await openModal<EnumItemType>(addModalType, {
          ...addModalExtraValues,
          initialValues: searchValue,
        });

        setData({ data: [...data, newData] });
        if (mode === "multiple" || mode === "tags") {
          onItemAdded &&
            onItemAdded(newData as EnumItemType, Array.isArray(value) ? [...value, String(newData.id)] : [String(newData.id)]);
        } else {
          onItemAdded && onItemAdded(newData as EnumItemType, newData.id);
        }
      } catch (error) {
      } finally {
        setSearchValue("");
      }
    }
  };

  const handleFilter = (input: string, option: any): boolean => {
    const label = option?.label || option?.title;
    return label && label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const filteredData = skipItems ? data.filter((e) => !skipItems.includes(e.id)) : data;

  return (
    <>
      <Select
        {...rest}
        loading={isLoading || loading}
        disabled={disabled}
        showSearch
        mode={mode}
        dropdownClassName={`${dark ? "dropdown-dark " : ""}${dropdownClassName}`}
        defaultActiveFirstOption={true}
        onSearch={setSearchValue}
        searchValue={searchValue}
        filterOption={handleFilter}
        onBlur={(e) => {
          if (e.relatedTarget?.tagName === "BUTTON") {
            e.preventDefault();
          }
        }}
        value={isLoading || value === null ? undefined : value}
        dropdownRender={(menu) => (
          <>
            {menu}
            {((typeof addModalType === "string" && isValidModalType(addModalType)) || onAddClick) && (
              <div className="select-add-btn">
                <Button
                  type="dashed"
                  block
                  htmlType="button"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }} // Prevent blur
                  onClick={handleAddNewItem}
                >
                  <Icon name="add-outline" />
                  &nbsp;
                  {`${_t("add")} ${searchValue ? `"${searchValue}"` : _t("new").toLowerCase()}`}
                </Button>
              </div>
            )}
          </>
        )}
      >
        {isLoading ? (
          <Option key="init" value={"-10"} className="text-center" disabled={!mode}>
            <Spin spinning />
          </Option>
        ) : (
          filteredData?.map((item) => (
            <Option key={item.id} value={mode ? String(item.id) : item.id} label={item.title}>
              {item.title}
            </Option>
          ))
        )}
      </Select>
      {isError && <Alert message={_t("msg.unknown_error")} className="mt-1" type="error" closable />}
    </>
  );
};

export default ServerSelectBox;
