import { Button, Col, Form, Popover, Row } from "antd";
import React, { FC, useEffect } from "react";
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";
import _t from "../../../../lang/translate";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { OfferTax, TaxEstimatedCollateralType } from "../../../../types/taxTypes";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import appConfig from "../../../../appConfig";
import Icon from "../../../../assets/icons/icon";
import InfoList from "../../../../components/infoList";
import format from "../../../../utilities/formatNumbers";

interface DynamicInputProps extends InputPriceProps {
  source: number | null;
  ratio: number;
  autoCalculate?: boolean;
  minThreshhold?: number;
  minValue?: number;
  maxValue?: number;
}
const DynamicInput: FC<DynamicInputProps> = ({ source, ratio, minThreshhold, minValue, maxValue, value, autoCalculate, onChange, ...rest }) => {
  useEffect(() => {
    if (!autoCalculate) return;
    const calculateDynamicValue = () => {
      let resultVal = Math.round((source || 0) * ratio);
      if (maxValue && resultVal && resultVal > maxValue) {
        resultVal = maxValue
      } else if (resultVal && minValue && resultVal < minValue) {
        // If it is NOT a zero reg fee car, and the estimation is less than 500, then set it to 500
        resultVal = minValue
      }
      resultVal = Math.round(resultVal * 1.25); // VAT

      if (minThreshhold && source && source < minThreshhold) resultVal = 0;

      resultVal !== value && onChange && onChange(resultVal);
    };
    calculateDynamicValue();
  }, [source, autoCalculate, ratio, value, onChange]);

  return <InputNum value={value} onChange={onChange} {...rest} />;
};



const WidgetCaseTotalRiskFee: FC<InputPriceProps & TaxEstimatedCollateralType['breakdown']> = ({ value, onChange,
  b2cRiskFee,
  standardRiskFee,
  b2cRiskFeeAutoCalculate,standardRiskFeeAutoCalculate,
  ...rest }) => {
  useEffect(() => {
    const calculateDynamicValue = () => {
      const totalRiskFee = (standardRiskFee || 0) + (b2cRiskFee || 0);
      totalRiskFee !== value && onChange && onChange(totalRiskFee);
    };
    calculateDynamicValue();
  }, [value, onChange, b2cRiskFee, standardRiskFee]);

  return <InputNum value={value} disabled onChange={onChange} {...rest} />;
};
const WidgetCaseRiskFee: FC<InputPriceProps & { registrationFee: number | null, isElectric: boolean, autoCalculate: boolean }> = ({ value, onChange,
  registrationFee, isElectric, autoCalculate,
  ...rest }) => {
  useEffect(() => {
    if (!autoCalculate) return;
    const calculateDynamicValue = () => {
      const widgetRiskFee = calcWidgetRiskFee(registrationFee || 0, isElectric)
      widgetRiskFee.total !== value && onChange && onChange(widgetRiskFee.total);
    };
    calculateDynamicValue();
  }, [value, onChange, registrationFee, isElectric]);

  return <InputNum value={value} onChange={onChange} {...rest} />;
};


const TaxEstimatedCollateralAmount: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer()
  const isElectric = !taxCase.vehicle?.emissionAmount;
  const isWidgetCase = taxCase.caseSourceId === appConfig("widgetCaseSourceId");
  const shouldDisplayB2CCollateral = isWidgetCase && !taxCase.taxCaseValueConfig?.disableWidgetB2cCollateral.value
  const shouldUpdate = (curr: OfferTax, next: OfferTax) =>
    curr.registrationFee?.registrationFee !== next.registrationFee?.registrationFee ||
    curr.estimatedCollateral?.autoCalculate !== next.estimatedCollateral?.autoCalculate ||
    curr?.estimatedCollateral?.estimatedCollateral !== next?.estimatedCollateral?.estimatedCollateral ||
    curr?.estimatedCollateral?.breakdown?.standardRiskFee !== next?.estimatedCollateral?.breakdown?.standardRiskFee ||
    curr?.estimatedCollateral?.breakdown?.b2cRiskFee !== next?.estimatedCollateral?.breakdown?.b2cRiskFee ||
    curr?.estimatedCollateral?.breakdown?.b2cRiskFeeAutoCalculate !== next?.estimatedCollateral?.breakdown?.b2cRiskFeeAutoCalculate||
    curr?.estimatedCollateral?.breakdown?.standardRiskFeeAutoCalculate !== next?.estimatedCollateral?.breakdown?.standardRiskFeeAutoCalculate
  return !shouldDisplayB2CCollateral ? (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {({ getFieldValue }) => {
            const autoCalculate = getFieldValue(["estimatedCollateral", "autoCalculate"]);
            return (
              <Form.Item name={["estimatedCollateral", "estimatedCollateral"]} label={_t("estimated", "collateral")}>
                <DynamicInput
                  ratio={appConfig("tax.riskfee.standard.ratio")}
                  suffix={<small>{_t("include_short", "vat")}</small>}
                  className="bg-white no-border"
                  source={getFieldValue(["registrationFee", "registrationFee"])}
                  minValue={appConfig("tax.riskfee.standard.minValue")}
                  autoCalculate={autoCalculate}
                  disabled={autoCalculate}
                  min={0}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="49px" className="text-right">
        <Form.Item noStyle name={["estimatedCollateral", "autoCalculate"]}>
          <AutoCalcSwitch />
        </Form.Item>
      </Col>
    </Row>
  ) : (
    <>
      <Form.Item shouldUpdate={shouldUpdate} noStyle>
        {({ getFieldValue }) => {
          const breakdown = getFieldValue(['estimatedCollateral', 'breakdown']) as TaxEstimatedCollateralType['breakdown']
          return (
            <Form.Item name={["estimatedCollateral", "estimatedCollateral"]} label={_t("estimated", "collateral")}>
              <WidgetCaseTotalRiskFee {...breakdown} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <div style={{ paddingLeft: 24 }}>
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {({ getFieldValue }) => {
            const autoCalculate = getFieldValue(["estimatedCollateral", "breakdown", "standardRiskFeeAutoCalculate"]);
            return (
              <Row className="flex-nowrap">
                <Col flex="auto">
                  <Form.Item labelAlign="left" labelCol={{ span: 12 }} name={["estimatedCollateral", "breakdown", "standardRiskFee"]} label={_t("collateral") + " (standard)"}>
                    <DynamicInput
                      ratio={appConfig("tax.riskfee.standard.ratio")}
                      suffix={<small>{_t("include_short", "vat")}</small>}
                      className="bg-white no-border"
                      source={getFieldValue(["registrationFee", "registrationFee"])}
                      autoCalculate={autoCalculate}
                      disabled={autoCalculate}
                      min={0}
                      minValue={appConfig("tax.riskfee.standard.minValue")}
                    />
                  </Form.Item>
                </Col>
                <Col flex="49px" className="text-right">
                  <Form.Item noStyle name={["estimatedCollateral", "breakdown", "standardRiskFeeAutoCalculate"]}>
                    <AutoCalcSwitch />
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {({ getFieldValue }) => {
            const autoCalculate = getFieldValue(["estimatedCollateral", "breakdown", "b2cRiskFeeAutoCalculate"]);
            return (
              <Row className="flex-nowrap">
                <Col flex={"auto"}>
                  <Form.Item labelAlign="left" labelCol={{ span: 12 }} name={["estimatedCollateral", "breakdown", "b2cRiskFee"]} 
                    label={<>
                      {_t("collateral") + " (b2c)"}
                      <Popover title={"Risikotillæg udregning (B2C)"} overlayStyle={{maxWidth: 300}} content={
                        <DisplayRiskFeeCalcuation registrationFee={getFieldValue(["registrationFee","registrationFee"])} isElectric={isElectric} />
                      }>
                        <Button 
                          size="small"
                          type="text"
                          shape="circle"
                          tabIndex={-1}
                          className="muted no-bg"
                        >
                          <Icon name="information-circle" />
                        </Button>
                      </Popover>
                    </>
                    }>
                    <WidgetCaseRiskFee
                      registrationFee={getFieldValue(["registrationFee", "registrationFee"])}
                      isElectric={isElectric}
                      className="bg-white no-border"
                      autoCalculate={autoCalculate}
                      disabled={autoCalculate}
                    />
                  </Form.Item>
                </Col>
                <Col flex="49px" className="text-right">
                  <Form.Item noStyle name={["estimatedCollateral", "breakdown", "b2cRiskFeeAutoCalculate"]}>
                    <AutoCalcSwitch />
                  </Form.Item>
                </Col>

              </Row>
            );
          }}
        </Form.Item>
      </div>
    </>
  );
};

export default TaxEstimatedCollateralAmount;

const calcWidgetRiskFee = (registrationFee: number, isElectric: boolean) => {
  const standard = calcStandardWidgetRiskFee(registrationFee || 0)
  const nonElectric = isElectric ? 0 : calcNonElectricWidgetRiskFee(registrationFee || 0)
  const electric = isElectric ? calcElectricWidgetRiskFee(registrationFee || 0) : 0
  return {
    total: Math.round((standard + nonElectric + electric) * 1.25),
    standard: Math.round(standard * 1.25),
    nonElectric: Math.round(nonElectric * 1.25),
    electric: Math.round(electric * 1.25)
  }
}

const calcStandardWidgetRiskFee = (registrationFee: number) => {
  const rate = appConfig("tax.riskfee.b2c.standard.ratio")
  const maxVal = appConfig("tax.riskfee.b2c.standard.maxValue")
  let standardN2CRiskFee = (registrationFee || 0) * rate
  if (standardN2CRiskFee > maxVal) {
    standardN2CRiskFee = maxVal
  }
  return standardN2CRiskFee
}

const calcNonElectricWidgetRiskFee = (registrationFee: number) => {
  const rate = appConfig("tax.riskfee.b2c.nonElectric.ratio")
  let nonElectricRiskFee = (registrationFee || 0) * rate
  return nonElectricRiskFee
}

const calcElectricWidgetRiskFee = (registrationFee: number) => {
  const rate = appConfig("tax.riskfee.b2c.electric.ratio");
  const minThreshhold = appConfig("tax.riskfee.b2c.electric.minThreshhold");
  let electricRiskFee = (registrationFee || 0) * rate
  if (registrationFee < minThreshhold) {
    electricRiskFee = 0;
  }
  return electricRiskFee
}

function DisplayRiskFeeCalcuation({isElectric,registrationFee}:{registrationFee:number | null, isElectric: boolean}) {
  const riskFee = calcWidgetRiskFee(registrationFee || 0, isElectric)
  return (<>
    <InfoList layout="horizontal" className="infolist-text-left">
      <InfoList.Item label="B2C standard" value={format.price(riskFee.standard)}/>
      <InfoList.Item label="B2C ikke-elbil" value={format.price(riskFee.nonElectric)}/>
      <InfoList.Item label="B2C elbil" value={format.price(riskFee.electric)}/>
      <InfoList.Item label="B2C Total" value={format.price(riskFee.total)} bold/>
    </InfoList>
  </>)
}