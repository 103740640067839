import React, { FC, useState } from "react";
import { Collapse, Form } from "antd";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import _t from "../../../../lang/translate";
import TradePriceCalculator from "./tradePriceCalculator";
import TradePriceEditFields from "./tradePriceEditFields";
import TradePriceTotal from "./tradePriceTotal";
import TradePriceViewFields from "./tradePriceViewFields";
import DateSelect from "../../../../components/form/dateSelect";

const { Panel } = Collapse;

interface TradePriceFormItemsProps {
}

const TradePriceFormItems: FC<TradePriceFormItemsProps> = () => {
  const [collapseKeys, setCollapseKeys] = useState<string[]>([]);
  const [editing, setEditing] = useState<boolean>(false);

  const startEditing = () => setEditing(true);
  const stopEditing = () => setEditing(false);

  return (
    <>
      <h4 className="mb-1">{_t("trade_price")}</h4>
      <Form.Item label={_t("documentation", "date")} name="documentationDate">
        <DateSelect />
      </Form.Item>
      <TradePriceCalculator />
      <CustomCollapse activeKey={collapseKeys} setActiveKey={setCollapseKeys}>
        <Panel key="1" header={<CustomCollapseHeader>{_t("calculation")}</CustomCollapseHeader>} forceRender>
          {editing ? (
            <TradePriceEditFields stopEditing={stopEditing} />
          ) : (
            <TradePriceViewFields startEditing={startEditing} />
          )}
        </Panel>
      </CustomCollapse>
      <TradePriceTotal />
    </>
  );
};

export default TradePriceFormItems;
