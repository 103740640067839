import { Button, Form, Input, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";
import { GovernmentCaseHandler } from "../../types/taxLegalTypes";
import { addGovernmentCaseHandler } from "../../services/taxLegalService";
import consts from "../../utilities/consts";
import { useState } from "react";

export default function AddGovernmentCaseHandler<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [loading, setLoading] = useState(false)
  const [tmpForm] = Form.useForm<GovernmentCaseHandler>();
  const handleSubmit = async (values: GovernmentCaseHandler) => {
    try {
      setLoading(true)
      if (!values.typeId) values.typeId = "motor_registry"
      const { data } = await addGovernmentCaseHandler(values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false)
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="horizontal" initialValues={payload} onFinish={handleSubmit}
      {...consts.formItemProps}
    >
      <h2 className="modal-title">{_t("add", 'MS') + " " + _t("caseHandler").toLowerCase()}</h2>
        <Form.Item label={_t("name")} name="name" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item label={_t("email")} name="email" rules={[{ required: true }]} >
          <Input type="email"/>
        </Form.Item>
        <Form.Item label={_t("phone")} name="phone" rules={[{ required: true }]} >
          <Input type="tel"/>
        </Form.Item>
        <Form.Item hidden name="typeId">
          <Input/>
        </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" loading={loading} onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("add")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(AddGovernmentCaseHandler);
