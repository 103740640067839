import { Button, Form, Input, Modal } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { resetPassword } from "../../../services/userService";
import { IdType } from "../../../types/appTypes";
import { passwordRegex } from "../../../utilities/password";

interface ResetPasswordProps {
  userId: IdType;
  linkStyle?: boolean;
}

const UserResetPassword: FC<ResetPasswordProps> = ({ userId, linkStyle }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPasswordForm] = Form.useForm();
  const { user } = UserStore.useContainer();
  const handleOk = () => {
    resetPasswordForm.validateFields().then(async (data) => {
      try {
        setLoading(true);
        await resetPassword(userId, data);
        setVisible(false);
      } catch (error) {
        renderErrorMessage(error)
      } finally {
        setLoading(false);
      }
    }).catch(err => null);
  };
  return (
    <>
      {linkStyle ? (
        <Button
          onClick={() => setVisible(true)}
          className="p-0 text-left"
          type="link"
        >
          <Icon name="lock-closed-outline" />
          &nbsp;
          {_t("reset_password")}
        </Button>
      ) : (
        <Button type="ghost" onClick={() => setVisible(true)}>
          <Icon name="lock-closed-outline" />
          {_t("reset_password")}
        </Button>
      )}
      <Modal
        title={_t("reset_password")}
        visible={visible}
        okText={_t("save")}
        cancelText={_t("cancel")}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => setVisible(false)}
      >
        <Form
          form={resetPasswordForm}
          name="resetPasswordForm"
          layout="vertical"
          requiredMark={false}
        >
          {user?.id === userId && (
            <Form.Item
              name="currentPassword"
              label={_t("current_password")}
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="password"
            label={_t("new_password")}
            rules={[
              { required: true },
              { whitespace: true },
              { min: 8, message: _t('password_min_8') },
              { pattern: passwordRegex, message: _t('password_complexity') }
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserResetPassword;
