import { Checkbox, Divider, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { FC, useState } from "react";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { sendImportOffer } from "../../../../services/importService";
import { ImportCaseType } from "../../../../types/importTypes";
import { isNum } from "../../../../utilities/typeGuard";

interface ImportSendOfferModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportSendOfferModal: FC<ImportSendOfferModalProps> = ({visible, setVisible}) => {
  const {importCase, setImportCase} = ImportCaseStore.useContainer();
  const {id, files: caseFiles} = importCase;
  const [loading, setLoading] = useState<boolean>(false);
  const [withReport, setWithReport] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [note, setNote] = useState<string | null>(null);

  const handleReview = async (newCase: ImportCaseType) => {
    const merchantId = newCase.merchant?.id;

    //Open review modal after sending offer:
    if (merchantId) {
      const {data: newRatingScore} = await openModal("review", {
        initialValues: {sourceId: newCase.id, sourceContext: "import"},
        targetId: merchantId,
        targetContext: "merchant",
      }).catch(() => ({} as any));
      // Populate the new rating score:
      if (isNum(newRatingScore) && newCase.merchant) {
        const merchant = {...newCase.merchant, rating: newRatingScore};
        setImportCase({...newCase, merchant});
      }
    }
  };

  const handleOfferSend = async () => {
    try {
      setLoading(true);
      const body = {withReport, files, note};
      const {data} = await sendImportOffer(id, body);
      message.success(<SavedMessage/>);
      setImportCase(data);
      setVisible(false);
      handleReview(data)
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={_t("send", "offer")}
      visible={visible}
      onOk={handleOfferSend}
      confirmLoading={loading}
      cancelButtonProps={{disabled: loading}}
      onCancel={() => setVisible(false)}
    >
      <p>{_t("add_these_files")}</p>

      <Checkbox disabled={loading} checked={withReport} onChange={({target}) => setWithReport(target.checked)}>
        {_t("car_report")}
      </Checkbox>

      <Checkbox.Group
        disabled={loading}
        onChange={(v) => setFiles(v)}
        options={caseFiles?.map((file) => ({
          label: file.name,
          value: file.response?.id,
        }))}
      />

      <Divider/>

      <TextArea
        disabled={loading}
        value={note || ''}
        onChange={({target}) => setNote(target.value)}
        className="noresize-text-area"
        placeholder={_t("note")}
        maxLength={500}
        autoSize={{minRows: 1, maxRows: 5}}
      />
    </Modal>
  );
};

export default ImportSendOfferModal;
