import { SelectProps as AntSelectProps, Badge, Button, Col, Form, Input, Modal, Row, Select, Switch, Typography } from "antd"
import { UploadFile } from "antd/lib/upload/interface"
import { useState } from "react"
import Icon from "../../assets/icons/icon"
import DocumentsUpload from "../../components/documentsUpload"
import EnumSelect from "../../components/form/enumSelect"
import DividerLine from "../../components/lineSplit"
import ServerSelectBox from "../../components/serverSelectBox"
import { useTaxLegalActions, useTaxLegalFiles, useTaxLegalId } from "../../contexts/taxLegalCaseStore"
import _t from "../../lang/translate"
import { tagsAutoComplete } from "../../services/autocompleteService"
import { taxLegalFileUrl } from "../../services/taxLegalService"
import { ApiFile } from "../../types/appTypes"
import { TaxLegalCategoryId } from "../../types/taxLegalTypes"
import { getFileId, isApiFile } from "../../utilities/convertTypes"
import format from "../../utilities/formatNumbers"
import { capitalize } from "../../utilities/stringExtraFunctions"
import { useEnums } from "../../services/enumService"

type SelectProps = AntSelectProps<number>


export function PenaltyType(antDesignProps: SelectProps) {
  return (
    <EnumSelect placeholder={_t("choose", "responsibilityEvaluation")} nameKey={["taxLegalCase", "penaltyType"]} {...antDesignProps} />
  )
}

export function LackOfComplianceTags() {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue }) => (
        <Form.Item label={"Mgl. regelefterlevelse"} name="lackOfComplianceTags">
          <ServerSelectTags categoryId="tax_legal_compliance" placeholder={_t("fix")} updateFieldsValue={(updatedValues) => setFieldsValue({ lackOfComplianceTags: updatedValues })} />
        </Form.Item>
      )}
    </Form.Item>
  )
}
export function PriceSourceTags({path}:{path:string[]}) {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue }) => (
        <Form.Item label={_t("equipment", "source")} name={[...path, "equipmentPriceSourceTags"]}>
          <ServerSelectTags categoryId="tax_legal_price_source" placeholder={_t("choose", "source")} updateFieldsValue={(updatedValues) => setFieldsValue({ equipmentSourceTags: updatedValues })} />
        </Form.Item>
      )}
    </Form.Item>
  )
}
export function SelectNewPriceSource({path}: {path: string[]}) {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue }) => (
        <Form.Item label={_t("new_price", "source")} name={[...path,"newPriceSourceTag"]}>
          <ServerSelectTags categoryId="tax_legal_price_source" placeholder={_t("choose", "source")} updateFieldsValue={(updatedValues) => setFieldsValue({ newPriceSource: updatedValues })} normalMode />
        </Form.Item>
      )}
    </Form.Item>
  )
}
export function AnalysisTags() {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue }) => (
        <Form.Item label={_t("analysis")} name="analysisTags">
          <ServerSelectTags categoryId="tax_legal_analysis" placeholder={_t("choose", "analysis")} updateFieldsValue={(updatedValues) => setFieldsValue({ analysisTags: updatedValues })} />
        </Form.Item>
      )}
    </Form.Item>
  )
}

export function ServerSelectTags({ categoryId, updateFieldsValue, placeholder, normalMode, ...rest }:
  { categoryId: TaxLegalCategoryId, updateFieldsValue: (updatedValues: number | number[]) => void, placeholder: string, normalMode?: boolean, }
) {
  return (
    <ServerSelectBox
      allowClear
      // 08-01-2025 --> Remove the ability to add new tags for now. I am keeping the code, just in case we want to use the functionality later.
      // addModalType={"addTag"}
      // addModalExtraValues={{ categoryId }}
      // onItemAdded={(_, updatedValues) => updateFieldsValue(updatedValues)} // TODO: Handle string to number conversion
      apiUrl={tagsAutoComplete()}
      filters={`filter[categoryId]=${categoryId}`}
      placeholder={placeholder}
      mode={normalMode ? undefined : "tags"}
      {...rest}
    />
  )
}

export function OutcomeTypeSelect(antDesignProps: SelectProps) {

  return (
    <EnumSelect placeholder={_t("choose", "outcome")} nameKey={["taxLegalCase", "outcomeType"]} {...antDesignProps} />
  )
}

export function ResumptionRequestOutcome(antDesignProps: SelectProps) {
  return (
    <EnumSelect placeholder={_t("choose", "outcome")} nameKey={["taxLegalCase", "resumptionOutcomeType"]} {...antDesignProps} />
  )
}

export function GovernmentAssessmentSelect({nameSpace, ...rest}:SelectProps & {nameSpace: string}) {
  const [enums] = useEnums()
  const governmentAssessment = enums?.taxLegalCase.governmentAssessment
  return (
    <Select placeholder={_t("choose", "outcome")} {...rest}>
      {governmentAssessment?.map(item => (
        <Select.Option key={item.id} value={item.id}><Badge color={item.color}/>{item.title} {nameSpace}</Select.Option>
      ))}
    </Select>
  )
}

type FormItemFileReference = { id: string; ant_uid: string };
type CaseAttachmentsItemProps = {
  itemName: "externalDocuments" 
    | "internalDocuments"
    | "materialsSummonsDocuments" 
    | "documentationForEvaluationDocuments" 
    | "proposalForConclusionDocuments" 
    | "notesForProposalDocuments" 
    | "conclusionDocuments" 
    | "resumptionRequestDocuments" 
    | "complaintDocuments" 
    | "statementDocuments" 
    | "casePresentationDocuments";
}
export function CaseAttachmentsItem({ itemName }: CaseAttachmentsItemProps) {
  const id = useTaxLegalId()
  const { setFiles } = useTaxLegalActions()
  const files = useTaxLegalFiles();
  const [open, setOpen] = useState(false);

  function pickFile(parsedIds: FormItemFileReference[], file: UploadFile, checked: boolean, setFieldsValue: (value: any) => void) {
    let newIDList = parsedIds;
    const fileReferenceObj = getNewFileReferenceObj(file.uid || "", isApiFile(file) ? String(file.id) : "");
    if (checked) {
      newIDList.push(fileReferenceObj)
    } else {
      newIDList = newIDList.filter(item => !(getFileId(file) === item.id || file.uid === item.ant_uid))
    }
    setFieldsValue({ [itemName]: JSON.stringify(newIDList) })
  }

  function getNewFileReferenceObj(ant_uid: string, id?: string):FormItemFileReference {
    return {
      ant_uid: ant_uid || "",
      id: id || ""
    }
  }

  return (
    <>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev[itemName] !== next[itemName]}>
        {({ setFieldsValue, getFieldValue }) => {
          const idList = getFieldValue([itemName]) as string | undefined
          const parsedIds = JSON.parse(idList || "[]") as FormItemFileReference[]
          return (
            <>
              <DocumentsUpload
                onSuccess={(apiFile: ApiFile, file) => {
                  let newIDList = parsedIds;
                  const existsIndex = newIDList.findIndex(item => {
                    if (item.id === apiFile.id) return true;
                    if (item.ant_uid === file.uid) return true;
                    return false;
                  })

                  const referneceObj = getNewFileReferenceObj(file.uid, apiFile.id ? String(apiFile.id) : "" )
                  if (existsIndex > -1) {
                    newIDList[existsIndex] = referneceObj
                  } else {
                    newIDList.push(referneceObj)
                  }

                  setFieldsValue({ [itemName]: JSON.stringify(newIDList) })

                  const index = files?.findIndex(item => isApiFile(item) && item.id === apiFile.id)
                  const newFiles = files && [...files];
                  if (index) {
                    // @ts-expect-error Conflicting types from the api vs antdesign
                    newFiles?.splice(index, 1, apiFile)
                  }
                  setFiles(newFiles)
                }}

                fileList={files || []}
                canLockFiles={true}
                canDelete={true}
                ids={parsedIds}
                filterByIDs
                onChange={({ fileList, file }) => {
                  let newIDList = parsedIds;
                  const existsIndex = newIDList.findIndex(item => {
                    if (item.ant_uid === file.uid) return true;
                    return false;
                  })

                  const referneceObj = getNewFileReferenceObj(file.uid)
                  if (existsIndex > -1) {
                    newIDList[existsIndex] = referneceObj;
                  } else {
                    newIDList.push(referneceObj)
                  }

                  setFieldsValue({ [itemName]: JSON.stringify(newIDList) })
                  setFiles(fileList)
                }}
                action={taxLegalFileUrl(id)}
              />

              <Modal title={_t("pick", "file")}
                visible={open}
                onOk={() => setOpen(false)}
                okButtonProps={{hidden: true}}
                onCancel={() => setOpen(false)}
                cancelText={_t("close")}
                width={600}
              >
                {files?.map((file, i) => {
                  const checked = parsedIds.some(id => (isApiFile(file) && file.id === id.id) || (file.uid === id.ant_uid))
                  return (
                    <div key={getFileId(file)} >
                      <Row gutter={8} align="middle">
                        <Col flex="auto">
                          <Typography.Text
                            type={
                              file.status === "error" || file.status === "removed"
                                ? "danger"
                                : undefined
                            }
                            className="mx-width-440"
                            title={
                              file.status === "error"
                                ? file?.error?.response?.data?.message
                                : undefined
                            }
                            ellipsis
                          >
                            <Icon name="attach-outline" size="large" className="mr-05" />
                            {capitalize(file.name)}
                          </Typography.Text>
                          <small> — {file.size && format.fileSize(file.size)}</small>
                        </Col>

                        <Col>
                          <Switch
                            checked={checked}
                            onChange={(checked) => pickFile(parsedIds, file, checked, setFieldsValue)}
                          />
                        </Col>
                      </Row>
                      {i + 1 < files.length ? (
                        <DividerLine className="mt-05 mb-05" />
                      ) : (
                        <DividerLine weight={2} className="mt-05 mb-1" />
                      )}
                    </div>
                  )
                })}
              </Modal>
            </>
          )
        }}
      </Form.Item>
      <div className="d-flex justify-content-end mt-1">
        <Button style={{}} onClick={() => setOpen(true)} icon={<Icon name="file-tray-outline" size="huge"/>}>Udvælg fil</Button>
      </div>
      <Form.Item hidden name={itemName}>
        <Input />
      </Form.Item>
    </>
  );
};
