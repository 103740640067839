import React, {ComponentProps, FC, useState} from "react";
import {Col, message, Row} from "antd";
import {SwitchChangeEventHandler} from "antd/lib/switch";
import SwitchInput from "../../../../components/form/switchInput";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import {isAxiosError} from "../../../../utilities/typeGuard";
import {ImportCaseType} from "../../../../types/importTypes";
import {AxiosResponse} from "axios";
import {slugify} from "../../../../utilities/stringExtraFunctions";
import {StatusUpdateType} from "../../../../types/appTypes";

interface StatusFlagToggleProps {
  callable: (id: number, data: StatusUpdateType) => Promise<AxiosResponse<ImportCaseType>>
  flag: keyof ImportCaseType['flags'],
  label: string,
  color?: ComponentProps<typeof SwitchInput>['color']
  id?: string
}

const StatusFlagToggle: FC<StatusFlagToggleProps> = ({callable, id: htmlId, flag, label, color = "green"}) => {
  const elementId = htmlId || slugify(label);
  const {importCase, setImportCase} = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>();
  const {id, log, flags} = importCase;

  const handleStatusToggle: SwitchChangeEventHandler = async (v) => {
    try {
      setLoading(true);
      const {data} = await callable(id, {status: v});
      setImportCase(data);
      message.success(_t("saved"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Row gutter={[8, 8]} className="mb-1">
      <Col>
        <SwitchInput
          size="small"
          loading={loading}
          id={elementId}
          color={color}
          label={label}
          checked={flags[flag]}
          onChange={handleStatusToggle}
        />
      </Col>
      {log && flag in log && (
        <Col>
          {/* Type assertion to `any` is required due to TypeScript not correctly narrowing the type
              after the existence check. Even with `flag in log`, TS cannot infer that log[flag] is safe */}
          <LogText mdash inline small className={"p-0"} logData={(log as any)[flag]}/>
        </Col>
      )}
    </Row>
  );
};

export default StatusFlagToggle;
