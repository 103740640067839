import { isValidNum } from "./typeGuard";

export function extractDataFromObj<T>(obj: any, path: string[]) {
  if (!path || !obj) return null;

  let results: any = { ...obj };
  for (const key of path) {
    if (key in results) {
      results = results[key];
    } else {
      return null;
    }
  }
  if (results) return results as T;

  return null;
}


export function areAllValuesFilled(obj: Record<any, any>) {
  const values = Object.values(obj)
  if (!values.length) return false;
  for (const val of values) {
    if (!val && !isValidNum(val)) return false
  }
  return true
}

export function pickNameListFromObj<T>(obj: T, nameList: (keyof T)[]): Partial<T> {
  const partialObj: Partial<T> = {}
  for (const key in obj) {
    if (nameList.includes(key)) {
      partialObj[key] = obj[key]
    }
  }
  return partialObj
}


export function getValuesFilledStatus<K extends Record<string, any>>(namesListObj: K, obj: Record<any,any>): Record<keyof K, boolean> {
  const newObj = {} as Record<keyof K, boolean>

  for (const key in namesListObj) {
    newObj[key] = areAllValuesFilled(pickNameListFromObj(obj, namesListObj[key]))
  }
  return newObj
}
export function constructObjectRecursively(path: string[], value: any): object {
  if (path.length === 0) return value;
  const [key, ...rest] = path;
  return { [key]: constructObjectRecursively(rest, value) };
}
