import { Button, Col, Form, Row, Space } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { FC, ReactNode, useEffect, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import DragUpload from "../../../../components/dragUpload";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import UploadButton from "../../../../components/uploadButton";
import UploadFileRender from "../../../../components/uploadFileRender";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import useIsDraggingFiles from "../../../../hooks/useIsDraggingFiles";
import _t from "../../../../lang/translate";
import { deleteFile } from "../../../../services/mediaService";
import { DocumentationFilesType } from "../../../../types/taxDocTypes";
import { accepted } from "../../../../utilities/acceptedFiles";

interface TaxDocUploadFileProps {
  label: ReactNode;
  action: string;
  fileKey: "ads" | "newPrice" | "equipments" | "vehicleList";
  onAutoFetch?: () => void;
  loading?: boolean;
}

const TaxDocUploadFile: FC<TaxDocUploadFileProps> = ({
  label,
  action,
  fileKey,
  onAutoFetch,
  loading: propsLoading,
}) => {
  const [isDragging] = useIsDraggingFiles();
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const { documentationFiles } = taxCase;

  useEffect(() => {
    typeof propsLoading === "boolean" && setLoading(propsLoading);
  }, [propsLoading]);

  const updateFiles = (file: UploadFile | null) => {
    const newDocumentationFiles: DocumentationFilesType = {
      ...documentationFiles,
      [fileKey]: file,
    };
    setTaxCase({ ...taxCase, documentationFiles: newDocumentationFiles });
  };

  const handleFileDelete = async (file: UploadFile) => {
    const serverId = file.response?.id;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        setLoading(true);
        await deleteFile(action, serverId);
      } catch (error) {
        renderErrorMessage(error, _t("msg.file_not_removed"));
        canRemove = false;
      } finally {
        setLoading(false);
      }
    }
    if (canRemove) {
      updateFiles(null);
    }
  };

  const handleUploadChange = ({ fileList }: any) => {
    updateFiles(fileList[0]);
  };

  const fileList =
    documentationFiles && documentationFiles[fileKey]
      ? [documentationFiles[fileKey]]
      : [];

  const props = {
    //fileList: fileList || [],
    accept: accepted.join(","),
    action: action,
    onChange: handleUploadChange,
    disabled: loading,
    uploadIcon: null,
  };

  return (
    <>
      {fileList[0] && (
        <UploadFileRender
          className="mb-05"
          onRemove={handleFileDelete}
          file={fileList[0]}
          loading={loading}
        />
      )}
      <Form.Item
        noStyle
        hidden={
          !!fileList?.length &&
          (fileList[0]?.status === "done" || fileList[0]?.status === "error")
        }
      >
        <Row justify="space-between" align="middle" className="mb-05">
          <Col>
            <label>{label}</label>
          </Col>
          <Col>
            <DragUpload
              className={`${isDragging && !props.disabled ? "is-dragging" : ""} file-drop-zone`}
              showUploadList={false}
              {...props}
            />
            {onAutoFetch ? (
              <Space>
                <Button
                  className="pl-05 pr-05"
                  onClick={onAutoFetch}
                  disabled={loading}
                >
                  <Icon name="refresh-outline" />
                </Button>
                <UploadButton {...props} />
              </Space>
            ) : (
              <UploadButton {...props} />
            )}
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default TaxDocUploadFile;
