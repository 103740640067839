import React, { FC, useState } from "react";
import { IdType } from "../../types/appTypes";
import { useForm } from "antd/lib/form/Form";
import { Form, message, Radio } from "antd";
import _t from "../../lang/translate";
import ServerSelectBox from "../serverSelectBox";
import { customersAutoComplete, dealersAutoComplete } from "../../services/autocompleteService";
import { getCustomer } from "../../services/customerService";
import { getDealer } from "../../services/dealerService";
import { CustomerType } from "../../types/customerTypes";
import { DealerType } from "../../types/dealerTypes";
import { SelectableFetchType } from "../../types/miscTypes";
import { renderErrorMessage } from "../messages/errorMessage";

interface Props {
  wantedTypes: SelectableFetchType[],
  onFetch: (type: SelectableFetchType, data: any) => void
}

interface SearchFormType {
  selectedId: null | IdType,
  selectedType: SelectableFetchType
}

const CustomTypeSelect: any = (
  {
    loading,
    wantedTypes,
    onChange,
    form,
    ...rest
  }: any) => {
  const handleChange = (e: any) => {
    form.setFieldsValue({ selectedId: null });

    onChange(e);
  }

  return (
    <Radio.Group
      optionType="button"
      className="mb-2"
      disabled={loading}
      onChange={handleChange}
      {...rest}
    >
      {(wantedTypes as SelectableFetchType[]).map(val => {
        let label;

        switch (val) {
          case "company":
            label = _t('company');
            break;
          case "dealer":
            label = _t('dealer');
            break;
          case "private":
            label = _t('private');
            break;
          default:
            label = val;
            break;
        }

        return <Radio.Button key={val} value={val}>{label}</Radio.Button>
      })}
    </Radio.Group>
  )
};

const CustomApiFinder = ({ onChange, form, ...rest }: any) => {
  const handleChange = (e: any) => {
    onChange(e);
    form.submit();
  }

  return (
    <ServerSelectBox apiUrl={rest.apiUrl || ''} filters={rest.filters} disabled={rest.loading} loading={rest.loading}
      onChange={handleChange} {...rest} />
  )
}

const SystemDataFetcher: FC<Props> = ({ wantedTypes, onFetch }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchForm] = useForm<SearchFormType>();

  const handleFormSubmit = async (values: SearchFormType) => {
    if (!values.selectedId) {
      return;
    }

    try {
      setLoading(true);

      let data = null;

      switch (values.selectedType) {
        case "company":
          data = (await getCustomer(values.selectedId)).data as CustomerType
          break;
        case "private":
          data = (await getCustomer(values.selectedId)).data as CustomerType
          break;
        case "dealer":
          data = (await getDealer(values.selectedId)).data as DealerType
          break;
        default:
          break;
      }

      onFetch(values.selectedType, data)
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={searchForm}
      requiredMark={false}
      onFinish={handleFormSubmit}
      initialValues={{
        selectedType: wantedTypes[0],
        selectedId: null,
      }}
    >
      <Form.Item
        name="selectedType"
        label={_t("type")}
        rules={[{ required: true }]}
      >
        <CustomTypeSelect wantedTypes={wantedTypes} loading={loading} form={searchForm} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.selectedType !== curr.selectedType}>
        {({ getFieldValue }) => {
          const selectedType = getFieldValue('selectedType');
          let wantedApiUrl = null;
          let wantedFilters = '';

          switch (selectedType) {
            case "company":
              wantedApiUrl = customersAutoComplete();
              wantedFilters = `filter[typeId]=2`;
              break;
            case "dealer":
              wantedApiUrl = dealersAutoComplete();
              break;
            case "private":
              wantedApiUrl = customersAutoComplete();
              wantedFilters = `filter[typeId]=1`;
              break;
            default:
              break;
          }

          return (
            <Form.Item name="selectedId" label={_t("customer")} rules={[{ required: true }]}>
              <CustomApiFinder apiUrl={wantedApiUrl} filters={wantedFilters} loading={loading} form={searchForm} />
            </Form.Item>
          )
        }}
      </Form.Item>


    </Form>
  )
};

export default SystemDataFetcher;
