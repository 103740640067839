import { Button, Drawer, message, Result } from "antd";
import { FC, useEffect, useState } from "react";
import Loading from "../../../../components/loading";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import LayoutStore from "../../../../contexts/layoutStore";
import _t from "../../../../lang/translate";
import { markAsActiveReport } from "../../../../services/leasingService";
import { motorAgencyReportUrl } from "../../../../services/motorAgencyService";
import { useApi } from "../../../../services/useApi";
import { MotorAgencyDataType } from "../../../../types/leasingTypes";
import consts from "../../../../utilities/consts";
import MotorAgencyReportInfo from "./motorAgencyReportInfo";

interface MotorAgencyHistoryProps {
  disabled?: boolean;
  vin?: string | null;
  activeMotorAgencyDataId?: string | null;
  leasingCaseId: number;
  onActiveChange?: (data: MotorAgencyDataType) => void;
}

const MotorAgencyHistory: FC<MotorAgencyHistoryProps> = ({
  vin,
  disabled,
  activeMotorAgencyDataId,
  onActiveChange,
  leasingCaseId,
}) => {
  const { screen } = LayoutStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const [{ data, isLoading, isError }, setUrl] = useApi<{ data: MotorAgencyDataType[] }>("", { data: [] });
  const reports = data.data;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!reports.length && visible && vin) {
      setUrl(motorAgencyReportUrl(vin));
    }
  }, [visible, reports, vin]);

  const toggleActiveMotorReport = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await markAsActiveReport(leasingCaseId, id || null);
      onActiveChange && data.motorAgency && onActiveChange(data.motorAgency);
      message.success(_t("saved"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button disabled={disabled} onClick={() => setVisible(true)}>
        {_t("show_history")}
      </Button>
      <Drawer
        visible={visible}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
        onClose={() => setVisible(false)}
      >
        {isLoading && <Loading loading={true} />}
        {isError && <Result title={_t("msg.unknown_error")} status="error" />}
        {!!reports.length &&
          reports.map((item) => {
            const isActive = item.id === activeMotorAgencyDataId;
            return (
              <div
                className={`border-rounded border-light-2 p-1 mb-1 mt-2${isActive ? " border-success" : ""}`}
                key={item.id}
              >
                <MotorAgencyReportInfo report={item} />
                <div className="text-right">
                  <Button
                    disabled={!isActive && loading}
                    loading={isActive && loading}
                    onClick={() => toggleActiveMotorReport(item.id)}
                  >
                    {_t(isActive ? "deactivate" : "activate")}
                  </Button>
                </div>
              </div>
            );
          })}
      </Drawer>
    </>
  );
};

export default MotorAgencyHistory;
