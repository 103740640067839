import _t from "../../lang/translate";
import { governmentCaseHandlersAutoComplete } from "../../services/autocompleteService";
import { GovernmentCaseHandler } from "../../types/taxLegalTypes";
import ServerSelectBox, { ServerSelectBoxProps } from "../serverSelectBox";

/** Motorstyrelsens sagsbehandler
* Specifically for TAX-LEGAL. But it can we used for anything */
export default function SelectGovernmentHandler(props: Omit<ServerSelectBoxProps, 'apiUrl'> & {addModalExtraValues: {typeId: GovernmentCaseHandler['typeId']}}) {
  const typeId = props.addModalExtraValues.typeId
  return  (
    <ServerSelectBox
      {...props}
      allowClear
      addModalType={"addGovernmentCaseHandler"}
      apiUrl={governmentCaseHandlersAutoComplete()}
      filters={`filter[typeId]=${typeId}`}
      placeholder={_t("choose", "caseHandler")}
    />
  )
};
