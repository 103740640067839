import { Button, Checkbox, message, Popconfirm, Space, Form, Input, Modal, Typography, Tooltip } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "../../components/table/dataTable";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import PageTitle from "../../layout/pageTitle";
import { getPath, urlExtraParams } from "../../routes/appRoutes";
import { useApi } from "../../services/useApi";
import { PaginationedData } from "../../types/apiTypes";
import { IdType, Person, ScreenProps } from "../../types/appTypes";
import { TaxLegalDashboard } from "../../types/taxLegalTypes";
import { SelectValue } from "antd/lib/select";
import Icon from "../../assets/icons/icon";
import StatusSelect from "../../components/form/statusSelect";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import StatusTag from "../../components/statusTag";
import { CustomColumnsType, RenderFunc } from "../../components/table/dataTableTypes";
import { deleteTaxLegalCase, initializeExport, taxLegalCasesURL, taxLegalCaseUrl } from "../../services/taxLegalService";
import { dealerKey, pageSizeKey, repairRequestedKey, reviewerKey, searchKey, statusKey, caseHandlerKey } from "../../services/urlQueryService";
import format from "../../utilities/formatNumbers";
import debounce from "lodash.debounce";
import { dealersAutoComplete, usersAutoComplete } from "../../services/autocompleteService";
import ServerSelectBox from "../../components/serverSelectBox";
import { getRandomString } from "../../utilities/stringExtraFunctions";
import consts from "../../utilities/consts";
import LoadingContainer from "../../components/loadingContainer";
import SelectUser from "../../components/form/selectUser";



const TaxLegal: FC<ScreenProps> = ({ title }) => {
  const { hasPermission } = UserStore.useContainer();
  const [doRefund, setDoRefund] = useState<boolean>(false);
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<TaxLegalDashboard>>("", { data: [] }, true);
  const location = useLocation()

  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 350), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = taxLegalCasesURL(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, delayedSetUrl]);

  function handleRefresh() {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    query.set(getRandomString(), new Date().getSeconds().toString());
    const url = taxLegalCasesURL(query.toString());
    setUrl(url);
  }

  const columns: CustomColumnsType<TaxLegalDashboard> = [
    {
      title: _t("case", "number_short"),
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => (
        hasPermission("screen.tax") ? (
        <>
        {record.repairRequestedAt && (
          <Typography.Text type={"warning"} className="priority-icon-table">
            <Tooltip title={"Reparation påkrævet (markeret d. " + format.date(record.repairRequestedAt) +")"}>
              <Icon name="build-outline" size="large" style={{ display: 'block' }} />
            </Tooltip>
          </Typography.Text>
        )}
          <Link to={getPath("taxLegal", record.id)}>
            <strong>{text}</strong>
          </Link>
        </>
        ) : (
          text
        )
      ),
      fixed: "left",
    },
    {
      title: _t("date"),
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      width: 110,
      render: (date) => {

        return format.date(date)
      },
    },
    {
      title: _t("caseHandler"),
      key: "caseHandler",
      dataIndex: "caseHandler",
      width: 155,
      ellipsis: true,
      render: (caseHandler: Person) => {
        return caseHandler.fullName
      },
    },
    {
      title: _t("ms.caseNumber"),
      key: "governmentCaseNumber",
      sorter: true,
      dataIndex: "governmentCaseNumber",
      width: 110,
    },
    {
      title: _t("ms.groupCaseNumber"),
      key: "governmentBatchId",
      sorter: true,
      dataIndex: "governmentBatchId",
      width: 160,
    },
    {
      title: _t("referenceNumber_short"),
      key: "referenceCode",
      sorter: true,
      dataIndex: "referenceCode",
      width: 110,
    },
    {
      title: _t("reviewer"),
      key: "reporterCompany",
      sorter: true,
      dataIndex: "reporterCompany",
      width: 125,
      ellipsis: true,
    },
    {
      title: _t("customer"),
      key: "clientName",
      dataIndex: "clientName",
      width: 125,
      ellipsis: true,
    },
    {
      title: _t("vehicle"),
      key: "car",
      dataIndex: "car",
      ellipsis: true,
    },
    {
      title: _t("vin"),
      key: "vin",
      sorter: true,
      dataIndex: "vin",
      width: 165,
    },
    {
      title: _t("firstRegDate"),
      key: "firstRegDate",
      sorter: true,
      dataIndex: "firstRegDate",
      width: 165,
      render: (date) => format.date(date),
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      dataIndex: "status",
      width: 200,
      render: (status) => <StatusTag status={status} size="small" />,
    },
    {
      title: _t("deadline", "date_at"),
      key: "nextDeadline",
      sorter: true,
      dataIndex: "nextDeadline",
      width: 142,
      render: (date) => format.date(date),
    },
    {
      title: _t("outcome"),
      key: "penaltyType",
      dataIndex: "penaltyType",
      width: 142,
      render: (penaltyType) => <StatusTag status={penaltyType} size="small" />,
    },
    {
      title: _t("regulation"),
      key: "regulationAmount",
      sorter: true,
      dataIndex: "regulationAmount",
      width: 142,
      render: (regulationAmount) => {
        return regulationAmount ? format.price(Number(regulationAmount)) : "-"
      },
    },
  ];

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      await deleteTaxLegalCase(id, doRefund);
      message.success("#" + id + " " + _t("deleted"));
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
    } catch (error) {
      renderErrorMessage(error, _t("msg.not_deleted"))
    }
  };

  const tableActions: RenderFunc<TaxLegalDashboard> = (_, { id }) => {
    return (
      <Popconfirm
        placement="topLeft"
        onVisibleChange={() => setDoRefund(false)}
        onConfirm={() => handleDelete(id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>{id}</strong>
            <div>
              <Checkbox
                checked={doRefund}
                onChange={e => setDoRefund(e.target.checked)}
              >{_t('refund', 'credits')}</Checkbox>
            </div>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    );
  };

  return (
    <>
      <PageTitle
        className="fluid-page-title pb-05"
        title={title}
        extra={
          <>
            <Button type="primary">
              <Link to={getPath("taxLegal", urlExtraParams.new)}>+ {_t("create", "case")}</Link>
            </Button>
          </>
        }
      >
      </PageTitle>
      <DataTable<TaxLegalDashboard>
        onRefresh={handleRefresh}
        filter={<TableFilters />} // TODO
        loading={isLoading}
        renderActions={tableActions}
        columnStorageKey="TAXLEGAL"
        columns={columns}
        dataSource={data.data}
        meta={data.meta}
      />
    </>
  );
};

export default TaxLegal;





function TableFilters() {
  const location = useLocation()
  const history = useHistory();
  const {user} = UserStore.useContainer()
  const searchParams = new URLSearchParams(location.search)
  const initStatusFilter = searchParams.get(statusKey);
  // const [dealerFilter, setDealerFilter] = useState(searchParams.get(dealerKey) ? Number(searchParams.get(dealerKey)) : undefined);
  const [caseHandlerFilter, setCaseHandlerFilter] = useState(searchParams.get(caseHandlerKey) ? Number(searchParams.get(caseHandlerKey)) : undefined);
  const [exportOpen, setExportOpen] = useState(false)
  const [repairRequestedFilter, setRepairRequestedFilter] = useState<boolean | null>(
    searchParams.get(repairRequestedKey) === "true" 
    ? true
    : searchParams.get(repairRequestedKey) === "false"
    ? false
    : null
  );
  const repairRequestedFilterColor = repairRequestedFilter === true 
    ? "warning" 
    : repairRequestedFilter === false
    ? "success"
    : "secondary"

  const onStatusFilterChange = (id: SelectValue) => {
    const query = new URLSearchParams(location.search);
    if (!id) query.delete(statusKey);
    else query.set(statusKey, id.toString());

    changeHistory(query)
  };

  const onUserFilterChange = (id: SelectValue) => {
    const query = new URLSearchParams(location.search);
    if (!id) {
      setCaseHandlerFilter(undefined)
      query.delete(caseHandlerKey);
    }
    else {
      setCaseHandlerFilter(Number(id))
      query.set(caseHandlerKey, id.toString());
    }

    changeHistory(query)
  };

  // const onDealerFilterChange = (id: SelectValue) => {
  //   const query = new URLSearchParams(location.search);
  //   if (!id) {
  //     setDealerFilter(undefined)
  //     query.delete(dealerKey);
  //   }
  //   else {
  //     setDealerFilter(Number(id))
  //     query.set(dealerKey, id.toString());
  //   }

  //   changeHistory(query)
  // };


  const onFilterForRepairRequested = () => {
    const query = new URLSearchParams(location.search);

    if (repairRequestedFilter === true) {
      setRepairRequestedFilter(false)
      query.set(repairRequestedKey, "false")
    } else if (repairRequestedFilter === false) {
      setRepairRequestedFilter(null)
      query.delete(repairRequestedKey)
    } else {
      setRepairRequestedFilter(true)
      query.set(repairRequestedKey, "true")
    }

    changeHistory(query)
  }
  function changeHistory(query:URLSearchParams) {
    history.push({
      ...history.location,
      pathname: location.pathname,
      search: query.toString(),
    });
  }

  // function onOpenAdvancedFilter() {
  //   alert("TODO: IMPLEMENT ADVANCED FILTER")
  // }

  return (
    <>
      <Space align="center">

        <Typography.Text type={repairRequestedFilterColor}>
          <Tooltip title={"Vis kun sager markeret med 'Reparation påkrævet'"}>
            <Icon name="build-outline" size="large" onClick={() => onFilterForRepairRequested()} style={{ display: 'block' }} />
          </Tooltip>
        </Typography.Text>

        {user?.dealer.id && (
          <ServerSelectBox
            allowClear
            style={{width: 210}}
            value={caseHandlerFilter}
            onChange={onUserFilterChange}
            apiUrl={usersAutoComplete()}
            placeholder={_t("choose", "caseHandler")}
            filters={`filter[dealerId]=${user.dealer.id}`}
          />
        )}
        {/* <ServerSelectBox
          allowClear
          value={dealerFilter}
          apiUrl={dealersAutoComplete("")}
          onChange={(value) => onDealerFilterChange(value)}
          style={{ width: 210 }}
          placeholder={_t("choose", "reviewer")}
        /> */}

        <StatusSelect
          defaultValue={initStatusFilter !== null ? Number(initStatusFilter) : undefined}
          showSearch={false}
          className="width-180"
          placeholder={_t("status", "filter")}
          allowClear
          onChange={(value) => onStatusFilterChange(value)}
          contexts={"taxLegal"}
        />

        <Button onClick={() => setExportOpen(true)}>
          {_t("export")}
        </Button>

        {/* <Button type="ghost" onClick={onOpenAdvancedFilter}>
           {_t("advanced", "filter")}
         </Button> */}
      </Space>

      <ExportModal open={exportOpen} setOpen={setExportOpen} />
    </>
  )
}

function ExportModal({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) {
  const [exportForm] = Form.useForm<{ email: string }>()
  const [loading, setLoading] = useState(false)
  async function onExport() {
    try {
      setLoading(true)
      const vals = exportForm.getFieldsValue()
      const { data } = await initializeExport({ to: vals.email })
      if (data.data) {
        message.success(`${_t("export.toEmail.firstPart")} ${vals.email}. ${_t("export.toEmail.lastPart")}`)
        exportForm.resetFields()
      } else {
        throw new Error(_t("exportFailedOnTheServer"))
      }
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }
  return (
    <Modal visible={open}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      onOk={onExport}
      onCancel={() => setOpen(false)} title={_t("exportList")}
    >
      <Form form={exportForm} {...consts.formItemProps}>
        <Form.Item name="email" label={_t("email")}>
          <Input disabled={loading} type="email" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
