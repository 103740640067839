import { Button, Col, Form, Input, message, Modal, Popconfirm, Result, Row, Select } from "antd";
import debounce from "lodash/debounce";
import find from "lodash/find";
import { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import InputPrice from "../../components/form/inputPrice";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import ServerSelectBox from "../../components/serverSelectBox";
import DataTable from "../../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../../components/table/dataTableTypes";
import _t from "../../lang/translate";
import {
  brandsAutoComplete,
  fuelsAutoComplete,
  modelsAutoComplete,
  typesAutoComplete
} from "../../services/autocompleteService";
import { pageSizeKey, searchKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import {
  deleteVehicleVariant,
  saveVehicleVariant,
  updateVehicleVariant,
  vehicleVariantsUrl
} from "../../services/vehicleCatalogService";
import { PaginationedData } from "../../types/apiTypes";
import { IdType, LocationStateType } from "../../types/appTypes";
import {
  SearchConfig,
  VehicleVariantItem,
  VehicleVariantPriceItem,
  VehicleVariantUpsertType
} from "../../types/vehicleCatalogTypes";
import format from "../../utilities/formatNumbers";
import { isAxiosError } from "../../utilities/typeGuard";

interface VariantProps {
  showModal: boolean;
  hideModal: () => void,
  searchConfig: SearchConfig;
  withActions: boolean;
  priceSelectAction?: (price: number | null) => void;
}

const yearsRange = (min: number, max: number): number[] => {
  var years = []
  var diff = max - min;

  if (diff > 40 || diff < 0) {
    return [];
  }

  for (var i = min; i <= max; i++) {
    years.push(i)
  }
  return years
}

const Variant: FC<VariantProps> = ({
  searchConfig,
  hideModal,
  showModal,
  withActions,
  priceSelectAction
}) => {
  const location = useLocation<LocationStateType>();
  const [{
    data,
    isLoading,
    isError
  }, setUrl, setData] = useApi<PaginationedData<VehicleVariantItem>>("", { data: [] }, true);
  const [targetId, setTargetId] = useState<IdType>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemForm] = Form.useForm<VehicleVariantUpsertType>();
  const doShowModal = showModal || !!targetId;

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  const generateYears = () => {
    const { productionYearStart, productionYearEnd, prices } = itemForm.getFieldsValue();

    let newPrices: VehicleVariantPriceItem[] = [];

    if (productionYearStart && productionYearEnd) {
      newPrices = yearsRange(productionYearStart, productionYearEnd).map(year => {
        const match = find(prices, ['year', year]);

        return {
          year: year,
          price: match?.price || null
        };
      });
    }

    itemForm.setFieldsValue({ prices: newPrices });
  }

  const columns: CustomColumnsType<VehicleVariantItem> = [
    {
      hidden: true,
      title: _t("id"),
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
    },
    {
      hidden: true,
      title: _t("fuel"),
      key: "vehicleFuel",
      dataIndex: "vehicleFuel",
    },
    {
      hidden: true,
      title: _t("type"),
      key: "vehicleType",
      dataIndex: "vehicleType",
    },
    {
      hidden: true,
      title: _t("brand"),
      key: "vehicleMake",
      dataIndex: "vehicleMake",
    },
    {
      hidden: true,
      title: _t("model"),
      key: "vehicleModel",
      dataIndex: "vehicleModel",
    },
    {
      hidden: false,
      title: _t("name"),
      key: "title",
      sorter: true,
      dataIndex: "title",
    },
    {
      hidden: false,
      title: _t("horse_power"),
      key: "horsePower",
      dataIndex: "horsePower",
      width: 60,
      sorter: true,
    },
    {
      hidden: false,
      title: _t("fuel_efficiency"),
      key: "fuelEfficiency",
      dataIndex: "fuelEfficiency",
      width: 60,
    },
    {
      hidden: false,
      title: _t("production_years"),
      key: "years",
      dataIndex: "years",
      render: (val, record) => `${record.productionYearStart ?? ''} - ${record.productionYearEnd ?? ''}`,
    },
    {
      hidden: false,
      title: _t("new_price"),
      key: "newPrice",
      dataIndex: "newPrice",
      sorter: true,
      render: (val, record) => {
        let { year } = searchConfig;
        year = Number(year);
        const rawPrice = find(record.prices, ['year', year])?.price;
        const price = rawPrice ? format.price(rawPrice, 0, "DKK") : '-';

        return priceSelectAction ? (<Button type="link" onClick={() => priceSelectAction(rawPrice || null)}>
          {price}
        </Button>) : price;
      },
    },
    {
      hidden: false,
      title: _t("aliases"),
      key: "aliases",
      dataIndex: "aliases",
      render: value => value?.map((val: string) => val).join(', ')
    },
  ];

  const startEdit = (id: IdType, row: VehicleVariantItem) => {
    itemForm.setFieldsValue(row);
    setTargetId(id);
  }

  const doHideModal = () => {
    hideModal();
    setTargetId(0);
    itemForm.setFieldsValue({
      title: null,
      horsePower: null,
      fuelEfficiency: null,
      productionYearStart: null,
      productionYearEnd: null,
      automaticGearBox: false,
      vehicleMakeId: undefined,
      vehicleModelId: undefined,
      vehicleFuelId: undefined,
      vehicleTypeId: undefined,
      aliases: [],
      prices: null,
    });
  }

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = itemForm.getFieldsValue();

      if (targetId) {
        await updateVehicleVariant(targetId, formData);
      } else {
        await saveVehicleVariant(formData);
      }

      message.success(_t("saved"));

      doHideModal();
      handleRefresh();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  const handleRefresh = useCallback(() => {
    const { vehicleMakeId, vehicleModelId, vehicleFuelId, vehicleTypeId, year, onlyPriced } = searchConfig;
    if (!!vehicleMakeId) {
      const query = new URLSearchParams(location.search);
      query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
      query.set("refreshId", new Date().getSeconds().toString());
      const url = vehicleVariantsUrl(query.toString(),
        vehicleTypeId || undefined,
        vehicleFuelId || undefined,
        vehicleMakeId || undefined,
        vehicleModelId || undefined,
        year || undefined,
        onlyPriced || undefined
      );

      query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
    }
  }, [delayedSetUrl, location.search, searchConfig, setUrl]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh, searchConfig]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteVehicleVariant(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      renderErrorMessage(ex, _t("msg.not_deleted"))
    }
  };

  const tableActions: RenderFunc<VehicleVariantItem> = (_, row) => {
    const { id, title } = row;

    return (
      <>
        <Button className="muted" type="text" shape="circle" icon={<Icon name="pencil-outline" />}
          onClick={() => startEdit(id, row)} />

        <Popconfirm
          placement="topLeft"
          onConfirm={() => handleDelete(id)}
          icon={<Icon fill="red" name="information-circle-outline" />}
          title={
            <div>
              {_t("msg.confirm_delete")}&nbsp;
              <strong>{title}</strong>
            </div>
          }
        >
          <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
        </Popconfirm>
      </>
    );
  };

  return (
    <>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable
          columnStorageKey="VEHICLE_CATALOG_VARIANT"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
          renderActions={withActions ? tableActions : undefined}
          loading={isLoading}
          onRefresh={handleRefresh}
        />
      )}

      <Modal visible={doShowModal} onCancel={doHideModal} destroyOnClose={false} onOk={itemForm.submit}
        okText={!!targetId ? _t('update') : _t('add')}
        confirmLoading={loading}
        width="70%"
      >
        <h2 className="modal-title">
          {!!targetId ? _t('update', 'variant') : _t('add', 'variant')}
        </h2>

        <Form
          form={itemForm}
          requiredMark={true}
          layout="horizontal"
          onFinish={handleSave}
          initialValues={{
            vehicleMakeId: searchConfig.vehicleMakeId,
            vehicleModelId: searchConfig.vehicleModelId,
            vehicleFuelId: searchConfig.vehicleFuelId,
            vehicleTypeId: searchConfig.vehicleTypeId,
          }}
        >
          <Form.Item name="title" label={_t('name')} rules={[{ required: true }]}>
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item name="vehicleMakeId" label={_t("brand")} rules={[{ required: true }]}>
            <ServerSelectBox disabled={loading} apiUrl={brandsAutoComplete()} />
          </Form.Item>

          <Form.Item shouldUpdate={(curr, next) => curr.vehicleMakeId !== next.vehicleMakeId} noStyle>
            {({ getFieldsValue, setFieldsValue }) => {
              const { vehicleMakeId } = getFieldsValue();
              const modelApiUrl = modelsAutoComplete('', vehicleMakeId);
              return (
                <Form.Item name="vehicleModelId" label={_t("model")} rules={[{ required: true }]}>
                  <ServerSelectBox disabled={loading} apiUrl={modelApiUrl} />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item name="vehicleFuelId" label={_t("fuel")} rules={[{ required: true }]}>
            <ServerSelectBox disabled={loading} apiUrl={fuelsAutoComplete()} />
          </Form.Item>

          <Form.Item name="vehicleTypeId" label={_t("type")} rules={[{ required: true }]}>
            <ServerSelectBox disabled={loading} apiUrl={typesAutoComplete()} />
          </Form.Item>

          <Form.Item name="productionYearStart" label={_t('production_year_start')}
            rules={[{ required: true }, { min: 4 }, { max: 4 }]}>
            <Input type="number" onBlur={generateYears} disabled={loading} minLength={4} maxLength={4} />
          </Form.Item>

          <Form.Item name="productionYearEnd" label={_t('production_year_end')}
            rules={[{ required: true }, { min: 4 }, { max: 4 }]}>
            <Input type="number" onBlur={generateYears} disabled={loading} minLength={4} maxLength={4} />
          </Form.Item>

          <Form.Item name="horsePower" label={_t('horse_power')}>
            <InputPrice decimalSize={2} disabled={loading} />
          </Form.Item>

          <Form.Item name="fuelEfficiency" label={_t('fuel_efficiency')}>
            <InputPrice decimalSize={2} disabled={loading} />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              const aliases: string[] = values.aliases || [];

              return (
                <Form.Item name="aliases" label={_t('aliases')} className="mb-1">
                  <Select mode="tags" placeholder={_t('choose')} disabled={loading}>
                    {aliases?.map((alias, i) => (
                      <Select.Option value={alias} key={i}>{alias}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>

          <Row gutter={16} className="border-light-2 p-1">
            <Form.Item shouldUpdate={(curr, next) => curr.prices !== next.prices} noStyle>
              {({ getFieldsValue }) => {
                return getFieldsValue(['prices']).prices?.map((set: VehicleVariantPriceItem, i: number) => {

                  const label = (
                    <>
                      {_t('new_price')}&nbsp;
                      <strong>({set.year})</strong>
                    </>
                  )

                  return (
                    <Col span={8} key={i}>
                      <Form.Item name={["prices", i, 'year']} hidden>
                        <Input type="hidden" disabled={loading} />
                      </Form.Item>

                      <Form.Item name={["prices", i, 'price']} label={label}>
                        <InputPrice suffix="kr" disabled={loading} />
                      </Form.Item>
                    </Col>

                  )
                })
              }}
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  )
};

export default Variant;
