import { Col, Form, Row } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { FC, useState } from "react";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import UploadButton from "../../../components/uploadButton";
import UploadFileRender from "../../../components/uploadFileRender";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { leasingUrl } from "../../../services/leasingService";
import { deleteFile } from "../../../services/mediaService";
import { SeparateFileType } from "../../../types/leasingTypes";
import { accepted } from "../../../utilities/acceptedFiles";

interface LeasingUploadFileProps {
  separateFile: SeparateFileType;
}

const LeasingUploadFile: FC<LeasingUploadFileProps> = ({ separateFile }) => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const { separateFiles } = leasingCase;
  const { label, apiKey, file } = separateFile;

  const action = `${leasingUrl(leasingCase.id)}/${apiKey}`;

  const updateFiles = (nextFile: UploadFile | null) => {
    const nextSeparateFiles: SeparateFileType[] = [...separateFiles];
    const fileIndex = nextSeparateFiles.findIndex((f) => f.apiKey === apiKey);
    nextSeparateFiles[fileIndex] = { ...separateFile, file: nextFile };
    setLeasingCase({ ...leasingCase, separateFiles: nextSeparateFiles });
  };

  const handleFileDelete = async (file: UploadFile) => {
    const serverId = file.response?.id;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        setLoading(true);
        await deleteFile(action, serverId);
      } catch (error) {
        renderErrorMessage(error, _t("msg.file_not_removed"))
        canRemove = false;
      } finally {
        setLoading(false);
      }
    }
    if (canRemove) {
      updateFiles(null);
    }
  };

  const handleUploadChange = ({ file, fileList }: any) => {
    updateFiles(fileList[0]);
  };

  const props = {
    //fileList: fileList || [],
    accept: accepted.join(","),
    action,
    onChange: handleUploadChange,
    disabled: loading,
    uploadIcon: null,
  };

  return (
    <>
      {file && <UploadFileRender className="mb-05" onRemove={handleFileDelete} file={file} loading={loading} />}
      <Form.Item noStyle hidden={file?.status === "done" || file?.status === "error"}>
        <Row justify="space-between" align="middle" className="mb-05">
          <Col>
            <label>{label}</label>
          </Col>
          <Col>
            <UploadButton {...props} />
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default LeasingUploadFile;
