import { Button, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import SavedMessage from "../../../components/messages/savedMessage";
import UserPageStore from "../../../contexts/userPageStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { saveUser } from "../../../services/userService";
import { initUser, UserType } from "../../../types/userTypes";
import consts from "../../../utilities/consts";
import UserFormFields from "./userFormFields";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import { isAxiosError } from "../../../utilities/typeGuard";

interface UserPageInfoFormProps {
  stopEditing: () => void;
}

const UserPageInfoForm: FC<UserPageInfoFormProps> = ({ stopEditing }) => {
  let history = useHistory();
  const { user, setUser, setLoading, isNew } = UserPageStore.useContainer();
  const [userForm] = Form.useForm<UserType>();

  const handleSave = async (values: UserType) => {
    try {
      setLoading(true);
      const { data } = await saveUser({ ...user, ...values });
      message.success(<SavedMessage message={_t("saved")} />);
      if (isNew) {
        history.replace(getPath("user", data.id));
      } else {
        stopEditing();
        setUser(data);
        setLoading(false);
      }
    } catch (error) {
      renderErrorMessage(error);
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        errors && userForm.setFields(errors);
      }
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={user || initUser}
      form={userForm}
      {...consts.formItemProps}
      requiredMark
      onFinish={handleSave}
      className="mb-0"
    >
      <UserFormFields isNew={isNew} />
      <Form.Item noStyle shouldUpdate>
        {({ submit }) =>
          isNew ? (
            <div className="text-right mt-2">
              <Button type="primary" onClick={submit}>
                {_t("create", "user")}
              </Button>
            </div>
          ) : (
            <Row justify="space-between" className="mt-2">
              <Col>
                <Button onClick={stopEditing}>{_t("cancel")}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Col>
            </Row>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default UserPageInfoForm;
