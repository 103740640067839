import { useEffect, useState } from 'react';
import { createContainer } from "unstated-next";
import { OriginalEvaluation, TaxLegalStage } from '../types/taxLegalTypes';
import format from '../utilities/formatNumbers';
import { isValidNum } from '../utilities/typeGuard';


type TaxLegalStagesPriceDifference = {
  type: TaxLegalStage['type'];
  position: string | number;
  figerprint: TaxLegalStage['fingerprint'];
  difference: {
    hearingOfParties?: PriceDifference;
    conclusion?: PriceDifference;
  }
}


type TaxLegalStagesStoreProps = {
  priceDifferences: TaxLegalStagesPriceDifference[];
  actions: {
    setPriceDifference(
      fingerprint: string, 
      formType: keyof TaxLegalStagesPriceDifference['difference'], 
      key: keyof PriceDifference, 
      val: PriceDifference[keyof PriceDifference]
    ): void;
  }
}

const useTaxLegalPriceDifferenceStore = (initialState?: TaxLegalStagesPriceDifference[]): TaxLegalStagesStoreProps => {
  const [priceDifferences, _setPriceDifference] = useState(initialState || initialPriceDifference);

  function setPriceDifference(fingerprint: string, formType: keyof TaxLegalStagesPriceDifference['difference'], key: keyof PriceDifference, val: PriceDifference[keyof PriceDifference]) {
    const index = priceDifferences.findIndex(item => item.figerprint === fingerprint)
    const temp = [...priceDifferences]
    if (index !== -1 && temp?.[index]?.difference?.[formType]) {
      temp[index].difference[formType][key] = val
    }
    _setPriceDifference(temp)
  }

  useEffect(() => {
    if (!initialState) return;
    let lengthChanged = false;
    let positionChanged = false;
    if (initialState.length !== priceDifferences.length) {
      lengthChanged = true;
    } else {
      positionChanged = initialState.some((item, i) => {
        const fingerprintMatch = priceDifferences.find(tmpItem => tmpItem.figerprint === item.figerprint)
        if (fingerprintMatch) {
          return fingerprintMatch.position !== item.position
        }
        return false
      });
    }
    if (lengthChanged || positionChanged) {
      const temp = [...priceDifferences]
      const newPriceDifferences: typeof temp = []
      // Merge old priceDifferences with new 'initialState'
      // I am sorry for sinning...
      initialState.forEach((item,i) => {
        let fingerprintIndex = temp.findIndex(tmpItem => tmpItem.figerprint === item.figerprint)
        newPriceDifferences[i] = item
        if (fingerprintIndex !== -1) {
          newPriceDifferences[i].difference = temp[fingerprintIndex].difference
        }
      })
      _setPriceDifference(newPriceDifferences)
    }
  }, [initialState])

  return {
    priceDifferences: priceDifferences,
    actions: {
      setPriceDifference,
    }
  };
};

const TaxLegalPriceDifferenceStore = createContainer(useTaxLegalPriceDifferenceStore);

export default TaxLegalPriceDifferenceStore

export const useTaxLegalPriceDifference = () => TaxLegalPriceDifferenceStore.useContainer()

export type PriceDifference = {
  newPrice: number | null,
  equipmentsPrice: number | null,
  tradePrice: number | null,
  registrationFee: number | null,
}

export function getPriceDifferenceKey(formItem: {}) {
  const key = Object.keys(formItem)[0] as keyof PriceDifference;
  return priceDifferenceKeys.find(item => item === key) as undefined | keyof PriceDifference
}

const getEmptyPriceDifference = () => ({
  newPrice: null,
  equipmentsPrice: null,
  tradePrice: null,
  registrationFee: null,
})
const priceDifferenceKeys = Object.keys(getEmptyPriceDifference());

export const initialPriceDifference: TaxLegalStagesPriceDifference[] = [
  {
    figerprint: "",
    type: "price_check",
    position: "1",
    difference: {
      conclusion: getEmptyPriceDifference(),
      hearingOfParties: getEmptyPriceDifference()
    },
  },
  {
    figerprint: "",
    type: "complaint",
    position: "2",
    difference: {
      conclusion: getEmptyPriceDifference(),
    }
  },
  {
    figerprint: "",
    type: "resumption",
    position: "3",
    difference: {
      conclusion: getEmptyPriceDifference(),
      hearingOfParties: getEmptyPriceDifference()
    }
  }
]

export function getInitialPriceDifference(stages: TaxLegalStage[], evaluation: OriginalEvaluation | null): TaxLegalStagesPriceDifference[] {
  return stages.map(stage => ({
    figerprint: stage.fingerprint,
    type: stage.type,
    position: stage.position,
    difference: {
      conclusion: getPriceDifference(stage, evaluation, 'conclusion' ),
      hearingOfParties: getPriceDifference(stage, evaluation, "hearingOfParties")
    }
  }))
}
const getPriceDifference = (stage: TaxLegalStage, evaluation: OriginalEvaluation | null, formType: "hearingOfParties" | "conclusion") => {
  if (stage.type === "complaint" && formType === 'hearingOfParties' || !evaluation) {
    return getEmptyPriceDifference()
  }
  return {
    newPrice: (stage.data[formType]?.newPrice as number || 0) - (evaluation.newPrice || 0),
    equipmentsPrice: (stage.data[formType]?.equipmentsPrice as number || 0) - (evaluation.equipmentsPrice || 0),
    tradePrice: (stage.data[formType]?.tradePrice as number || 0) - (evaluation.tradePrice || 0),
    registrationFee: (stage.data[formType]?.registrationFee as number || 0) - (evaluation.registrationFee || 0),
  }
}

export function formatPriceDifference(difference: null | undefined | number) {
  if (!difference) {
    return "-"
  }
  let prefix = "";
  if (difference > 0) prefix = "+";
  return `${prefix} ${format.price(difference)}`
}

export function formatPriceDifferenceChange(a: null | number | undefined, b: null | number | undefined) {
  if (!isValidNum(a)) {
    return "(Ingen ændring)"
  }
  const difference = a - (b || 0);
  let prefix = "";
  if (difference > 0) prefix = "+";
  return `(Ændret ${prefix} ${format.price(difference)})`
}
