import { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import ResellerTaxReceiptActions from "./resellerTaxReceiptActions";
import ResellerTaxChecklistSwitches from "../../../../components/resellerTaxChecklistSwitches";
import ResellerTaxInternalInvoiceActions from "./resellerTaxInternalInvoiceActions";

const ResellerTaxInternalChecklist: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <CollapseCard
      title={_t("DMF", "checklist")}
      loading={loading}
    >
      <ResellerTaxChecklistSwitches setLoading={setLoading} />
      <DividerLine fluid />
      <ResellerTaxInternalInvoiceActions setLoading={setLoading} />
    </CollapseCard>
  );
};

export default ResellerTaxInternalChecklist;
