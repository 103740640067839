import React, {FC} from "react";
import {Button, Space} from "antd";
import CardBox from "../../../../components/cardBox";
import _t from "../../../../lang/translate";
import DownloadBtn from "../downloadBtn";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import {openFileLink} from "../../../../services/mediaService";
import Icon from "../../../../assets/icons/icon";
import {
  downloadEntryCertificate,
  downloadPurchaseContract,
  downloadTakeHomeAgreement
} from "../../../../services/importService";

const ImportDownloadBtns: FC = () => {
  const {importCase} = ImportCaseStore.useContainer();
  const {offer, vehicleReport, paymentAdvice} = importCase.links || {};
  return (
    <CardBox title={_t("download")}>
      <Space className="mb-05" direction="vertical">
        <Button type="primary" ghost onClick={() => openFileLink(offer)} disabled={!offer}>
          <Icon name="download-outline" size="large"/>
          {_t("offer")}
        </Button>
        <Button type="primary" disabled={!vehicleReport} ghost onClick={() => openFileLink(vehicleReport)}>
          <Icon name="download-outline" size="large"/>
          {_t("car_report")}
        </Button>
        <Button type="primary" disabled={!paymentAdvice} ghost onClick={() => openFileLink(paymentAdvice)}>
          <Icon name="download-outline" size="large"/>
          {_t("invoice")}
        </Button>
        <DownloadBtn label={_t("entry-certificate-GR")} callable={downloadEntryCertificate}/>
        <DownloadBtn label={_t("take-home-agreement")} callable={downloadTakeHomeAgreement}/>
        <DownloadBtn label={_t("purchase-contract-GR")} callable={downloadPurchaseContract}/>
      </Space>
    </CardBox>
  );
};

export default ImportDownloadBtns;
