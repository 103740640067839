import React, {FC} from "react";
import StatusFlagToggle from "./statusFlagToggle";
import {
  updateCertificateOfConformityReceived, updateOriginalInvoiceReceived,
  updateRegistrationCertificate1Received, updateRegistrationCertificate2Received,
  updateVehiclePaid,
  updateVehiclePurchaseContractRequested,
  updateVehiclePurchaseContractSigned
} from "../../../../services/importService";
import _t from "../../../../lang/translate";

const ImportMerchantRelation: FC = () => {
  return (
    <>
      <StatusFlagToggle
        id={"vehiclePurchaseContractRequested"}
        flag={"vehiclePurchaseContractRequested"}
        callable={updateVehiclePurchaseContractRequested}
        label={_t("purchase-contract-GR", "ordered")}
      />
      <StatusFlagToggle
        id={"vehiclePurchaseContractSigned"}
        flag={"vehiclePurchaseContractSigned"}
        callable={updateVehiclePurchaseContractSigned}
        label={_t("purchase-contract-GR", "signed")}
      />
      <StatusFlagToggle
        flag={"vehiclePaid"}
        callable={updateVehiclePaid}
        label={_t("vehicle", "paid")}
      />

      <StatusFlagToggle
        flag={"registrationCertificate1Received"}
        callable={updateRegistrationCertificate1Received}
        label={`${_t("registration_certificate", "part_one")} ${_t('received')}`}
      />

      <StatusFlagToggle
        flag={"registrationCertificate2Received"}
        callable={updateRegistrationCertificate2Received}
        label={`${_t("registration_certificate", "part_two")} ${_t('received')}`}
      />

      <StatusFlagToggle
        flag={"certificateOfConformityReceived"}
        callable={updateCertificateOfConformityReceived}
        label={`${_t("coc_document")} ${_t('received')}`}
      />

      <StatusFlagToggle
        flag={"originalInvoiceReceived"}
        callable={updateOriginalInvoiceReceived}
        label={`${_t("original", "invoice")} ${_t('received')}`}
      />
    </>
  );
};

export default ImportMerchantRelation;
