import React, { FC, useEffect, useState } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import { compare } from "../../../../utilities/deepCompare";
import calculateRegFee, { RegFeeCalcInputType, RegFeeCalcOutputType } from "./../calc/regFeeCalculatorIndex";

interface TaxRegestrationFeeInputProps extends InputPriceProps {
  // TODO: Delete Projected after 31-12-2024;
  onOutput?: (output: RegFeeCalcOutputType, projected: RegFeeCalcOutputType) => void;
  input: RegFeeCalcInputType;
  autoCalculate: boolean;
}

const TaxRegestrationFeeInput: FC<TaxRegestrationFeeInputProps> = ({
  input,
  onOutput,
  autoCalculate,
  onChange,
  onError,
  ...rest
}) => {
  const [prevInput, setPrevInput] = useState<RegFeeCalcInputType>(input);

  useEffect(() => {
    if (!autoCalculate || compare(input, prevInput)) return;
    setPrevInput(input);

    async function calc() {
      try {
        const output = await calculateRegFee(input);
        const tempOutput2025 = await calculateRegFee({...input, versionYear: 2025});
        if (onChange && typeof output.registrationFee === "number") {
          onChange(Math.ceil(output.registrationFee));
        }
        onOutput && onOutput(output, tempOutput2025);
      } catch (error) {
        // TODO --> TS error
        onError && onError(error as any)
      }
    }

    calc();
  }, [input, autoCalculate]);

  return <InputNum onChange={onChange} {...rest} />;
};

export default TaxRegestrationFeeInput;
